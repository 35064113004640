import React from 'react';
import '_boot/index.scss';
import ReactDOM from "react-dom";
import { createBrowserHistory, createHashHistory } from "history";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from 'serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from "redux";

import Login from "_modules/login/Login";
import loginState from "_modules/login/loginState";
import appState from "states/appState";

import Home from "routes/home/Home";

const store = createStore(
  combineReducers({
    'loginState': loginState,
    'appState':appState,
  }),
  applyMiddleware(thunk)
);
window.store = store;

const history = window.cordova ? createHashHistory() : createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <div className="router">
        <Route component={Login} />
        <Route path={window.HomeURL} exact component={Home} />
      </div>
    </Router >
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();