import React from "react";

import "./items-list.scss";

class View extends React.Component {

    buildItems = (props, key) => {
        if(props === undefined || props === null) {
            return null;
        }

        return (
            <this.props.component key={key} index={key} {...props} parentProps={this.props} />
        )
    }

    render() {

        const cls = this.props.className ? this.props.className : "items-list";
        let lastCls = null;

        if (this.props.lastComponentProps && this.props.index !== 0) {
            lastCls = (this.props.items.length === 0 ? 
                this.props.lastComponentProps.noItemsClass : null);
        }

     
        return (
            <div className={cls}>
                {this.props.items.map(this.buildItems)}
                {this.props.lastComponent ?
                    <this.props.lastComponent {...this.props} cls={lastCls}  />
                    : null
                }
            </div>
        );
    }
}

export default View;
