import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./right.scss";
import * as Lang from "./RightLang";
import * as actions from "states/appState";

import Panel from "components/panel/Panel";
import Tab from "components/tab/Tab";
import Tab1 from "containers/tab1/Tab1";
import Tab2 from "containers/tab2/Tab2";
import Tab3 from "containers/tab3/Tab3";
import Tab4 from "containers/tab4/Tab4";

import DamageData from "containers/damage_data/DamageData";
import Exports from "containers/exports/Exports";
import ButtonSave from "components/button-save/ButtonSave";
import Users from "containers/users/Users";


class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabs: [
                {
                    label: Lang.TAB1, id: 0, onClick: this.tab1Click, selected: true, disabled: false, tab: Tab1
                }, {
                    label: Lang.TAB2, id: 1, onClick: this.tab2Click, selected: false, disabled: false, tab: Tab2
                }, {
                    label: Lang.TAB3, id: 2, onClick: this.tab3Click, selected: false, disabled: false, tab: Tab3
                }, {
                    label: Lang.TAB4, id: 3, onClick: this.tab4Click, selected: false, disabled: false, tab: Tab4
                }
            ]
        }
        this.state.selectedTab = { props: this.state.tabs[0] };
    }

    tabClicked = (params) => {
        const items = this.state.tabs;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.selected = item.id === params.props.id ? true : false;
        }

        this.setState({
            tabs: items,
            selectedTab: params,
        })
    }

    tab1Click = (params) => {
        this.tabClicked(params);
    }


    tab2Click = (params) => {
        this.tabClicked(params);
    }


    tab3Click = (params) => {
        this.tabClicked(params);
    }


    tab4Click = (params) => {
        this.tabClicked(params);
    }

    buildTabs = (param, key) => {
        return (
            <Tab key={key} {...param} />
        )
    }

    saveData = (param) => {
        this.props.actions.saveData();
    }

    render() {

        const cSel = this.props.appState.currentSection;
        const cSubSel = this.props.appState.currentSubSection;

        if (this.props.appState.isLoading) {
            return null;
        }

        return (
            <div className={this.props.appState.rightShowGrayBG ? "right bg-gray" : "right"}>
                <Panel label={""} />
                {(cSel === 0 && this.props.appState.probes) ?
                    <div className={this.props.appState.rightShowGrayBG ? "bg-gray" : ""}>
                        <div className="tabs">
                            {this.state.tabs.map(this.buildTabs)}
                        </div>
                        <div className={this.props.appState.rightShowGrayBG ? "tab-content border-top-gray" : "tab-content"}>
                            {this.state.selectedTab ? <this.state.selectedTab.props.tab /> : null}
                        </div>
                        {this.props.appState.save ?
                            <div className="save">
                                <ButtonSave onClick={this.saveData} />
                            </div>
                            : null}
                    </div>
                    : null}
                {(cSel === 1 && cSubSel === 0 && this.props.appState.damage_data) ?
                    <DamageData />
                    : null}

                {(cSel === 1 && cSubSel === 1 && this.props.appState.exports) ?
                    <Exports />
                    : null}

                {(cSel === 1 && cSubSel === 2 && this.props.appState.exports) ?
                    <Users />
                    : null}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
