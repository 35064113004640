import React from "react";

import "./check.scss";

class View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: (this.props.checked !== undefined) ? this.props.checked : false,
        }
    }

    click = data => {
        data.preventDefault();
        const chk = !this.state.checked;
        this.setState({
            checked: chk
        })
        if (this.props.onChange) {
            const resp = { target: { value: chk } };
            this.props.onChange(this, resp);
        }
    }

    labelClick = params => {
        params.preventDefault();
        if (this.props.onLabelClick) {
            this.props.onLabelClick(this);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checked !== undefined) {
            this.setState({
                checked: nextProps.checked
            })
        }
    }

    render() {

        const disClass = this.props.disabled === true ? " disabled" : "";
        const clsName = this.props.className ? this.props.className : "check";

        return (
            <div className={clsName + disClass}>
                <div className="checkmark" onClick={this.click} >
                    {this.state.checked ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" /></svg>
                        : null}
                </div>
                {this.props.label ?
                    <div onClick={this.labelClick} className="label">{this.props.label}</div>
                    : null}
            </div>
        );
    }
}

export default View;
