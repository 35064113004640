import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Lang from "./CompaniesLang";
import "./companies.scss";
import * as actions from "states/appState";

import Company from "components/company/Company";
import Panel from "components/panel/Panel";

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companies: [],
            selectedCompany: null,
        }
    }

    componentWillMount() {
        this.props.actions.getCompanies();
    }

    selectCompany = (param, check = false) => {
        if (!this.props.appState.save) {
            const elems = this.state.companies;

            for (let i = 0; i < elems.length; i++) {
                const elem = elems[i];
                const props = elem.props;
                const data = elem.data;
                data.selected = false;
                if (props.id === param.props.props.id) {
                    data.selected = true;
                }
            }

            this.setState({
                companies: elems,
                selectedCompany: param,
            })

            this.props.actions.getCompanyData(param.props.props);
        }
    }

    getCompanySelection = (params) => {
        const elems = this.state.companies;
        for (let i = 0; i < elems.length; i++) {
            const elem = elems[i];
            if (elem.props.id === params.id) {
                return elem.data.selected;
            }
        }
        return false;
    }

    floorSelected = (param) => {
        this.props.actions.selectFloor(param.props);

    }

    buildCompanies = (param, key) => {
        return (
            <Company appState={this.props.appState} onFloorSelected={this.floorSelected} onClick={this.selectCompany} key={key} {...param} />
        )
    }

    componentWillReceiveProps(nextProps) {
        const elems = [];

        for (let i = 0; i < nextProps.appState.companies.length; i++) {
            const eProps = nextProps.appState.companies[i];
            const elem = {
                data: {
                    selected: this.getCompanySelection(eProps),
                    company_data: (this.state.selectedCompany && this.state.selectedCompany.props.props.id === eProps.id) ? nextProps.appState.company_data : null,
                },
                props: eProps,
            }
            elems.push(elem);
        }

        this.setState({
            companies: elems,
        })


    }


    render() {
        return (
            <div className="companies">
                <Panel label={Lang.TITLE} />
                <div className="companies-company">
                    <div className="companies-details">
                        <span>{Lang.COMPANY_ID}</span>
                        <span>{Lang.COMPANY_NAME}</span>
                    </div>
                    {this.state.companies.map(this.buildCompanies)}
                </div>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
