import React, { Component } from 'react';
import "./floor.scss";

import Investigation from "components/investigation/Investigation";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedInvestigation: "",
        }
    }

    componentWillReceiveProps(nextProps) {
        const open = nextProps.selected === nextProps.id ? true : false;

        if (!open) {
            this.setState({
                'selectedInvestigation': "",
            })
        }
    }

    click = (param) => {
        if (!this.props.appState.save) {
            if (this.props.items.length > 0) {
                this.props.onClick(this);
            }
        }
    }

    selectInvestigation = (param) => {
        if (!this.props.appState.save) {
            this.setState({
                'selectedInvestigation': param.props.id,
            })
        }
    }

    buildInvestigations = (param, key) => {
        return (
            <Investigation appState={this.props.appState} key={key} selected={this.state.selectedInvestigation} onClick={this.selectInvestigation}  {...param} />
        )
    }

    render() {
        // const dis = this.props.items.length === 0 ? " disabled" : "";
        const dis = "";
        const open = this.props.selected === this.props.id ? true : false;

        return (
            <div onClick={this.click} className={"floor" + (open ? " open" : "") + dis} >
                {this.props.name}
                {open ?
                    <div className="investigations">
                        {this.props.items.map(this.buildInvestigations)}
                    </div>
                    : null}
            </ div>
        );
    }
}