import React, { Component } from 'react';
//import * as Lang from "./Lang";
import "./button.scss";

export default class Button extends Component {

    click = params => {
        this.props.onClick(this);
    }

    render() {
        let img = this.props.selected ? <img alt="" src={this.props.selectedImg} /> : <img alt="" src={this.props.img} />

        return (
            <div onClick={this.click} className={"bar-button " + (this.props.selected ? " selected" : "") + (this.props.disabled ? " disabled" : "") + (this.props.bottom === true ? " bottom" : "")}>
                {img}
            </div>
        );
    }
}