import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Lang from "./UsersLang";
import "./users.scss";
import * as actions from "states/appState";
import User from "components/user/User";
import Plus from "components/plus/Plus";
import ButtonSave from "components/button-save/ButtonSave";
import * as email from "utils/email";

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'errors': [],
        }
    }

    componentWillMount() {
        this.props.actions.getUsers(this);
    }

    changeUserData = (param, ev) => {
        const props = param.props;
        this.props.actions.editUser({
            'index': props.index,
            'name': props.name,
            'value': ev.target.value,
        })
        this.props.actions.toggleSave(true);
    }

    builAppUsers = (param, key) => {
        return this.buildUsers(param, key, null, "app");
    }

    buildBackendUsers = (param, key) => {
        return this.buildUsers(param, key, null, "admin");
    }

    checkUserDuplicate = (param, nm, index) => {
        const users = this.props.appState.users;
        for (let i = 0; i < users.length; i++) {
            if (i !== index) {
                const user = users[i];
                if (user[nm] === param[nm]) {
                    return true;
                }
            }
        }
        return false;
    }

    save = (param) => {
        const users = this.props.appState.users;
        const errors = [];
        let hasErrors = false;
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            let err = {
                'username': false,
                'password': false,
                'email': false,
            }
            if (user.username === "") {
                hasErrors = true;
                err.username = true;
            } else {
                const dp = this.checkUserDuplicate(user, "username", i);
                if (!hasErrors)
                    hasErrors = dp;
                err.username = dp;
            }
            if (user.email === "") {
                if (!hasErrors)
                    hasErrors = true;
                err.email = true;
            } else {
                const ve = !email.validate(user.email);
                if (!hasErrors)
                    hasErrors = ve;
                err.email = ve;

                if (!ve) {
                    const dp = this.checkUserDuplicate(user, "email", i);
                    if (!hasErrors)
                        hasErrors = dp;
                    err.email = dp;
                }
            }
            if (!user.id && user.password === "") {
                if (!hasErrors)
                    hasErrors = true;
                err.password = true;
            }
            errors.push(err);
        }

        this.setState({
            'errors': errors,
        })

        if (!hasErrors) {
            this.props.actions.saveUsers();
        }
    }

    addBackendUser = () => {
        this.props.actions.addUser({ 'role': "admin" })
    }

    addAppUser = () => {
        this.props.actions.addUser({ 'role': "app" })
    }

    deleteUser = (param) => {
        this.props.actions.deleteUser(param.props);
    }

    getFirstAppUser() {
        const users = this.props.appState.users;
        for (let i = 0; i < users.length; i++) {
            if (users[i].role === "app") {
                return users[i];
            }
        }
    }

    buildUsers = (param, key, arr, type) => {
        if (param.role !== type) {
            return null;
        }

        let showTop = false;
        if (key === 0 || param.id === this.getFirstAppUser().id)
            showTop = true;

        const topUserLabel = showTop ? Lang.USERNAME : null;
        const topPassLabel = showTop ? Lang.PASWORD : null;
        const topEmailLabel = showTop ? Lang.EMAIL : null;


        return (
            <User topUserLabel={topUserLabel} topPassLabel={topPassLabel} topEmailLabel={topEmailLabel} errors={this.state.errors[key]} index={key} onChange={this.changeUserData} onDelete={this.deleteUser} key={key} {...param} />
        )
    }

    render() {
        //console.log(this.props.appState.errors);
        return (
            <div className="users">
                <div className="title">{Lang.TITLE_BACKEND}</div>
                <div className="cnt">
                   {this.props.appState.users.map(this.buildBackendUsers)}
                    <Plus label={Lang.ADD_USER} onClick={this.addBackendUser} />
                </div>
                <div className="title title-app">{Lang.TITLE_APP}</div>
                <div className="cnt">
                      {this.props.appState.users.map(this.builAppUsers)}
                    <Plus label={Lang.ADD_USER} onClick={this.addAppUser} />
                </div>
                {this.props.appState.save ?
                    <div className="save">
                        <ButtonSave onClick={this.save} />
                    </div>
                    : null}
            </div>
        )
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
