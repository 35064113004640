import React from "react";

import "./radio.scss";

class Item extends React.Component {

    onChange = (param) => {
        this.props.onChange(this, param);
    }

    render() {
        const chk = (this.props.selected === this.props.index) ? true : false;

        return (
            <div className="radio-item">
                <input type="radio" checked={chk} name={this.props.name} onChange={this.onChange} />
                <label className="radio-item-label" htmlFor="radio-item-for">{this.props.label}</label>
            </div>
        )
    }
}

export default class View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            'selected': 0,
        }
    }

    componentWillMount() {
        const props = {
            ...this.props.values[this.props.selected],
            index:this.props.selected,
        }
        this.onChange({props:props});
    }

    onChange = (param, ev) => {
        this.setState({
            'selected': param.props.index,
        })

        if (this.props.onChange) {
            this.props.onChange(this, param);
        }
    }

    buildItems = (param, key) => {
        return (
            <Item key={key} {...this.props} {...param} selected={this.state.selected} onChange={this.onChange} index={key} />
        )
    }

    render() {
        const clsName = this.props.className ? this.props.className : "radio";

        return (
            <div className={clsName}>
                <div className="radio-items">
                    {this.props.values.map(this.buildItems)}
                </div>
                {this.props.children}
            </div>
        );
    }
}