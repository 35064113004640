import React, { Component } from 'react';
import "./damage.scss";

import Button2 from "_modules/button2/Button2";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'showTT': false,
        }

        this.ht = null;
        this.ttClicked = false;
    }

    click = (param) => {
        if (!this.ttClicked) {
            if (this.props.company_data) {
                if (this.props.company_data.events.length > 0)
                    this.props.onClick(this);
            } else {
                this.props.onClick(this);
            }
        }
    }

    componentWillMount(){
    }

    toggleTT = (params) => {
        let dl = 10;
        if (params) {
            dl = 0;
            clearTimeout(this.ht);
        } else {

        }

        this.ht = setTimeout(() => {
            this.setState({
                'showTT': params,
            });
        }, dl);


    }

    mouseOver = (params) => {
        if (this.props.onClickTT) {
            this.toggleTT(true);
        }
    }

    mouseOut = (params) => {
        this.toggleTT(false);
    }

    clickTT = (params) => {
        this.ttClicked = true;
        params.preventDefault();
        this.props.onClickTT(this);

        setTimeout(() => {
            this.ttClicked = false;
        }, 10);
    }

    render() {
       
        return (
            <div onMouseOut={this.mouseOut} onMouseOver={this.mouseOver} onClick={this.click} className={"damage" + (this.props.selected ? " selected" : "" + (this.props.isLast === true ? " last" : ""))}>
                <div className="damage-name">
                    {this.props.name}
                </div>
                {this.state.showTT ?
                    <div onClick={this.clickTT} onMouseOver={this.mouseOver} className="tt">
                        <Button2 svg={require("assets/images/Symbol 25 – 210.svg")} />
                    </div>
                    : null}
            </div>
        );
    }
}