import * as loginActions from "_modules/login/loginState";

export const treat = (error, dispatch) => {
    const errRes = error.response;
    if (errRes) {
        if (errRes.data.exception) {
            return { error: errRes.data.message };
        }
        if (errRes.data && errRes.data.message) {
            if (errRes.data.message === "Unauthenticated.") {
                dispatch(loginActions.logout());
            }
        }
        return errRes.data;

    } else {
        return { error: "ERR_NETWORK_FAILED" };
    }

}