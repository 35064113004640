import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./tab1.scss";
import * as actions from "states/appState";
//import * as Lang from "./Tab1Lang";

//import Slider from "_modules/slider/Slider";
import Planner from "_modules/planner/Planner"


class View extends Component {

    // constructor(props) {
    //     super(props);

        // this.state = {
        //     'canRenderPlanner': true,
        // };

        // this.lrp = "!";
    // }

    // componentWillReceiveProps(nextProps) {
    //     const lrp = nextProps.appState.lastReadingProbes;

    //     if (lrp !== this.lrp) {
    //         this.setState({
    //             'canRenderPlanner': false,
    //         })

    //         setTimeout(() => {
    //             this.setState({
    //                 'canRenderPlanner': true,
    //             })
    //         }, 10);
    //     }

    //     this.lrp = lrp;
    // }

    render() {

        // if (!this.state.canRenderPlanner) {
        //     return null;
        // }

        return (
            <div className="tab1">
                <Planner actions={this.props.actions} appState={this.props.appState} />
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
