import * as Config from "_boot/Config";
import * as Api from "_services/Api";
import * as treatError from "_utils/treatError";

const ID = "LOGIN";
const LOGIN_ITEM = "___LOGIN___";
const URL_DATA = "/api/login";
let history = null;

export const ActionID = {
    DATA: ID + "DATA",
    ERROR: ID + "ERROR",
    LOGOUT: ID + "LOGOUT",
}

const defaultState = {
    data: null,
    error: null,
}

const state = (state = defaultState, action) => {
    switch (action.type) {

        case ActionID.DATA:
            state.data = action.data;
            return {
                ...state,
            }

        case ActionID.ERROR:
            state.error = action.error;
            return {
                ...state,
            }

        case ActionID.LOGOUT:
            state.data = null;
            state.error = null;
            return {
                ...state,
            }

        default:
            return {
                ...state,
            }

    }
}

export default state;

export const getHeaders = params => {
    return {
        'Authorization': 'Bearer ' + window.store.getState().loginState.data.user.api_token,
    }
}

export const doLoginState = params => (dispatch) => {
    const data = JSON.parse(localStorage.getItem(Config.APP.ID + LOGIN_ITEM));
    if (params && params.props && params.props.history) {
        history = params.props.history;
    }
    const url = history.location.pathname;
    const ldEx = window.store.getState().loginState.data;
    //localStorage.clear();
    if (params && params.force) {
        history.push(window.HomeURL);
    }
    if (data) {
        if (!ldEx) {
            dispatch({
                type: ActionID.DATA, data,
            });
            if (url === window.LoginURL) {
                history.push(window.HomeURL);
            }
        }
    } else {
        if (url !== window.LoginURL) {
            history.push(window.LoginURL);
        }
    }

}

export const login = (params, inst) => dispatch => {

    const dataToSend = {
        'username': params.username,
        'password': params.password,
        'target': "admin",
    }
    //console.log(dataToSend);

    Api.post(URL_DATA, dataToSend, null, (result) => {
        const data = result.data;
        localStorage.setItem(Config.APP.ID + LOGIN_ITEM, JSON.stringify(data));
        dispatch(doLoginState(inst));
    }, (error) => {
        dispatch({
            type: ActionID.ERROR, error: treatError.treat(error, dispatch),
        })
    });
}

export const logout = (inst) => dispatch => {
    localStorage.clear();
    dispatch({
        type: ActionID.LOGOUT, data: null
    })
    if (inst)
        dispatch(doLoginState(inst));
    else
        dispatch(doLoginState({ force: true }));


}