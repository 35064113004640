import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import * as Lang from "./HomeLang";
import "./home.scss";
import * as actions from "states/appState";
import * as loginActions from "_modules/login/loginState";
import Bar from "_modules/bar/Bar";
import Companies from "containers/companies/Companies";
import Right from "containers/right/Right";
import Settings from 'containers/settings/Settings';
import Preloader from "_modules/preloader/Preloader";

window.HomeURL = "/";

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabBarSelected: 0,
        }

        this.centerComponents = [
            <Companies />, <Settings />
        ]
    }

    onTabBarSelected = param => {
        if (param.props.id === 2){
            this.props.loginActions.logout(this);
            return;
        }
        this.props.actions.selectSection(param.props.id);
        this.setState({
            tabBarSelected: param.props.id,
        });
    }

    componentWillMount() {
        this.props.actions.initApp();
        //this.onTabBarSelected({ props: { id: 1 } });
    }

    upload = (param) => {
        this.props.actions.upload(param.target.files[0])
    }

    render() {
        if (!this.props.appState.exports) {
            return <Preloader />
        }

        return (
            <div className="home">

                <Bar
                    logo={require("assets/images/logo.png")}
                    onSelect={this.onTabBarSelected}
                    enabled={!this.props.appState.save}
                    buttons={[
                        {
                            img: require("assets/images/Symbol 23.svg"),
                            selectedImg: require("assets/images/damages.svg"),
                            selected: true,
                            disabled: false,
                        }, {
                            img: require("assets/images/settings.svg"),
                            selectedImg: require("assets/images/Symbol 24.svg"),
                            selected: false,
                            disabled: false,
                        }, {
                            img: require("assets/images/signoff.svg"),
                            selected: false,
                            disabled: false,
                            bottom: true,
                        }
                    ]} />
                {this.centerComponents[this.state.tabBarSelected]}
                <Right />

                {this.props.appState.isPreloader ?
                    <Preloader />
                    : null}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
        loginActions: bindActionCreators(loginActions, dispatch),
    })
)(View);
