import React, { Component } from 'react';
import "./button3.scss";

import Button from "_modules/button/Button";
export default class View extends Component {

    render() {
        return (
            <Button className="button button3" {...this.props} />
          );
    }
}