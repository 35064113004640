import React, { Component } from 'react';
import "./tree3.scss";

import Tree from "_modules/tree/Tree";
import ReactSVG from 'react-svg'
import Check from "_modules/check/Check";

class Node extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        }

        this.crrNodeIndex = 0;
    }


    click = (param) => {
        //if (this.props.items)
        // this.props.onClick(this.props);
    }

    checkChanged = (param, param2) => {
        const scr = param.props.screenshot;
        scr["exportCheck" + param.props.treeIndex] = param2.target.value;
        param.props.onCheck(param, param2);
    }

    render() {
        const oi = !this.props.open ? require("assets/images/tree_right.svg") : require("assets/images/tree_down.svg");
        const exChk = this.props.screenshot ? this.props.screenshot["exportCheck" + this.props.treeIndex] : true;
        const chk = this.props.level === 4 ? <Check
            treeIndex={this.props.treeIndex}
            checked={(exChk === true) ? true : false}
            {...this.props} onChange={this.checkChanged} /> : null;
        let show = true;

        if (this.props.level >= 2) {
            show = false;
            if (this.props.object["exportCheck" + this.props.treeIndex] === true) {
                show = true;
            }
        }

        if (!show) {
            return null
        }

        return (
            <div className="tree-child">
                <div className="items">
                    <div className="items-row">
                        <div onClick={this.click} className={"tree-child-ind" + (this.props.open ? " open" : "") + ((this.props.items) ? "" : " hidden")}><ReactSVG alt="" src={oi} /></div>
                        <div className={"chk chk" + this.props.level}>
                            {chk}
                            <div className={"tx tx" + this.props.level}>{this.props.name}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        }

        this.crrNodeIndex = 0;

    }

    selectNode = (params) => {
        this.crrNodeIndex = 0;
        this.populateNode("crrNodeIndex", true, this.state, params.index, { open: !params.open }, null, 0);
        this.setState({
            items: this.state.items,
        })
    }

    populateNode = (index, root, parent, srcIndex = -1, props = null, srcProps = null, level) => {
        const lv = level + 1;
        const items = parent.items;
        for (let i = 0; i < items.length; i++) {
            const it = items[i];

            it.component = Node;
            it.onClick = this.selectNode;
            it.index = this[index]++;
            it.root = root;
            it.node = items;
            it.itemIndex = i;
            it.parent = parent;
            it.level = lv;
            it.treeIndex = this.props.treeIndex;
            it.onCheck = this.props.onCheck;
            if (it.object) {
                it.open = false;
                if (it.parent.open) {
                    const ch = it.object["exportCheck" + it.treeIndex];
                    if (ch === true)
                        it.open = true;
                }
            }

            if (props) {
                if (it.index === srcIndex) {
                    if (srcProps) {
                        Object.assign(it, srcProps);
                    }
                    if (props.add) {
                        this[index]++;
                        it.node.push({
                            name: "",
                            items: [],
                            component: Node,
                            index: this[index]++,
                            root: root,
                            node: items,
                            itemIndex: i,
                        })
                    }
                    else
                        Object.assign(it, props);
                }
            }
        }

        level++;
        for (let i = 0; i < items.length; i++) {
            const itms = items[i];
            if (itms.items) {
                this.populateNode(index, false, itms, srcIndex, props, srcProps, level);
            }
        }

    }

    populateItems(props) {
        if (props.items) {
            this.crrNodeIndex = 0;
            const items = props.items;
            this.populateNode("crrNodeIndex", true, props, -1, null, null, 0);

            this.setState({
                items: items,
            })
        }
    }

    componentWillMount() {
        this.populateItems(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.populateItems(nextProps);
    }

    render() {

        return (
            <div className="cmp-panel">
                <Tree className="tree tree3" items={this.state.items} />
            </div>
        );
    }
}