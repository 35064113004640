import React, { Component } from 'react';
import "./test1.scss";

import Input from "_modules/input/Input";
import * as Lang from "./Test1Lang";

export default class View extends Component {

    buildItems = (param, key) => {
        return (
            <Input index={key} onChange={this.onChangeItem} key={key} {...param} forceClear={true} value={param.name} />
        )
    }

    onChangeItem = (param, ev) => {
        this.props.onChange(this, param, ev);
    }

    render() {
       
        return (
            <div className="test1">
                <div className="title">{Lang.TITLE}</div>
                {this.props.details.values.map(this.buildItems)}
            </div>
        );
    }
}