import * as Api from "_services/Api";
import * as treatError from "_utils/treatError";
import * as loginActions from "_modules/login/loginState";
import * as get from "utils/get";
import * as probe from "_utils/probe";
import { getTestResultsForSample, generateIncidentData } from "./incidentData"
import * as PouchDB from "_services/PouchDB";
import * as random from "utils/random";
import { DOC_GEN_URL } from "_services/Api";
const {v4 : uuidv4} = require('uuid');


var DOWNLOAD_STATUS_CHECK_INTERVAL = null

const ID = "APP";
const GET_USERS = "/api/users/all";
const UPDATE_USERS = "/api/users/update_users";
const DELETE_USER = "/api/users/delete";

export const ActionID = {
    ERROR: ID + "ERROR",

    GET_COMPANIES: ID + "GET_COMPANIES",
    GET_COMPANY_DATA: ID + "GET_COMPANY_DATA",
    RELOAD_COMPANY_DATA: ID + "RELOAD_COMPANY_DATA",
    GET_SAMPLE_LAYER: ID + "GET_SAMPLE_LAYER",
    GET_ROOM_NAMES_LAYER: ID + "GET_ROOM_NAMES_LAYER",
    GET_ROOM_LINES_LAYER: ID + "GET_ROOM_LINES_LAYER",
    GET_CORRECTIONS: ID + "GET_CORRECTIONS",
    GET_SAMPLES_FOR_CURRENT_INVESTIGATION: ID + "GET_SAMPLES_FOR_CURRENT_INVESTIGATION",
    GET_ROOM_NAMES_FOR_CURRENT_INVESTIGATION: ID + "GET_ROOM_NAMES_FOR_CURRENT_INVESTIGATION",
    GET_ROOM_LINES_FOR_CURRENT_INVESTIGATION: ID + "GET_ROOM_LINES_FOR_CURRENT_INVESTIGATION",

    ADD_DAMAGE: ID + "ADD_DAMAGE",
    GET_DAMAGES: ID + "GET_DAMAGES",
    DELETE_DAMAGE: ID + "DELETE_DAMAGE",
    UPDATE_DAMAGE: ID + "UPDATE_DAMAGE",

    GET_DAMAGE_DATA: ID + "GET_DAMAGE_DATA",
    UPDATE_DAMAGE_DATA: ID + "UPDATE_DAMAGE_DATA",
    GET_BACKEND_RESULTS: ID + "GET_BACKEND_RESULTS",
    ADD_TEST: ID + "ADD_TEST",
    DELETE_TEST: ID + "DELETE_TEST",
    UPDATE_TEST: ID + "UPDATE_TEST",
    ADD_TEST_ITEM: ID + "ADD_TEST_ITEM",
    DELETE_TEST_ITEM: ID + "DELETE_TEST_ITEM",
    TEST_CHANGED: ID + "TEST_CHANGED",

    ADD_MATERIAL_OPTION: ID + "ADD_MATERIAL_OPTION",
    DELETE_MATERIAL_OPTION: ID + "DELETE_MATERIAL_OPTION",
    UPDATE_MATERIAL_OPTION: ID + "UPDATE_MATERIAL_OPTION",
    ADD_SAMPLE_LOCATION: ID + "ADD_SAMPLE_LOCATION",
    DELETE_SAMPLE_LOCATION: ID + "DELETE_SAMPLE_LOCATION",
    UPDATE_SAMPLE_LOCATION: ID + "UPDATE_SAMPLE_LOCATION",

    GET_EXPORT_DATA: ID + "GET_EXPORT_DATA",
    SHOW_RIGHT_GRAY_BG: ID + "SHOW_RIGHT_GRAY_BG",
    TOGGLE_SAVE: ID + "TOGGLE_SAVE",
    TOGGLE_SAVE_IN_PROGRESS: ID + "TOGGLE_SAVE_IN_PROGRESS",

    FLOOR_SELECTED: ID + "FLOOR_SELECTED",
    SECTION_SELECTED: ID + "SECTION_SELECTED",
    SUB_SECTION_SELECTED: ID + "SUB_SECTION_SELECTED",
    TOGGLE_LEGEND: ID + "TOGGLE_LEGEND",
    SET_REF: ID + "SET_REF",
    GET_BACKEND_FOR_DAMAGE_TYPE_RESULTS: ID + "GET_BACKEND_FOR_DAMAGE_TYPE_RESULTS",
    TOGGLE_IS_LOADING: ID + "TOGGLE_IS_LOADING",
    TOGGLE_IS_PRELOADER: ID + "TOGGLE_IS_PRELOADER",
    GET_USERS: ID + "GET_USERS",
    DELETE_USER: ID + "DELETE_USER",
    EDIT_USER: ID + "EDIT_USER",
    ADD_USER: ID + "ADD_USER",
    SAVE_USERS: ID + "SAVE_USERS",
    USERS_ERRORS: ID + "USERS_ERRORS",
    DELETE_PHOTO: ID + "DELETE_PHOTO",
    SAVE_PHOTO: ID + "SAVE_PHOTO",

    ADD_SCREENSHOT: "ADD_SCREENSHOT",
    REMOVE_SCREENSHOT: "REMOVE_SCREENSHOT",
    DOWNLOAD_STATUS_CHANGED: "DOWNLOAD_STATUS_CHANGED"
}

const defaultState = {
    error: null,
    companies: [],
    company_data: null,
    currentIncidentId: -1,
    damages: [],
    damagesDoc: null,
    damage_data: null,
    probe: null,
    exports: null,
    rightShowGrayBG: false,
    save: false,
    saveInProgress: false,
    currentDamage: -1,
    probes: null,
    corrections: null,
    screenshots: null,
    currentFloor: null,
    lastReadingProbes: "",
    currentSection: 0,
    backend_results: null,
    currentSubSection: 0,
    legend: null,
    ref: null,
    currentSampleLayer: 0, // the id
    exportsDoc: null,
    files: null,
    backend_for_damage_type: { 'tests': {} },
    isLoading: true,
    isPreloader: true,
    probesForCurrentInvestigation: [],
    users: [],
    errors: {
        'users': null,
    },
    download_status: null
}

const getItemByValue = (items, key, val) => {
    for (let i = 0; i < items.length; i++)
        if (items[i][key] === val)
            return i;
    return -1;
}

const state = (state = defaultState, action) => {
    switch (action.type) {

        case ActionID.GET_COMPANIES:
            state.companies = action.data;
            state.probe = null;
            state.probes = null;
            state.screenshots = null;
            return {
                ...state
            }

        case ActionID.GET_COMPANY_DATA:
            state.company_data = action.data.incident_data;
            state.currentIncidentId = action.data.currentIncident.id;
            state.probe = null;
            state.probes = null;
            state.screenshots = null;
            return {
                ...state
            }

        case ActionID.RELOAD_COMPANY_DATA:
            state.company_data = action.data.incident_data;
            return {
                ...state
            }

        case ActionID.GET_SAMPLE_LAYER:
            state.probe = null;
            state.probes = action.data.probes;
            state.legend = action.data.legend;
            state.screenshots = action.data.screenshots;
            state.lastReadingProbes = new Date().getTime();
            state.currentSampleLayer = action.data.id;
            return {
                ...state
            }

        case ActionID.GET_ROOM_NAMES_LAYER:
            state.roomNames = action.data.roomNames;
            return {
                ...state
            }

        case ActionID.GET_ROOM_LINES_LAYER:
            state.roomLines = action.data.roomLines;
            return {
                ...state
            }

        case ActionID.GET_CORRECTIONS:
            state.corrections = action.data.corrections;
            return {
                ...state
            }

        case ActionID.GET_SAMPLES_FOR_CURRENT_INVESTIGATION:
            state.probesForCurrentInvestigation = action.data.probesForCurrentInvestigation;
            return {
                ...state
            }

        case ActionID.GET_ROOM_NAMES_FOR_CURRENT_INVESTIGATION:
            state.roomNamesForCurrentInvestigation = action.data.roomNamesForCurrentInvestigation;
            return {
                ...state
            }

        case ActionID.GET_ROOM_LINES_FOR_CURRENT_INVESTIGATION:
            state.roomLinesForCurrentInvestigation = action.data.roomLinesForCurrentInvestigation;
            return {
                ...state
            }

        case ActionID.GET_DAMAGES:
            state.probe = null;
            state.probes = null;
            state.screenshots = null;
            state.damages = action.data.data;
            state.damagesDoc = action.data;
            return {
                ...state
            }

        case ActionID.ADD_DAMAGE:
            //state.damages.push(action.data);
            return {
                ...state
            }

        case ActionID.DELETE_DAMAGE:
            //let itemIndex = getItemByValue(state.damages, "id", action.data.id);
            //state.damages.splice(itemIndex, 1);
            return {
                ...state
            }

        case ActionID.UPDATE_DAMAGE:
            //itemIndex = getItemByValue(state.damages, "id", action.data.id);
            //state.damages[itemIndex].name = action.data.name;
            return {
                ...state
            }

        case ActionID.ADD_TEST:
            //state.damage_data.backend_results.push(action.data);
            return {
                ...state
            }

        case ActionID.DELETE_TEST:
            //let itemIndex = getItemByValue(state.damage_data.backend_results, "id", action.data.id);
            //state.damage_data.backend_results.splice(itemIndex, 1);
            return {
                ...state
            }

        case ActionID.UPDATE_TEST:
            //itemIndex = getItemByValue(state.damage_data.backend_results, "id", action.data.id);
            //state.damage_data.backend_results[itemIndex].name = action.data.name;
            return {
                ...state
            }

        case ActionID.ADD_TEST_ITEM:
            return {
                ...state
            }

        case ActionID.DELETE_TEST_ITEM:
            return {
                ...state
            }
        case ActionID.TEST_CHANGED:
            return {
                ...state
            }

        case ActionID.ADD_MATERIAL_OPTION:
            action.data.items.push(action.data.add);
            return {
                ...state
            }

        case ActionID.DELETE_MATERIAL_OPTION:
            action.data.items[action.data.index] = null;
            return {
                ...state
            }

        case ActionID.UPDATE_MATERIAL_OPTION:
            action.data.items[action.data.index].name = action.data.name;
            return {
                ...state
            }

        case ActionID.ADD_SAMPLE_LOCATION:
            action.data.items.push(action.data.add);
            return {
                ...state
            }

        case ActionID.DELETE_SAMPLE_LOCATION:
            action.data.items[action.data.index] = null;
            return {
                ...state
            }

        case ActionID.UPDATE_SAMPLE_LOCATION:
            action.data.items[action.data.index].name = action.data.name;
            if (action.data.short)
                action.data.items[action.data.index].short = action.data.short;
            return {
                ...state
            }

        case ActionID.UPDATE_DAMAGE_DATA:
            //state.damage_data = action.data;
            return {
                ...state
            }

        case ActionID.GET_EXPORT_DATA:
            state.company_data = null;
            state.damage_data = null;
            state.exportsDoc = action.data;
            state.exports = action.data.exports;
            return {
                ...state
            }

        case ActionID.TOGGLE_SAVE:
            state.save = action.data;
            return {
                ...state
            }

        case ActionID.TOGGLE_SAVE_IN_PROGRESS:
            state.saveInProgress = action.data;
            return {
                ...state
            }

        case ActionID.GET_DAMAGE_DATA:
            state.damage_data = action.data;
            state.currentDamage = action.data.docID;
            return {
                ...state
            }

        case ActionID.GET_BACKEND_RESULTS:
            state.backend_results = action.data;
            return {
                ...state
            }


        case ActionID.ERROR:
            state.error = action.error;
            return {
                ...state,
            }

        case ActionID.SHOW_RIGHT_GRAY_BG:
            state.rightShowGrayBG = action.data;
            return {
                ...state,
            }

        case ActionID.FLOOR_SELECTED:
            state.currentFloor = action.data;
            return {
                ...state
            }

        case ActionID.SECTION_SELECTED:
            state.currentSection = action.data;
            state.damage_data = null;
            return {
                ...state
            }

        case ActionID.SUB_SECTION_SELECTED:
            state.currentSubSection = action.data;
            return {
                ...state
            }

        case ActionID.TOGGLE_LEGEND:
            state.legend[action.data.index].visible = action.data.visible;
            return {
                ...state
            }

        case ActionID.SET_REF:
            state.ref = new Date().getTime();
            return {
                ...state
            }

        case ActionID.GET_BACKEND_FOR_DAMAGE_TYPE_RESULTS:
            state.backend_for_damage_type = action.data;
            if (!state.backend_for_damage_type.tests) {
                state.backend_for_damage_type.tests = {};
            }
            const tests = state.backend_for_damage_type.tests;
            for (let i = 0; i < state.backend_results.tests.length; i++) {
                const test = state.backend_results.tests[i];
                if (!tests[test.id]) {
                    tests[test.id] = {
                        'details': {
                            'active': 0,
                            'values': [],
                        }
                    }
                }
                if (test.type === "0") {
                    if (tests[test.id].details.values.length === 0) {
                        tests[test.id].details.values.push(
                            { 'id': get.randomID() }
                        )
                    }
                }
            }
            return {
                ...state
            }

        case ActionID.TOGGLE_IS_LOADING:
            state.isLoading = action.data;
            return {
                ...state
            }

        case ActionID.TOGGLE_IS_PRELOADER:
            state.isPreloader = action.data;
            return {
                ...state
            }

        case ActionID.GET_USERS:
            state.users = action.data;
            return {
                ...state
            }

        case ActionID.DELETE_USER:
            state.users.splice(action.data.index, 1);
            return {
                ...state
            }

        case ActionID.EDIT_USER:
            state.users[action.data.index][action.data.name] = action.data.value;
            return {
                ...state
            }

        case ActionID.ADD_USER:
            state.users.push(action.data);
            return {
                ...state
            }

        case ActionID.SAVE_USERS:
            state.errors.users = null;
            return {
                ...state
            }

        case ActionID.USERS_ERRORS:
            state.errors.users = action.data;
            return {
                ...state
            }

        case ActionID.DELETE_PHOTO:
            return {
                ...state
            }

        case ActionID.SAVE_PHOTO:
            return {
                ...state
            }

        case ActionID.ADD_SCREENSHOT:
            return {
                ...state,
                screenshots: [...state.screenshots, action.data.new_screenshots]
            }

        case ActionID.REMOVE_SCREENSHOT:
            return {
                ...state,
                screenshots: state.screenshots.filter((screenshot) => screenshot.id !== action.data.id)
            }

        case ActionID.DOWNLOAD_STATUS_CHANGED:
            console.log("Set new download status: ", action.data);
            return {
                ...state,
                download_status: action.data
            }

        default:
            return {
                ...state,
            }

    }
}

export default state;

export const initApp = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    //console.log(loginData);
    if (!loginData) {
        return;
    }

    PouchDB.startSync(() => {

        dispatch(getBackendResults());
        dispatch(getDamages());
        dispatch(getExports());
        dispatch(toggleIsLoading(false));
        dispatch(togglePreloader(false));


    }, (error) => {
        dispatch(togglePreloader(false));
    });
}

export const getBackendResults = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    PouchDB.get("backend_results", (retData) => {
        const data = retData;
        dispatch({
            type: ActionID.GET_BACKEND_RESULTS, data,
        })
    }, (err) => {
        if (err.status === 404) {
            PouchDB.put("backend_results", { 'tests': [] }, (retData) => {
                const data = retData;
                dispatch({
                    type: ActionID.GET_BACKEND_RESULTS, data,
                })
            });
        }
    });
}

export const getDamages = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }
    PouchDB.get("damage_types", (retData) => {
        const data = retData;
        dispatch({
            type: ActionID.GET_DAMAGES, data,
        })
    }, (err) => {
        if (err.status === 404) {
            PouchDB.put("damage_types", { data: [] }, (retData) => {
                const data = retData;
                dispatch({
                    type: ActionID.GET_DAMAGES, data,
                })
            });
        }
    });

}

export const getExports = params => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    PouchDB.get("exports", (data) => {
        dispatch(populateExports(data));
    }, (err) => {
        if (err.status === 404) {
            PouchDB.put("exports", { exports: [{ 'name': '', 'id': get.randomID(), 'width': 0, 'height': 0 }] }, (retData) => {
                dispatch(populateExports(retData));
            });
        }
    });

}

export const getCompanies = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    PouchDB.getAllWithPrefix("incident_").then((incidents) => {
        // if sort is needed, use this
        // const incidents_list = incidents.sort(function (a, b) {
        //     if (a.created_at > b.created_at) {
        //         return 1;
        //     }

        //     if (a.created_at < b.created_at) {
        //         return -1;
        //     }

        //       return 0;
        // });

        dispatch({
            type: ActionID.GET_COMPANIES, data: incidents.sort((a, b) => (a.number < b.number) ? 1 : -1)
        })
    });
}

export const getCompanyData = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }
    //console.log(params);

    generateIncidentData(param.id).then(incident_data => {
        const data = {
            incident_data: incident_data,
            currentIncident: param,
        }

        dispatch({
            type: ActionID.GET_COMPANY_DATA, data,
        })

        dispatch(getDamageData({ id: data.currentIncident.damage_type_id }));
    });
}

export const reloadIncidentData = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    generateIncidentData(param.id).then(incident_data => {
        const data = {
            incident_data: incident_data
        }

        dispatch({
            type: ActionID.RELOAD_COMPANY_DATA, data,
        })
    });
}

const checkLegend = (legend, legentText /* param, type, sourceType, sl2 */ ) => {
    for (let i = 0; i < legend.length; i++) {
        const item = legend[i];

        if(item.text === legentText) {
            return true;
        }

        // if (sourceType === 0) {
        //     if (type === 0) {
        //         if (param.color === item.color && param.form === item.form) {
        //             return true;
        //         }
        //     }
        //     if (type === 1) {
        //         if (param.color === item.color && param.form === item.form) {
        //             return true;
        //         }
        //     }
        //     if (type === 2) {
        //         if (param.color === item.color && param.form === item.form && param.sample_location_2 === sl2) {
        //             return true;
        //         }
        //     }
        // }
        // if (sourceType === 1) {
        //     if (param.text === item.text) {
        //         return true;
        //     }
        // }

        // if (sourceType === 2) {
        //     if (param.color === item.color && param.text === item.text) {
        //         return true;
        //     }
        // }
    }

    return false;
}

const buildLegend = (legend, param) => {

    const appState = window.store.getState().appState;

    const color = param.color;
    const form = param.form;
    const type = param.numbering_type;
    const sourceType = param.sourceType;
    let tx = "";
    let gn = "";
    if (sourceType === 0) {
        if (type === 0)
            tx = "Probenahmeorte";
        if (type === 1)
            tx = param.description;
        if (type === 2) {
            const gstl = probe.getSampleTypeLocation2_1(appState, param);
            gn = gstl ? gstl.name : "_";
            tx = "Probenahmeorte " + gn;
        }
    }
    if (sourceType === 1) {
        tx = param.text;
    }

    if (sourceType === 2) {
        tx = param.text;
    }

    // check if the generated text is already in the legend
    const exists = checkLegend(legend, tx /* param, type, sourceType, param.sample_location_2 */);
    if (exists) {
        return;
    }

    legend.push({
        'color': color,
        'form': form,
        'text': tx,
        'sourceType': sourceType,
        'visible': true,
        'sample_layer_id': param.sample_layer_id
    })
}


export const getSampleLayerData = _param => async dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    let data = {
        probes: null, 
        screenshots: null, 
        legend: null,
        id: _param.id
    };

    dispatch({
        type: ActionID.GET_SAMPLE_LAYER, data,
    })

    const samplePrefix = "sample_" + _param.id;
    const testsForSamplesPrefix = "tests_results_for_probe_" + _param.id;

    const samples = await PouchDB.getAllWithPrefix(samplePrefix);
    const tests_results_for_samples = await PouchDB.getAllWithPrefix(testsForSamplesPrefix);

    const screenshotsPrefix = "screenshot_" + _param.id;
    const screenshots = await PouchDB.getAllWithPrefix(screenshotsPrefix);
    
    const probes = [];
    const legend = [];

    for (let sample of samples) {
        sample.tests = getTestResultsForSample(tests_results_for_samples, sample.id);

        buildLegend(legend, sample);
        probes.push(sample);
    }

    data = {};
    data.probes = probes;
    data.legend = legend;
    data.screenshots = screenshots;
    data.id = _param.id;

    //console.log(probes);
    dispatch({
        type: ActionID.GET_SAMPLE_LAYER, data,
    })
}


export const getRoomNamesLayerData = _param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    let data = {'roomNames': null };
    dispatch({
        type: ActionID.GET_ROOM_NAMES_LAYER, data,
    })

    const roomNamePrefix = "roomname_" + _param.id;

    PouchDB.getAllWithPrefix(roomNamePrefix).then((rNames) => {
        const roomNames = [];

        for (let roomName of rNames) {
            roomNames.push(roomName);
        }

        data = {};
        data.roomNames = roomNames;

        dispatch({
            type: ActionID.GET_ROOM_NAMES_LAYER, data,
        })
    });
}


export const getRoomLinesLayerData = _param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    let data = {'roomLines': null };
    dispatch({
        type: ActionID.GET_ROOM_LINES_LAYER, data,
    })

    const roomLinePrefix = "roomline_" + _param.id;

    PouchDB.getAllWithPrefix(roomLinePrefix).then((rLines) => {
        const roomLines = [];

        for (let roomLine of rLines) {
            roomLines.push(roomLine);
        }

        data = {};
        data.roomLines = roomLines;

        dispatch({
            type: ActionID.GET_ROOM_LINES_LAYER, data,
        })
    });
}


export const getFloorCorrectionsData = _param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData || !_param) {
        return;
    }

    let data = {'corrections': null };
    dispatch({
        type: ActionID.GET_CORRECTIONS, data,
    })

    const correctionPrefix = "floor_correction_" + _param.id;

    PouchDB.getAllWithPrefix(correctionPrefix).then((corrections) => {
        // const corrections = [];

        // for (let correction of corrections) {
        //     corrections.push(correction);
        // }

        data = {};
        data.corrections = corrections;

        dispatch({
            type: ActionID.GET_CORRECTIONS, data,
        })
    });
}

export const getAllSamplesForCurrentInvestigation = _param => async dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;

    const probesForCurrentInvestigation = [];

    const floors = appState.company_data.items;
    if (floors) {
        for (let i = 0; i < floors.length; i++) {
            const floor = floors[i];
            
            const investiongations = floor.items;
            for (let j = 0; j < investiongations.length; j++) {

                const investiongation = investiongations[j];
                
                const sampleLayers = investiongation.items;
                for (let k = 0; k < sampleLayers.length; k++) {
                    const sampleLayer = sampleLayers[k];
                    
                    const samplePrefix = "sample_" + sampleLayer.id;

                    await PouchDB.getAllWithPrefix(samplePrefix).then((samples) => {

                        for (let sample of samples) {
                            probesForCurrentInvestigation.push(sample);
                        }
                    });
                }
            }
        }
    }

    const data = {
        probesForCurrentInvestigation
    }

    dispatch({
        type: ActionID.GET_SAMPLES_FOR_CURRENT_INVESTIGATION, data,
    })
}

export const getAllRoomNamesForCurrentInvestigation = _param => async dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;

    const roomNamesForCurrentInvestigation = [];

    const floors = appState.company_data.items;
    if (floors) {
        for (let i = 0; i < floors.length; i++) {
            const floor = floors[i];
            
            const investiongations = floor.items;
            for (let j = 0; j < investiongations.length; j++) {

                const investiongation = investiongations[j];
                
                const sampleLayers = investiongation.items;
                for (let k = 0; k < sampleLayers.length; k++) {
                    const sampleLayer = sampleLayers[k];
                    
                    const roomNamePrefix = "roomname_" + sampleLayer.id;

                    await PouchDB.getAllWithPrefix(roomNamePrefix).then((roomNames) => {

                        for (let roomName of roomNames) {
                            roomNamesForCurrentInvestigation.push(roomName);
                        }
                    });
                }
            }
        }
    }

    const data = {
        roomNamesForCurrentInvestigation
    }

    dispatch({
        type: ActionID.GET_ROOM_NAMES_FOR_CURRENT_INVESTIGATION, data,
    })
}

export const getAllRoomLinesForCurrentInvestigation = _param => async dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;

    const roomLinesForCurrentInvestigation = [];

    const floors = appState.company_data.items;
    if (floors) {
        for (let i = 0; i < floors.length; i++) {
            const floor = floors[i];
            
            const investiongations = floor.items;
            for (let j = 0; j < investiongations.length; j++) {

                const investiongation = investiongations[j];
                
                const sampleLayers = investiongation.items;
                for (let k = 0; k < sampleLayers.length; k++) {
                    const sampleLayer = sampleLayers[k];
                    
                    const roomLinePrefix = "roomline_" + sampleLayer.id;

                    await PouchDB.getAllWithPrefix(roomLinePrefix).then((roomLines) => {

                        for (let roomLine of roomLines) {
                            roomLinesForCurrentInvestigation.push(roomLine);
                        }
                    });
                }
            }
        }
    }

    const data = {
        roomLinesForCurrentInvestigation
    }

    dispatch({
        type: ActionID.GET_ROOM_LINES_FOR_CURRENT_INVESTIGATION, data,
    })
}

export const addDamage = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    const id = get.randomID();
    appState.damages.push({
        'id': id,
        'name': param,
    });

    PouchDB.put("damage_types", appState.damagesDoc, (retData) => {

        PouchDB.put("settings_for_damage_type_" + id, {
            'app_settings': {
                'app_results_1': [{ 'id': get.randomID(), 'name': "" }],
                'app_results_2': [{ 'id': get.randomID(), 'name': "" }],
                'sample_type': [{ 'id': get.randomID(), 'name': "", 'short': "" }],
                'material_options': [],
                'sample_location': [],
            },
        }, (retData2) => {
            dispatch({
                type: ActionID.ADD_DAMAGE, retData,
            })
        });
    });


}

export const deleteDamage = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    const itemIndex = getItemByValue(appState.damages, "id", param.id);
    appState.damages.splice(itemIndex, 1);

    PouchDB.put("damage_types", appState.damagesDoc, (retData) => {
        dispatch({
            type: ActionID.DELETE_DAMAGE, retData,
        })
    });
}

export const updateDamage = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    const itemIndex = getItemByValue(appState.damages, "id", param.id);
    appState.damages[itemIndex].name = param.newValue;

    PouchDB.put("damage_types", appState.damagesDoc, (retData) => {
        dispatch({
            type: ActionID.UPDATE_DAMAGE, retData,
        })
    });
}

export const getDamageData = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    dispatch(toggleIsLoading(true));

    PouchDB.get("settings_for_damage_type_" + param.id, (retData) => {
        const data = { ...retData, docID: param.id };

        PouchDB.get("backend_for_damage_type_" + param.id, (retData) => {
            const data = retData;
            dispatch({
                type: ActionID.GET_BACKEND_FOR_DAMAGE_TYPE_RESULTS, data,
            })
            dispatch(toggleIsLoading(false));
        }, (err) => {
            if (err.status === 404) {
                PouchDB.put("backend_for_damage_type_" + param.id, { 'tests': {} }, (retData) => {
                    const data = retData;
                    dispatch({
                        type: ActionID.GET_BACKEND_FOR_DAMAGE_TYPE_RESULTS, data,
                    })
                    dispatch(toggleIsLoading(false));
                });
            }
        });


        dispatch({
            type: ActionID.GET_DAMAGE_DATA, data,
        })
    });


}

export const addTest = param => dispatch => {
    const storeState = window.store.getState();
    const loginData = storeState.loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    appState.backend_results.tests.push({
        'name': param.name,
        'type': param.type,
        'id': get.randomID(),
        'details': {
            'active': 0,
            'values': param.type === "0" ? [{ 'id': get.randomID() }] : [],
        }
    })

    PouchDB.put("backend_results", appState.backend_results, (retData) => {
        const data = retData;
        dispatch({
            type: ActionID.ADD_TEST, data,
        })
    });

    dispatch({
        type: ActionID.GET_BACKEND_FOR_DAMAGE_TYPE_RESULTS, data: appState.backend_for_damage_type
    })


}

export const deleteTestItem = (activeTest, testItem) => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;

    const activeTestItems = appState.backend_for_damage_type.tests[activeTest.id].details.values;
    const itemIndex = activeTestItems.findIndex(test => test.id === testItem);
    activeTestItems.splice(itemIndex, 1);

    PouchDB.put(appState.backend_for_damage_type._id, appState.backend_for_damage_type, (retData) => {
        const data = retData;
        dispatch({
            type: ActionID.DELETE_TEST_ITEM, data,
        })
    });
}

export const deleteTest = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    const itemIndex = getItemByValue(appState.backend_results.tests, "id", param.id);
    appState.backend_results.tests.splice(itemIndex, 1);


    PouchDB.put("backend_results", appState.backend_results, (retData) => {
        const data = retData;
        dispatch({
            type: ActionID.DELETE_TEST, data,
        })
    });
}

export const updateTest = param => dispatch => {
    const loginData = window.store.getState().loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    const itemIndex = getItemByValue(appState.backend_results.tests, "id", param.id);
    appState.backend_results.tests[itemIndex].name = param.newValue;


    PouchDB.put("backend_results", appState.backend_results, (retData) => {
        const data = retData;
        dispatch({
            type: ActionID.UPDATE_TEST, data,
        })
    });
}

export const addTestItem = param => dispatch => {

    const appState = window.store.getState().appState;

    const item = appState.backend_for_damage_type.tests[param.id];
    const newData = {};
    if (param.type === "1") {
        newData.name = "";
        newData.id = get.randomID();

        newData.minCol = 0;
        newData.minQnt = 0;
        newData.minShow = true;
        newData.minUnit = "";
        
        newData.col1 = 1;
        newData.qnt1 = 0;
        newData.show1 = true;
        newData.unit1 = "";
        newData.col2 = 2;
        newData.qnt2 = 0;
        newData.show2 = true;
        newData.unit2 = "";
        newData.col3 = 3;
        newData.qnt3 = 0;
        newData.show3 = true;
        newData.unit3 = "";
        newData.col4 = 4;
        newData.qnt4 = 0;
        newData.show4 = true;
        newData.unit4 = "";
        newData.col5 = 5;
        newData.qnt5 = 0;
        newData.show5 = true;
        newData.unit5 = "";
        newData.col6 = 6;
        newData.qnt6 = 0;
        newData.show6 = true;
        newData.unit6 = "";
        newData.col7 = 7;
        newData.qnt7 = 0;
        newData.show7 = true;
        newData.unit7 = "";
        newData.col8 = 8;
        newData.qnt8 = 0;
        newData.show8 = true;
        newData.unit8 = "";
        
        newData.col = 10;
        newData.maxCol = 11;
        newData.maxQnt = 0;
        newData.maxShow = true;
        newData.maxUnit = "";
    }

    item.details.values.push(newData);
    const data = null;
    dispatch({
        type: ActionID.ADD_TEST_ITEM, data,
    })

    dispatch(toggleSave(true));
}

export const addMaterialOption = param => dispatch => {

    const dataToSend = {
        'add': {
            'name': param.value,
            'id': get.randomID(),
            'items': [],
        },
        'items': param.current.items,
    }

    const data = dataToSend;
    dispatch({
        type: ActionID.ADD_MATERIAL_OPTION, data,
    })
    dispatch(toggleSave(true));
}

export const deleteMaterialOption = param => dispatch => {

    const dataToSend = {
        'index': param.index,
        'items': param.items,
    }

    const data = dataToSend;
    dispatch({
        type: ActionID.DELETE_MATERIAL_OPTION, data,
    })
    dispatch(toggleSave(true));
}

export const updateMaterialOption = param => dispatch => {

    const dataToSend = {
        'name': param.newValue,
        'items': param.items,
        'index': param.index,
    }
    const data = dataToSend;
    dispatch({
        type: ActionID.UPDATE_MATERIAL_OPTION, data,
    })
    dispatch(toggleSave(true));
}

export const addSampleLocation = param => dispatch => {

    const dataToSend = {
        'add': {
            'name': param.value,
            'id': get.randomID(),
            'items': [],
        },
        'items': param.current.items,
    }
    if (param.value2 !== undefined) {
        dataToSend.add.short = param.value2;
    }

    const data = dataToSend;
    dispatch({
        type: ActionID.ADD_SAMPLE_LOCATION, data,
    })
    dispatch(toggleSave(true));

}

export const deleteSampleLocation = param => dispatch => {

    const dataToSend = {
        'index': param.index,
        'items': param.items,
    }

    const data = dataToSend;
    dispatch({
        type: ActionID.DELETE_SAMPLE_LOCATION, data,
    })
    dispatch(toggleSave(true));
}

export const updateSampleLocation = param => dispatch => {

    const dataToSend = {
        'name': param.newValue.value,
        'items': param.items,
        'index': param.index,
    }
    if (param.newValue.value2 !== undefined) {
        dataToSend.short = param.newValue.value2;
    }

    const data = dataToSend;
    dispatch({
        type: ActionID.UPDATE_SAMPLE_LOCATION, data,
    })
    dispatch(toggleSave(true));
}

export const populateExports = data => dispatch => {
    dispatch({
        type: ActionID.GET_EXPORT_DATA, data,
    })
}

export const toggleRightBgGray = param => dispatch => {
    const data = param;
    dispatch({
        type: ActionID.SHOW_RIGHT_GRAY_BG, data,
    })
}

export const toggleSave = param => dispatch => {
    const data = param;

    dispatch({
        type: ActionID.TOGGLE_SAVE, data,
    })
}

export const toggleSaveInProgress = param => dispatch => {
    const data = param;
    dispatch({
        type: ActionID.TOGGLE_SAVE_IN_PROGRESS, data,
    })
}

export const updateAppSettings = (param, ev) => dispatch => {
    const store = window.store.getState();
    const app_settings = store.appState.damage_data.app_settings;
    const items = app_settings[param.props.ctg];
    const index = param.props.index;
    const item = items[index];
    item[param.props.nameKey] = ev.target.value;
    const linkValue = param.props.linkTo ? item[param.props.linkTo] : "";

    if(item.id === undefined) {
        item.id = get.randomID();
    }

    if (index === items.length - 1) {
        if (!param.props.linkTo || (linkValue && linkValue !== ""))
            items.push({});
    }

    for (let i = items.length - 2; i >= 0; i--) {
        const exItem = items[i];
        if (exItem.name === "" && (!param.props.linkTo || (linkValue === ""))) {
            items.splice(i, 1);
        } else {
            break;
        }
    }
}

export const testChanged = (param, param2, ev, type) => dispatch => {
    const store = window.store.getState();
    const loginData = store.loginState.data;
    if (!loginData) {
        return;
    }

    const data = store.appState.backend_for_damage_type.tests[param.props.id];
    if (!data) {
        return;
    }
    const values = data.details.values;
    const index = param2.props.id;
    const value = values.find(test => test.id === index);
    if (!value) {
        return null;
    }
    const id = value.id ? value.id : get.randomID();
    const eValVal = ev.target.value;
    value.id = id;

    if (param.props.type === "0") {
        value.name = eValVal;

        if (index === values.length - 1) {
            values.push({});
        }

        for (let i = values.length - 2; i >= 0; i--) {
            if (values[i].name === "") {
                values.splice(i, 1);
            } else {
                break;
            }
        }
    }

    let canDispatch = true;
    if (param.props.type === "1") {
        canDispatch = false;
        if (value[type] !== eValVal) {
            value[type] = eValVal;
            canDispatch = true;
        }
    }

    if (canDispatch) {
        dispatch({
            type: ActionID.TEST_CHANGED, data,
        })

        dispatch(toggleSave(true));
    }
}

export const testActiveChanged = (param, ev) => dispatch => {
    const store = window.store.getState();
    const loginData = store.loginState.data;
    if (!loginData) {
        return;
    }

    const data = store.appState.backend_for_damage_type.tests[param.props.id];
    data.details.active = ev.target.value ? 1 : 0;

    dispatch({
        type: ActionID.TEST_CHANGED, data,
    })


}

export const updateDamageData = param => dispatch => {
    const store = window.store.getState();
    const loginData = store.loginState.data;
    if (!loginData) {
        return;
    }

    const appState = window.store.getState().appState;
    const currentDamage = appState.currentDamage;


    PouchDB.put("settings_for_damage_type_" + currentDamage, appState.damage_data, (retData) => {
        dispatch({
            type: ActionID.UPDATE_DAMAGE_DATA, retData,
        })
    });
}

const saveBackEnd = param => dispatch => {
    const appState = window.store.getState().appState;
    PouchDB.put("backend_results", appState.backend_results, (retData) => {
        dispatch({
            type: ActionID.SET_REF, data: null,
        })
    });
}

const saveBackEndForDamageTypeResult = param => dispatch => {
    const store = window.store.getState();
    const loginData = store.loginState.data;
    if (!loginData) {
        return;
    }

    const data = store.appState.backend_for_damage_type;

    PouchDB.put(data._id, data, (retData) => {
        dispatch({
            type: ActionID.SET_REF, data: null,
        })
    });

}

export const save = param => dispatch => {
    dispatch(updateDamageData());
    dispatch(saveBackEnd());
    dispatch(saveBackEndForDamageTypeResult());
    dispatch(toggleSave(false));
}

export const selectFloor = param => dispatch => {
    dispatch({
        type: ActionID.FLOOR_SELECTED, data: param,
    })
}

export const selectSection = param => dispatch => {
    dispatch({
        type: ActionID.SECTION_SELECTED, data: param,
    })
}

export const selectSubSection = param => dispatch => {
    dispatch({
        type: ActionID.SUB_SECTION_SELECTED, data: param,
    })
}

export const toggleLegend = param => dispatch => {
    dispatch({
        type: ActionID.TOGGLE_LEGEND, data: param,
    })
}

export const setRef = param => dispatch => {
    dispatch({
        type: ActionID.SET_REF, data: null,
    })
}

export const addScreenshot = (screenshot) => dispatch => {
    const appState = window.store.getState().appState;
    const screenshotDBId = "screenshot_" + appState.currentSampleLayer + "_" + screenshot.id;

    PouchDB.put(screenshotDBId, screenshot);

    dispatch({
        type: ActionID.ADD_SCREENSHOT, data: {new_screenshots: screenshot}
    });
    
    // Reload the incident data(company_data) to have the screenshot available in the exports tab
    if(appState.currentIncidentId !== "") {
        const currentCompany = appState.companies.find(comp => comp.id === appState.currentIncidentId);
        if(currentCompany) {
            dispatch(reloadIncidentData(currentCompany));
        }
    }
}

export const removeScreenshot = (screenshot_id) => dispatch => {
    console.log('deleting screenshot ' + screenshot_id)

    const appState = window.store.getState().appState;
    const screenshotDBId = "screenshot_" + appState.currentSampleLayer + "_" + screenshot_id;

    PouchDB.remove(screenshotDBId);

    dispatch({
        type: ActionID.REMOVE_SCREENSHOT, data: {id: screenshot_id}
    });

    // Reload the incident data(company_data) to have the screenshot removed from the exports tab
    if(appState.currentIncidentId !== "") {
        const currentCompany = appState.companies.find(comp => comp.id === appState.currentIncidentId);
        if(currentCompany) {
            dispatch(reloadIncidentData(currentCompany));
        }
    }
}

const saveProbes = async () => {
    const appState = window.store.getState().appState;

    // let savedProbesCount = 0;
    for (let i = 0; i < appState.probes.length; i++) {
        const probe = appState.probes[i];
        if(probe.is_changed) {
            // only the data that can be changed in webadmin
            const probeDataToSave = {
                text: probe.text,
                material_option_id_1: probe.material_option_id_1,
                material_option_id_2: probe.material_option_id_2,
                result: probe.result,
                sample_type: probe.sample_type,
                sample_location_1: probe.sample_location_1,
                sample_location_2: probe.sample_location_2,
                sample_location_3: probe.sample_location_3
            }

            await PouchDB.put(probe.id, probeDataToSave);
            // savedProbesCount++;

            probe.is_changed = false;
        }
    }

    // console.log('saved ', savedProbesCount)
}

const saveExport = async () => {
    // iterates over all investigations and saves the export settings
    const appState = window.store.getState().appState;

    // tab4 has check over all floors, so iterate over all
    const floors = appState.company_data.items;
    for (let i = 0; i < floors.length; i++) {
        const floor = floors[i];

        const investigations = floor.items;
        for (let j = 0; j < investigations.length; j++) {
            const investigation = investigations[j];
            
            const exportSettings = {
                exportCheck1: false,
                exportCheck2: false,
                exportProbes: {},
                investigation_id: investigation.id
            }

            let hasExportSettings = false;
            if(typeof(investigation.exportCheck1) != "undefined") {
                exportSettings.exportCheck1 = investigation.exportCheck1;
                hasExportSettings = true;
            }

            if(typeof(investigation.exportCheck2) != "undefined") {
                exportSettings.exportCheck2 = investigation.exportCheck2;
                hasExportSettings = true;
            }

            if(investigation.exportProbes) {
                exportSettings.exportProbes = {...investigation.exportProbes};
                hasExportSettings = true;
            }

            if(hasExportSettings) {
                const exportSettingsId = "export_settings_" + appState.currentIncidentId + "_" + investigation.id;
                await PouchDB.put(exportSettingsId, exportSettings);
            }

            saveScreenshotsForAllSampleLayers(investigation);
        }
    }
}

const saveScreenshotsForAllSampleLayers = async (investigation) => {
    const sampleLayers = investigation.items;
    for(let j = 0; j < sampleLayers.length; j++) {
        const sampleLayer = sampleLayers[j];

        for(let k = 0; k < sampleLayer.screenshots.length; k++) {
            const screenshot = sampleLayer.screenshots[k];
            await PouchDB.put(screenshot._id, screenshot);
        }

    }
}

const saveTestsAndResults = async () => {
    const appState = window.store.getState().appState;

    const floors = appState.company_data.items;
    for (let i = 0; i < floors.length; i++) {
        const floor = floors[i];

        const investigations = floor.items;
        for (let j = 0; j < investigations.length; j++) {
            const investigation = investigations[j];
            const sample_layers = investigation.items;

            for (let k = 0; k < sample_layers.length; k++) {
                const sample_layer = sample_layers[k];

                if(sample_layer.id === appState.currentSampleLayer && sample_layer.tests) {
                    const tests_data = {
                        sample_layer_id: sample_layer.id,
                        tests: sample_layer.tests
                    }

                    const sample_layer_tests_id = "tests_for_sample_layer_" + investigation.id + "_" + sample_layer.id;
                    await PouchDB.put(sample_layer_tests_id, tests_data);
                }
            }
        }
    }

    const currentProbes = appState.probes;
    for (let j = 0; j < currentProbes.length; j++) {
        const probe = currentProbes[j];

        if(probe.tests) {
            const tests_data = {
                sample_id: probe.id,
                tests: probe.tests
            }

            const tests_results_id = "tests_results_for_probe_" + probe.sample_layer_id + "_" + probe.id;
            await PouchDB.put(tests_results_id, tests_data);
        }
    }
}

// this is called from tab2, tab3 and tab4
export const saveData = () => async dispatch => {
    dispatch(toggleSaveInProgress(true));

    await saveProbes();
    await saveExport();
    await saveTestsAndResults();
    
    await PouchDB.sync((err) => {
        if(err) {
            console.log("Error in sync: ", err);
        }
        else {
            console.log("Sync finished successfully.");
        }

        dispatch({
            type: ActionID.SET_REF, data: null,
        })
    
        dispatch(toggleSaveInProgress(false));
        dispatch(toggleSave(false));
    });
}

// these are the settings for exports
export const saveExports = param => dispatch => {
    const appState = window.store.getState().appState;
    const exportsDoc = appState.exportsDoc;

    PouchDB.put("exports", exportsDoc, (retData) => {
        dispatch({
            type: ActionID.SET_REF, data: null,
        })
        
        dispatch(toggleSave(false));
    });


}

export const executeExport1 = param => dispatch => {
    console.log("execute export 1");
    dispatch({
        type: ActionID.SET_REF, data: null,
    })
}

export const executeExport2_1 = param => dispatch => {
    console.log("execute export 2_1");
    dispatch({
        type: ActionID.SET_REF, data: null,
    })
}

export const executeExport2_2 = param => dispatch => {
    console.log("execute export 2_2");
    dispatch({
        type: ActionID.SET_REF, data: null,
    })
}

export const upload = (param) => dispatch => {
    //console.log(param);
}

export const toggleIsLoading = param => dispatch => {
    dispatch({
        type: ActionID.TOGGLE_IS_LOADING, data: param,
    })
}


export const togglePreloader = param => dispatch => {
    dispatch({
        type: ActionID.TOGGLE_IS_PRELOADER, data: param,
    })
}

const getLastUser = (param) => {
    const users = window.store.getState().appState.users;
    for (let i = users.length - 1; i >= 0; i--) {
        if (users[i].role === param.role) {
            return users[i];
        }
    }

    return null;
}

export const addUser = param => dispatch => {
    const lastUser = getLastUser(param);
    let ok = true;
    if (lastUser) {
        if (lastUser.username === "" || lastUser.email === "" || lastUser.password === "") {
            ok = false;
        }
    }

    if (ok) {
        const data = {
            "username": "",
            "email": "",
            "password": "",
            "role": param.role,
        }

        // dispatch(toggleSave(true));

        dispatch({
            type: ActionID.ADD_USER, data: data,
        })
    }


}

export const getUsers = param => dispatch => {
    /*
    const data = [
        {
            "username": "test1",
            "email": "test1@test.com",
            "password": "test_001",
            "role": "admin",
        },
        {
            "username": "test4",
            "email": "test4@test.com",
            "password": "test_004",
            "role": "app",
        },
    ];
   
    dispatch({
        type: ActionID.GET_USERS, data: data,
    })
     */

    Api.get(GET_USERS, null,
        { 'headers': loginActions.getHeaders() }
        // null
        , (retData) => {
            //console.log(retData);
            dispatch({
                type: ActionID.GET_USERS, data: retData.data.users,
            })
        }, (error) => {
            treatError.treat(error, dispatch);
        });

}

export const deleteUser = param => dispatch => {
    dispatch({
        type: ActionID.DELETE_USER, data: param,
    })

    if (param.id !== undefined) {
        const dataToSend = {
            'user_id': param.id,
        }

        Api.post(DELETE_USER, dataToSend, { 'headers': loginActions.getHeaders() }, (retData) => {
        }, (error) => {
            try {
                treatError(error);
            }
            catch (e) {

            }
        });
    }
}

export const editUser = param => dispatch => {
    dispatch({
        type: ActionID.EDIT_USER, data: param,
    })
}

export const saveUsers = param => dispatch => {
    const users = window.store.getState().appState.users;
    const dataToSend = {
        'users': users,
    }
    Api.post(UPDATE_USERS, dataToSend, { 'headers': loginActions.getHeaders() }, (retData) => {
        const errors = retData.data.errors;
        if (errors) {
            dispatch({
                type: ActionID.USERS_ERRORS, data: errors,
            })
        } else {
            dispatch({
                type: ActionID.SAVE_USERS, data: null,
            })
        }
        dispatch(toggleSave(false));
    }, (error) => {
        try {
            treatError(error);
        }
        catch (e) {

        }
        dispatch(toggleSave(false));
    });

}

export const deletePhotoProbe = (param, param2) => dispatch => {
    param.images.splice(param2, 1);
    dispatch({
        type: ActionID.DELETE_PHOTO, data: null,
    })
}

export const togglePhotoProbe = (param) => dispatch => {

    const names = ["photo1.jpg", "photo2.jpg", "photo3.jpg", "photo4.jpg"];
    const name = names[random.range(0, names.length - 1)];
    const files = window.store.getState().appState.files;
    let id = get.randomID();
    files.files.push({
        'id': id,
        'serverURL': name
    })

    param.id = id;

    PouchDB.put("files", files, (retData) => {
        dispatch(togglePreloader(false));
    });

    dispatch({
        type: ActionID.SAVE_PHOTO, data: null,
    })
}

export const doExport = (param, param2 = "") => dispatch => {
    const downloadId = uuidv4();
    Api.downloadFile(param, downloadId, param2);

    DOWNLOAD_STATUS_CHECK_INTERVAL = setInterval(async () => {
        let result = await Api.getDownloadStatus(downloadId);
        console.log("Get download status data: ", result.data);
        dispatch({
            type: ActionID.DOWNLOAD_STATUS_CHANGED, data: result.data,
        })
        if (result.data === false || result.data.is_done) {
               clearInterval(DOWNLOAD_STATUS_CHECK_INTERVAL);
        }
     }, 2 * 1000);
}

export const resetDownloadStatus = param => dispatch => {
    clearInterval(DOWNLOAD_STATUS_CHECK_INTERVAL);
    dispatch({
        type: ActionID.DOWNLOAD_STATUS_CHANGED, data: null,
    })
}