import React, { Component } from 'react';
import "./tab3.scss";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "states/appState";
import * as Lang from "./Tab3Lang";
import Select from "_modules/select/Select";
import Input from "_modules/input/Input";
import Test2 from "components/tests/test2/Test2";
import * as probe from "_utils/probe";

class Test extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'options': [],
            'options2': [],
            'selected': "",
            'selected2': 0,
            'ref': new Date().getTime(),
        }
//       #FFBAC5 		#FF3178		#C75353
// #FCF19B		#FFFF00		#EADA1E
// #B6FDC4		#60FFBC		#7ADD32
// #B7FFFF		#60F3FF		#1DD2D9
        this.colors = [{
                'color': "#B7FFFF",
            }, {
                'color': "#60F3FF",
            }, {
                'color': "#1DD2D9",
            }, {
                'color': "#B6FDC4",
            }, {
                'color': "#60FFBC",
            }, {
                'color': "#7ADD32",
            }, {
                'color': "#FCF19B",
            }, {
                'color': "#FFFF00",
            }, {
                'color': "#EADA1E",
            }, {
                'color': "#FFBAC5",
            }, {
                'color': "#FF3178",
            }, {
                'color': "#C75353",
            }
        ];

        this.testProps = null;

    }

    componentWillMount() {
        const options = [{
            'label': Lang.SELECT,
            'value': "",
        }];

        for (let i = 0; i < this.props.backend_results.tests.length; i++) {
            const item = this.props.backend_results.tests[i];
            const bItem = this.props.backend_for_damage_type.tests[item.id];
            if (bItem.details.active === 1) {
                options.push({
                    'label': item.name,
                    'value': item.id,
                })
            }
        }

        const sel = this.getTestTypeSelect(this.props.currentSL.tests[this.props.index].selected);
        let selected = sel.index + 1;
        if (sel.index + 1 >= options.length) {
            selected = 0;
        }

        let selected22 = this.getTestTypeSelect2(sel, this.props.currentSL.tests[this.props.index].selected2);

        this.setState({
            'options': options,
            'selected': selected,
            'selected2': selected22,
        })
    }

    getTestTypeSelect = (param) => {
        // Returning index here(i) should take into account only the selected(active) tests
        // This does not work properly if any of the tests before the selected one is not checked as active in settings
        let index = 0;
        for (let i = 0; i < this.props.backend_results.tests.length; i++) {
            const item = this.props.backend_results.tests[i];
            const bItem = this.props.backend_for_damage_type.tests[item.id];
            if (item.id === param) {
                return {
                    'item': bItem,
                    'oItem': item,
                    'index': index,
                }
            }
            // Index only the active(checked tests in the settings area) and return this new index to fix the bug mentioned before
            if (bItem.details.active === 1) {
                index++;
            }
        }

        return {
            'index': -1,
        };
    }

    getTestTypeSelect2 = (param, id) => {
        if (!param.item) {
            return 0;
        }

        if (param.oItem.type === "1") {
            for (let i = 0; i < param.item.details.values.length; i++) {
                const item = param.item.details.values[i];
                if (item.id === id) {
                    return item.id;
                }
            }

            // return first option in case there were no selctions made already
            // const firstOption = param.item.details.values && param.item.details.values[0] && param.item.details.values[0].id ? param.item.details.values[0] : {'id': 0};
            // return firstOption.id;
        }

        return 0;
    }

    getTestTypeProps = (param) => {
        this.testProps = null;

        for (let i = 0; i < this.props.backend_results.tests.length; i++) {
            const item = this.props.backend_results.tests[i];
            const bItem = this.props.backend_for_damage_type.tests[item.id];
            if (item.id === param) {
                this.testProps = {
                    'details': {
                        'active': bItem.details.active,
                        // 'backend_result_id': bItem.details.backend_result_id,
                        'values': [],
                        'id': item.id,
                        'type': item.type,
                    },
                    'id': item.id,
                    'type': item.type,
                };

                for (let j = 0; j < bItem.details.values.length; j++) {
                    const val = bItem.details.values[j];
                    this.testProps.details.values.push({
                        'id': val.id,

                        'minCol': val.minCol,
                        'minQnt': val.minQnt,
                        'minShow': val.minShow,
                        'minUnit': val.minUnit,

                        'col1': val.col1,
                        'qnt1': val.qnt1,
                        'show1': val.show1,
                        'unit1': val.unit1,
                        'col2': val.col2,
                        'qnt2': val.qnt2,
                        'show2': val.show2,
                        'unit2': val.unit2,
                        'col3': val.col3,
                        'qnt3': val.qnt3,
                        'show3': val.show3,
                        'unit3': val.unit3,
                        'col4': val.col4,
                        'qnt4': val.qnt4,
                        'show4': val.show4,
                        'unit4': val.unit4,
                        'col5': val.col5,
                        'qnt5': val.qnt5,
                        'show5': val.show5,
                        'unit5': val.unit5,
                        'col6': val.col6,
                        'qnt6': val.qnt6,
                        'show6': val.show6,
                        'unit6': val.unit6,
                        'col7': val.col7,
                        'qnt7': val.qnt7,
                        'show7': val.show7,
                        'unit7': val.unit7,
                        'col8': val.col8,
                        'qnt8': val.qnt8,
                        'show8': val.show8,
                        'unit8': val.unit8,

                        'col': val.col,

                        'maxCol': val.maxCol,
                        'maxQnt': val.maxQnt,
                        'maxShow': val.maxShow,
                        'maxUnit': val.maxUnit,

                        'name': val.name,
                    });
                }

            }
        }
    }

    buildTestProbes = (param, key) => {
        if (param.sourceType === 2){
            return;
        }

        if (param.sample_layer_id !== this.props.appState.currentSampleLayer) {
            return null;
        }


        const options = [
            {
                'label': Lang.SELECT,
                'value': -1,
            }
        ]
        if (this.testProps) {
            const tValues = this.testProps.details.values;
            for (let i = 0; i < tValues.length; i++) {
                if (tValues[i].name !== undefined) {
                    options.push({
                        'label': tValues[i].name,
                        'value': i,
                    })
                }
            }
        }

        if (this.testProps) {
            if (this.testProps.type === "1") {
                const test = this.props.currentSL.tests[this.props.index];

                const settings = test.settings[test.selected2];
                if (settings) {
                    const detVals = this.testProps.details.values.find(t => t.id === test.selected2);
                    Object.assign(detVals, settings);
                }

                let val = (param.tests && param.tests[this.props.index] !== undefined) ? param.tests[this.props.index].value : "";
                if (val === undefined) {
                    val = "";
                }


                //if (this.props.first2 === 0 && val === -1) {
                // val = "";
                // }
                let cVal = 0;

                if (val !== "") {
                    const tVal = this.testProps.details.values.find(test => test.id === this.state.selected2);

                    if (this.testProps.details.values.length !== 0) {

                        const minVal = parseFloat((tVal.minQnt+'').replace(",", "."));
                        const q1Val  = parseFloat((tVal.qnt1+'').replace(",", "."));
                        const q2Val  = parseFloat((tVal.qnt2+'').replace(",", "."));
                        const q3Val  = parseFloat((tVal.qnt3+'').replace(",", "."));
                        const q4Val  = parseFloat((tVal.qnt4+'').replace(",", "."));
                        const q5Val  = parseFloat((tVal.qnt5+'').replace(",", "."));
                        const q6Val  = parseFloat((tVal.qnt6+'').replace(",", "."));
                        const q7Val  = parseFloat((tVal.qnt7+'').replace(",", "."));
                        const q8Val  = parseFloat((tVal.qnt8+'').replace(",", "."));
                        const maxVal = parseFloat((tVal.maxQnt+'').replace(",", "."));

                        const minShow = tVal.minShow;
                        const show1  = tVal.show1;
                        const show2  = tVal.show2;
                        const show3  = tVal.show3;
                        const show4  = tVal.show4;
                        const show5  = tVal.show5;
                        const show6  = tVal.show6;
                        const show7  = tVal.show7;
                        const show8  = tVal.show8;
                        const maxShow  = tVal.maxShow;

                        let fval = parseFloat(val.replace(",", "."));

                        if(fval > maxVal && maxShow) {
                            cVal = tVal.maxCol;
                        } else if(fval > q8Val && show8) {
                            cVal = tVal.col;
                        } else if(fval > q7Val && show7) {
                            cVal = tVal.col8;
                        } else if(fval > q6Val && show6) {
                            cVal = tVal.col7;
                        } else if(fval > q5Val && show5) {
                            cVal = tVal.col6;
                        } else if(fval > q4Val && show4) {
                            cVal = tVal.col5;
                        } else if(fval > q3Val && show3) {
                            cVal = tVal.col4;
                        } else if(fval > q2Val && show2) {
                            cVal = tVal.col3;
                        } else if(fval > q1Val && show1) {
                            cVal = tVal.col2;
                        } else if(fval > minVal && minShow) {
                            cVal = tVal.col1;
                        } else {
                            cVal = tVal.minCol;
                        }
                    }
                }

                const color = this.colors[cVal];

                return (
                    <div key={key} className="test-probe-test-2">
                        <Input index={key} type="text" onChange={this.change} key={key} className="input input-test" value={val} />
                        {val !== "" ? <div style={{ backgroundColor: color.color }} className="shape"></div> : null}
                    </div>
                )
            }
        }

        let vIndex = -1;
        if (options.length > 1 && param.tests && param.tests[this.props.index]) {
            const value =  param.tests[this.props.index].value; 
            if (value !== undefined){
                vIndex = value;
            }
        }

        return (
            <Select index={key} manualValues={true} onSelect={this.selectTest1Option} key={key} options={options} values={[options[vIndex + 1]]} />
        )

    }

    selectTest1Option = (param, param2, param3) => {
        const probe = this.props.probes[param.props.index];
        const index = this.props.index;

        if (!param2[0]) {
            return null;
        }

        if (!probe.tests) {
            probe.tests = {};

        }

        if (!probe.tests[index]) {
            probe.tests[index] = {};
        }

        if (param3 > 2) {
            if (param.clicked) {
                this.props.actions.toggleSave(true);
                probe.tests[index].value = param2[0].value;
            }
        }

        // console.log("Probe", probe);
        // console.log("Probe tests", probe.tests);

        this.setState({
            'ref': new Date().getTime(),
        });
    }

    change = (param, ev) => {
        let regexpFloat = /^[0-9]*(,[0-9]{0,2})*$/
        if(!regexpFloat.test(ev.target.value)) {
            this.setState({
                'ref': new Date().getTime(),
            });

            return false;
        }

        const probe = this.props.probes[param.props.index];
        const index = this.props.index;

        if (!probe.tests) {
            probe.tests = {};

        }
        if (!probe.tests[index]) {
            probe.tests[index] = {};
        }
        probe.tests[index].value = ev.target.value;

        this.props.actions.toggleSave(true);


        this.setState({
            'ref': new Date().getTime(),
        });
    }

    test2Changed = (param, param2, param3, param4) => {
        let regexpFloat = /^[0-9]*(,[0-9]{0,2})*$/;
        if(param4 !== 'minUnit') {
            if(!regexpFloat.test(param3.target.value)) {
                this.setState({
                    'ref': new Date().getTime(),
                });
                
                return false;
            }
        }

        const selected2 = this.state.selected2;
        const value = this.testProps.details.values.find(test => test.id === selected2);
        const index = this.props.index;
        const test = this.props.currentSL.tests[index];

        value[param4] = param3.target.value;

        let canDo = true;
        if (param3.change !== undefined) {
            if (param3.change < 3) {
                canDo = false;
            }
        }

        if (canDo) {
            if (!test.settings[test.selected2]) {
                test.settings[test.selected2] = {};
            }

            test.settings[test.selected2][param4] = value[param4];
            // ????? same thing ???
            if (param.clicked !== undefined && param.clicked === true) {
                this.props.actions.toggleSave(true);
            } else {
                this.props.actions.toggleSave(true);
            }
        }


        this.setState({
            'ref': new Date().getTime(),
        })
    }

    select2 = (param, param2) => {
        const select2 = param2[0].value;

        this.setState({
            'selected2': select2,
        })

        this.props.onSelect2(this, param, param2);
    }

    select = (param, param2) => {
        this.props.onSelect(this, param, param2);
        this.getTestTypeProps(param2[0].value);
        const options2 = [];

        if (this.testProps) {
            if (this.testProps.type === "1") {
                for (let i = 0; i < this.testProps.details.values.length; i++) {
                    const item = this.testProps.details.values[i];
                    options2.push({
                        'label': item.name,
                        'value': item.id,
                    });
                }

            }
        }

        this.setState({
            'options2': options2,
        })
    }

    render() {
        const selected2Option = this.state.options2.find(option => option.value === this.state.selected2);
        const selected2 = selected2Option === undefined ? (this.state.options2.length > 0 ? this.state.options2[0] : 0) : selected2Option;

        return (
            <div className="test">
                <Select onSelect={this.select} options={this.state.options} values={[this.state.options[this.state.selected]]} />
                {(this.testProps && this.testProps.type === "1" && this.state.options2.length > 0) ?
                    <div className="select2">
                        <Select onSelect={this.select2} options={this.state.options2} values={[selected2]} />
                        <Test2
                            action={this.props.actions}
                            onChange={this.test2Changed}
                            {...this.testProps}
                            showOnly={this.state.selected2}
                            backend_for_damage_type={this.props.backend_for_damage_type}
                        />
                    </div>
                    : null}
                {(this.testProps && this.testProps.type === "1" && this.state.options2.length === 0) ? null :
                    <div className={"test-probes" + ((this.testProps && this.testProps.type === "1") ? " t2" : "")} >
                        {this.props.probes.map(this.buildTestProbes)}
                    </div>
                }
            </div>
        )
    }
}

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'currentSL': null,
            'ref': new Date().getTime(),
        };
    }

    getCurrentSampleLayer = (param) => {
        const items = this.props.appState.company_data.items;
        for (let i = 0; i < items.length; i++) {
            const floor = items[i];
            for (let j = 0; j < floor.items.length; j++) {
                const investigation = floor.items[j];
                for (let k = 0; k < investigation.items.length; k++) {
                    const sl = investigation.items[k];
                    if (sl.id === this.props.appState.currentSampleLayer) {
                        return sl;
                    }
                }
            }
        }
    }

    addBlankTest = () => {
        return (
            {
                'selected': "",
                'settings': {},
            }
        )
    }

    componentWillMount() {

        if (this.props.appState.probes.length === 0) {
            return (null);
        }

        const currentSL = this.getCurrentSampleLayer();
        if (!currentSL.tests) {
            currentSL.tests = [];
            currentSL.tests.push(this.addBlankTest());
        }

        for (let i = 0; i < currentSL.tests.length; i++) {
            currentSL.tests[i].first = true;
            currentSL.tests[i].first2 = 0;
        }

        this.setState({
            'currentSL': currentSL,
        })

    }

    buildProbes = (param, key) => {

        if (param.sourceType === 2){
            return;
        }

        if (param.sample_layer_id !== this.props.appState.currentSampleLayer) {
            return;
        }

        const nm = probe.getProbeName(this.props.appState, param, false);
        return (
            <div key={key} className="probe">
                {nm}
            </div>
        )
    }

    selectTestType = (inst, param, param2) => {
        if (param2.length > 0) {
            const tests = this.state.currentSL.tests;
            const val = param2[0].value;
            const index = inst.props.index;

            if (!tests[index]) {
                return;
            }
            const iFirst = tests[index].first;
            tests[index].first = false;

            if (val !== tests[index].selected) {
                tests[index].selected = val;

                if (index === tests.length - 1) {
                    if (val >= 0) {
                        tests.push(this.addBlankTest());
                    }
                }

                if (iFirst) {
                    return;
                }

                this.props.actions.toggleSave(true);

                if (index === tests.length - 2) {
                    if (val === "") {
                        while (tests[tests.length - 1].selected === "") {
                            tests.splice(-1, 1);
                            if (tests.length === 0) {
                                break;
                            }
                        }
                        tests.push(this.addBlankTest());
                    }
                }


                for (let i = 0; i < inst.props.probes.length; i++) {
                    const item = inst.props.probes[i];
                    if (item.tests && item.tests[index] !== undefined) {
                        delete item.tests[index];
                    }
                }

                this.setState({
                    'ref': new Date().getTime(),
                })
            }
        }
    }

    selectTestType2 = (inst, param, param2) => {
        if (param2.length > 0) {
            const tests = this.state.currentSL.tests;
            const val = param2[0].value;
            const index = inst.props.index;

            if (!tests[index]) {
                return;
            }

            const iFirst2 = tests[index].first2;

            if (val !== tests[index].selected) {
                tests[index].selected2 = val;
                tests[index].first2++;

                if (iFirst2 < 3) {
                    return;
                }

                this.props.actions.toggleSave(true);

                tests[index].first2 = 4;

                for (let i = 0; i < inst.props.probes.length; i++) {
                    const item = inst.props.probes[i];
                    if (item.tests && item.tests[index] !== undefined) {
                        delete item.tests[index];
                    }
                }

                this.setState({
                    'ref': new Date().getTime(),
                })
            }
        }
    }


    buildTests = (param, key) => {

        return (
            <Test
                appState={this.props.appState}
                actions={this.props.actions}
                index={key}
                currentSL={this.state.currentSL}
                onSelect={this.selectTestType}
                onSelect2={this.selectTestType2}
                key={key} {...param}
                probes={this.props.appState.probes}
                backend_results={this.props.appState.backend_results}
                backend_for_damage_type={this.props.appState.backend_for_damage_type}
            />
        )
    }

    render() {

        if (this.props.appState.probes.length === 0) {
            return (null);
        }

        // console.clear();
        // console.log(this.props.appState.company_data.items[0]);
        // console.log(this.props.appState.company_data.items[0].items[0]);
        // console.log(this.props.appState.company_data.items[0].items[0].items[0]);
        // console.log(this.props.appState.company_data.items[0].items[0].items[0].tests[0]);
        // console.log(this.props.appState.company_data.items[0].items[0].items[0].tests[0].selected2);

        return (
            <div className="tab3">
                <div className="probes">
                    <div className="title">{Lang.PROBE}</div>
                    {this.props.appState.probes.map(this.buildProbes)}
                </div>

                <div className="tests">
                    {this.state.currentSL.tests.map(this.buildTests)}
                </div>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
