import React, { Component } from 'react';
import "./screenshot.scss";

import Legend from "../legend/Legend";

export default class View extends Component {

    render() {
        // for 96 dpi, we get 96/2,54 => 37,795
        // const cmToPixelRatio = 37.795;

        // for 72 dpi, we get 72/2,54 => 28,35
        const cmToPixelRatio = 28.35;

        const w = (this.props.width * cmToPixelRatio) / 1;
        const h = (this.props.height * cmToPixelRatio) / 1;

        // place top left corner at (20, 20)
        const top = 20;
        const left = 20;

        return (
            <div
                className="screenshot"
                ref="screenShotArea"
                id="screenShotArea"
            >
                <div className="scr-top" style={{ height: top }}>
                </div>
                <div className="scr-middle" style={{ height: h }}>
                    <div className="scr-left" style={{ width: left }}>
                    </div>
                    <div className="scr-center" style={{ width: w }}>
                        <Legend
                            appState={this.props.appState}
                            oneIsVisible={this.props.oneIsVisible}
                            twoIsVisible={this.props.twoIsVisible}
                            scale={this.props.legendScale}
                        />
                    </div>
                    <div className="scr-right">
                    </div>
                </div>
                <div className="scr-bottom">
                </div>

            </div >
        )
    }
}