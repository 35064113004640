import * as PouchDB from "_services/PouchDB";

export const getTestResultsForSample = (tests_results_for_samples, sample_id) => {
    let results_for_sample = null;

    tests_results_for_samples.forEach(tests_results_for_sample => {
        if(tests_results_for_sample.sample_id === sample_id) {
            results_for_sample = tests_results_for_sample.tests;
        }
    });

    return results_for_sample;
}

const getSelectedTestsForSampleLayer = (tests_for_sample_layers, sample_layer_id) => {
    let selected_tests_for_sample_layer = null;

    tests_for_sample_layers.forEach(tests_for_sample_layer => {
        if(tests_for_sample_layer.sample_layer_id === sample_layer_id) {
            selected_tests_for_sample_layer = tests_for_sample_layer.tests;
        }
    });

    return selected_tests_for_sample_layer;
}

const generateSamplesLayerForInvestigation = ({samples_layers, tests_for_sample_layers}, investigation_id) => {
    const samplesLayerForInvestigation = [];

    samples_layers.forEach(async (samples_layer) => {
        if(samples_layer.investigation_id === investigation_id) {
            const selected_tests_for_sample_layer = getSelectedTestsForSampleLayer(tests_for_sample_layers, samples_layer.id);

            const screenshotsPrefix = "screenshot_" + samples_layer.id;
            const screenshots_for_sample_layer = await PouchDB.getAllWithPrefix(screenshotsPrefix);

            samplesLayerForInvestigation.push({
                name: samples_layer.name,
                type: samples_layer.type,
                id: samples_layer.id,
                screenshots: screenshots_for_sample_layer,
                tests: selected_tests_for_sample_layer
            });
        }
    });

    return samplesLayerForInvestigation;
}

const getExportSettingsForInvestigation = (export_settings, investigation_id) => {
    const export_settings_for_investigation = {
        exportCheck1: false,
        exportCheck2: false,
        exportProbes: {},
    }

    export_settings.forEach(export_setting => {
        if(export_setting.investigation_id === investigation_id) {
            export_settings_for_investigation.exportCheck1 = export_setting.exportCheck1;
            export_settings_for_investigation.exportCheck2 = export_setting.exportCheck2;
            export_settings_for_investigation.exportProbes = export_setting.exportProbes;
        }
    });

    return export_settings_for_investigation;
}

const generateInvestigationsForFloor = ({investigations, samples_layers, export_settings}, floor_id) => {
    const investigationsForFloor = [];

    investigations.forEach(async (investigation) => {
        if(investigation.floor_id === floor_id) {
            const {exportCheck1, exportCheck2, exportProbes} = getExportSettingsForInvestigation(export_settings, investigation.id);
            const tests_for_sample_layers = await PouchDB.getAllWithPrefix("tests_for_sample_layer_" + investigation.id);
            const items = generateSamplesLayerForInvestigation({samples_layers, tests_for_sample_layers}, investigation.id)

            investigationsForFloor.push({
                type: investigation.type,
                date: investigation.date,
                id: investigation.id,
                number: investigation.number,
                // samples layers
                items: items,
                exportCheck1,
                exportCheck2,
                exportProbes
            });
        }
    });

    return investigationsForFloor;
}

// reads from db the needed data for an incident and builds a incident data structure for the app
export const generateIncidentData = async (incident_id) => {
    const incident = await PouchDB.getP("incident_" + incident_id);
    const floor_plans = await PouchDB.getAllWithPrefix("floor_" + incident_id);
    const investigations = await PouchDB.getAllWithPrefix("investigation_" + incident_id);
    const samples_layers = await PouchDB.getAllWithPrefix("samples_layer_" + incident_id);
    const export_settings = await PouchDB.getAllWithPrefix("export_settings_" + incident_id);
    
    const incident_data = {
        id: incident_id,
        type: "incident_data",
        damage_type_id: incident.damage_type_id,
        items: [] // floors
    }

    const sorted_floors = floor_plans; // .sort(function (a, b) {
         // if sort is needed, use this
        // if (a.created_at > b.created_at) {
        //     return 1;
        // }

        // if (a.created_at < b.created_at) {
        //     return -1;
        // }

        //     return 0;
    // });

    for(let i = 0; i < sorted_floors.length; i++) {
        const floor = sorted_floors[i];
    
        // copy floor fields
        incident_data.items.push({
            name: floor.name,
            type: floor.type,
            id: floor.id,
            file_id: floor.file_id,
            // investigations
            items: generateInvestigationsForFloor({investigations, samples_layers, export_settings}, floor.id)
        });
    };

    return incident_data;
}
