import React, { Component } from 'react';
import "./button1.scss";

import Button from "_modules/button/Button";

export default class View extends Component {

    clickValue = (params) => {
        if (this.props.onClick) {
            this.props.onClick(this);
        }
    }

    render() {
        return (
            <div className="button1">
                <Button {...this.props} />
                <div onClick={this.clickValue} className="button1-value">{this.props.value}</div>
            </div>
        );
    }
}