import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Lang from "./SettingsLang";
import "./settings.scss";
import * as actions from "states/appState";

import Panel from "components/panel/Panel";
import Damage from "components/damage/Damage";
import Plus from "components/plus/Plus";
import PopupEdit from "components/popup-edit/PopupEdit";
import PopupConfirm from "components/popup-confirm/PopupConfirm";

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            damages: [],
            selectedDamage: null,
            exportsSelected: false,
            backendSelected: false,
            showPopupDamage: false,
            editPopupDamage: false,
            showConfirmDeleteDamagePopup: false
        }

        this.currentDamage = null;
    }

    componentWillMount() {
        this.props.actions.getDamages();
        this.getExports();
    }

    damageSelected = (param = null) => {
        if (!this.props.appState.save) {
            const elems = this.state.damages;

            for (let i = 0; i < elems.length; i++) {
                const elem = elems[i];
                const props = elem.props;
                const data = elem.data;
                data.selected = false;
                if (param) {
                    if (props.id === param.props.id) {
                        data.selected = true;
                    }
                }
            }

            this.setState({
                damages: elems,
                selectedDamage: param,
                exportsSelected: false,
                backendSelected: false
            })

            if (param) {
                this.props.actions.getDamageData(param.props);
                this.props.actions.selectSubSection(0);

            }
        }
    }

    getSelection = (params) => {
        const elems = this.state.damages;
        for (let i = 0; i < elems.length; i++) {
            const elem = elems[i];
            if (elem.props.id === params.id) {
                return elem.data.selected;
            }
        }
        return false;
    }

    componentWillReceiveProps(nextProps) {
        const elems = [];
        if (nextProps.appState.damages) {
            for (let i = 0; i < nextProps.appState.damages.length; i++) {
                const eProps = nextProps.appState.damages[i];
                const elem = {
                    data: {
                        selected: this.getSelection(eProps),
                    },
                    props: eProps,
                }
                elems.push(elem);
            }

            this.setState({
                damages: elems,
            })
        }
    }


    buildDamages = (params, key) => {
        const last = params.props.id === this.props.appState.damages.length;
        return (
            <Damage onClickTT={this.editDamagePopup} isLast={last} onClick={this.damageSelected} key={key} {...params.props} {...params.data} />
        )
    }

    editDamagePopup = (params) => {
        if (!this.props.appState.save) {
            this.currentDamage = params.props;
            this.setState({
                editPopupDamage: true,
            })
        }
    }

    closeEditDamagePopup = (params) => {
        this.setState({
            editPopupDamage: false,
        })
    }

    updateDamage = (params) => {
        this.props.actions.updateDamage({ ...this.currentDamage, 'newValue': params.state.value });
        this.closeEditDamagePopup();
    }

    closeConfirmDeleteDamagePopup = () => {
        this.setState({
            showConfirmDeleteDamagePopup: false
        });
    }

    showConfirmDeleteDamagePopup = testItemId => {
        this.closeEditDamagePopup();
        this.setState({
            showConfirmDeleteDamagePopup: true
        });
    }

    deleteDamage = (params) => {
        this.props.actions.deleteDamage(this.currentDamage);
        this.closeConfirmDeleteDamagePopup();
    }

    getExports = (param) => {
        if (!this.props.appState.save) {
            this.damageSelected();
            this.setState({
                exportsSelected: true,
                backendSelected: false,
            });
            this.props.actions.selectSubSection(1);
        }
    }

    backend = (params) => {
        if (!this.props.appState.save) {
            this.damageSelected();
            this.setState({
                exportsSelected: false,
                backendSelected: true,
            });
            this.props.actions.selectSubSection(2);
        }
    }

    showDamagePopup = (params) => {
        if (!this.props.appState.save) {
            this.setState({
                showPopupDamage: true,
            })
        }
    }

    addDamage = (param) => {
        this.props.actions.addDamage(param.state.value);
        this.closeDamagePopup();
    }

    closeDamagePopup = (params) => {
        this.setState({
            showPopupDamage: false,
        })
    }

    render() {
        return (
            <div className="settings">
                <Panel label={Lang.TITLE} />
                <div className="damages">
                    <div className="damages-damage">
                        {this.state.damages.map(this.buildDamages)}
                    </div>
                    <Plus onClick={this.showDamagePopup} label={Lang.BTN_ADD_DAMAGE} />
                </div>
                <div className="footer">
                    <Damage selected={this.state.exportsSelected} onClick={this.getExports} name={Lang.SETTINGS_1} />
                    <Damage selected={this.state.backendSelected} onClick={this.backend} name={Lang.SETTINGS_2} />
                </div>
                {this.state.showPopupDamage ?
                    <PopupEdit onCancel={this.closeDamagePopup} onAccept={this.addDamage} addClass=" pad" hideClose={true} top={Lang.POPUP_DAMAGE_INPUT_TOP} title={Lang.POPUP_DAMAGE_TITLE} />
                    : null}
                {this.state.editPopupDamage ?
                    <PopupEdit value={this.currentDamage.name} showLeft={true} onDelete={this.showConfirmDeleteDamagePopup} onCancel={this.closeEditDamagePopup} onAccept={this.updateDamage} addClass=" pad" hideClose={true} top={Lang.POPUP_DAMAGE_INPUT_TOP} title={Lang.POPUP_EDIT_DAMAGE_TITLE} />
                    : null}
                {this.state.showConfirmDeleteDamagePopup ?
                    <PopupConfirm showLeft={false} onDelete={this.deleteDamage} onCancel={this.closeConfirmDeleteDamagePopup} onAccept={this.deleteDamage} addClass=" pat" hideClose={true} message={Lang.POPUP_CONFIRM_DELETE_DAMAGE_MESSAGE} title="" ></PopupConfirm>
                    : null}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
