import React, { Component } from 'react';
import "./investigation.scss";

import * as Lang from "./InvestigationLang";
import SampleLayer from "containers/sample-layer/SampleLayer";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedSampleLayer: "",
        }
    }

    componentWillReceiveProps(nextProps) {
        const open = nextProps.selected === nextProps.id ? true : false;

        if (!open) {
            this.setState({
                'selectedSampleLayer': "",
            })
        }
    }

    click = (params) => {
        if (!this.props.appState.save) {
            params.stopPropagation();
            if (this.props.items.length > 0) {
                this.props.onClick(this);
            }
        }
    }

    selectSampleLayer = (param) => {
        if (!this.props.appState.save) {
            this.setState({
                'selectedSampleLayer': param.props.id,
            })
        }
    }


    buildSamplesLayers = (params, key) => {
        return (
            <SampleLayer appState={this.props.appState} key={key} selected={this.state.selectedSampleLayer} onClick={this.selectSampleLayer} {...params} />
        )
    }

    render() {
        // const dis = this.props.items.length === 0 ? " disabled" : "";
        const dis = "";
        const open = this.props.selected === this.props.id ? true : false;

        const options = {
            'day': '2-digit',
            'month': '2-digit',
            'year': 'numeric',
        };
        const date = new Date(this.props.date).toLocaleString("en-GB", options).replace("/", ".").replace("/", ".");
        //console.log(date);

        const nm = Lang.NAME + " " + this.props.number + ", " + date;

        return (
            <div onClick={this.click} className={"investigation" + (open ? " open" : "") + dis} >
                {nm}
                {open ?
                    <div className="samples-layers">
                        {this.props.items.map(this.buildSamplesLayers)}
                    </div>
                    : null}
            </div>
        );
    }
}