import React, { Component } from 'react';
import "./planner.scss";

// import Slider from '@material-ui/lab/Slider';
import PlnRight from "./plnRight/PlnRight";
import PlMain from "./plMain/PlMain";

export default class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interestAreaWidth: 30,
            interestAreaHeight: 15,

            planAreaWidth: window.innerWidth,
            planAreaHeight: window.innerHeight,
            // originalPlanImageWidth: undefined,
            // originalPlanImageHeight: undefined,
            planImageWidth: undefined,
            planImageHeight: undefined,
            planImageScalingPercentage: 100,
            legendScale: 100,
            zoomLevel: 100,
            interestPointRadius: 1,
            oneIsChecked: true,
            twoIsChecked: true,

            blueprintOffsetX: 0,
            blueprintOffsetY: 0,
            blueprintOffsetZoom: 0,

            blueprintScreenShotOffsetX: 0,
            blueprintScreenShotOffsetY: 0,
            blueprintScreenShotOffsetZoom: 0,

            mustJumpToScreenShotArea: false
        }
    }

    componentDidMount() {
        // window.addEventListener("resize", (evt) => {
        //     // console.log("resized");
        //     // this.setState({ planAreaHeight: window.innerHeight, planAreaWidth: window.innerWidth})
        // });
    }

    loadScreenShot(x, y, zoom, zoomLevel, interestAreaWidth, interestAreaHeight, legendScale, interestPointRadius) {
        this.setState({
            blueprintScreenShotOffsetX: x,
            blueprintScreenShotOffsetY: y,
            blueprintScreenShotOffsetZoom: zoom,
            zoomLevel: zoomLevel,
            interestAreaWidth,
            interestAreaHeight,
            legendScale,
            interestPointRadius,
            mustJumpToScreenShotArea: true
        });
    }

    markScreenShotAsLoaded() {
        this.setState({ mustJumpToScreenShotArea: false })
    }

    setInterestAreaWidth(inst, ev) {
        this.setState({ interestAreaWidth: ev.target.value });
    }

    setInterestAreaHeight(inst, ev) {
        // console.log("setInterestAreaHeight", interestAreaHeight);
        this.setState({ interestAreaHeight: ev.target.value });
    }

    setBlueprintOffset(blueprintOffsetX, blueprintOffsetY) {

        // console.log("[[ setting BlueprintOffset ]] = ", blueprintOffsetX, blueprintOffsetY);
        this.setState({ blueprintOffsetX, blueprintOffsetY, mustJumpToScreenShotArea: false });
    }

    setBlueprintOffsetZoom(blueprintOffsetZoom) {
        // console.log(">>> SAVING THE the ZOOM LEVEL OFFSET to",blueprintOffsetZoom);
        this.setState({ blueprintOffsetZoom, mustJumpToScreenShotArea: false });
    }

    handleImageLoaded({ target: img }) {
        this.setState({ originalPlanImageWidth: img.offsetWidth, originalPlanImageHeight: img.offsetHeight });
        // console.log("Setting the width and height of the image", img.offsetWidth);
    }

    onChangeLegendScale(inst, evt) {
        const legendScale = parseInt(evt.target.value);
        // console.log("scaling level:",legendScale);

        this.setState({ legendScale });
    }

    onChangeScaleLevel(inst, evt) {

        const scaleLevel = parseInt(evt.target.value);
        // console.log("scaleLevel", scaleLevel);
        // console.log("Change scale level; image width ", this.state.planImageWidth * (scaleLevel / 100));

        // this.setState({planImageScalingPercentage: scaleLevel, planImageWidth: this.state.originalPlanImageWidth * (scaleLevel / 100)});
        this.setState({ zoomLevel: scaleLevel });
    }

    onChangeRadiusLevel(inst, evt) {
        // sets the radius based on the slider values - its reverse is the function sliderValue in PlnRight.js
        const radius = (parseInt(evt.target.value) - 150) * 2;

        // console.log(`radius level: ${radius} based on ${evt.target.value}`);

        this.setState({ interestPointRadius: radius });
    }



    onChangeOptionOne(inst, evt) {
        this.setState({ oneIsChecked: evt.target.value });
    }

    onChangeOptionTwo(inst, evt) {
        this.setState({ twoIsChecked: evt.target.value });
    }

    render() {
        return (
            <div className="planner">
                <PlMain
                    appState={this.props.appState}
                    interestAreaWidth={this.state.interestAreaWidth}
                    interestAreaHeight={this.state.interestAreaHeight}
                    imageWidth={this.state.planImageWidth}
                    imageHeight={this.state.planImageHeight}
                    planAreaWidth={this.state.planAreaWidth}
                    planAreaHeight={this.state.planAreaHeight}
                    handleImageLoaded={this.handleImageLoaded.bind(this)}
                    zoomLevel={this.state.zoomLevel}
                    legendScale={this.state.legendScale}
                    interestPointRadius={this.state.interestPointRadius}
                    oneIsChecked={this.state.oneIsChecked}
                    twoIsChecked={this.state.twoIsChecked}
                    setInterestAreaWidth={this.setInterestAreaWidth.bind(this)}
                    setInterestAreaHeight={this.setInterestAreaHeight.bind(this)}

                    blueprintOffsetX={this.state.blueprintOffsetX}
                    blueprintOffsetY={this.state.blueprintOffsetY}
                    blueprintOffsetZoom={this.state.blueprintOffsetZoom}

                    blueprintScreenShotOffsetX={this.state.blueprintScreenShotOffsetX}
                    blueprintScreenShotOffsetY={this.state.blueprintScreenShotOffsetY}
                    blueprintScreenShotOffsetZoom={this.state.blueprintScreenShotOffsetZoom}

                    mustJumpToScreenShotArea={this.state.mustJumpToScreenShotArea}

                    setBlueprintOffset={this.setBlueprintOffset.bind(this)}
                    setBlueprintOffsetZoom={this.setBlueprintOffsetZoom.bind(this)}
                    screenShotButtonClicked={this.state.screenShotButtonClicked}
                    markScreenShotAsLoaded={this.markScreenShotAsLoaded.bind(this)}
                />
                <PlnRight
                    appState={this.props.appState}
                    actions={this.props.actions}
                    onChangeScaleLevel={this.onChangeScaleLevel.bind(this)}
                    onChangeRadiusLevel={this.onChangeRadiusLevel.bind(this)}
                    onChangeLegendScale={this.onChangeLegendScale.bind(this)}
                    legendScale={this.state.legendScale}
                    zoomLevel={this.state.zoomLevel}
                    interestPointRadius={this.state.interestPointRadius}

                    oneIsChecked={this.state.oneIsChecked}
                    twoIsChecked={this.state.twoIsChecked}
                    onChangeOptionOne={this.onChangeOptionOne.bind(this)}
                    onChangeOptionTwo={this.onChangeOptionTwo.bind(this)}
                    loadScreenShot={this.loadScreenShot.bind(this)}

                    blueprintOffsetX={this.state.blueprintOffsetX}
                    blueprintOffsetY={this.state.blueprintOffsetY}
                    blueprintOffsetZoom={this.state.blueprintOffsetZoom}
                    interestAreaWidth={this.state.interestAreaWidth}
                    interestAreaHeight={this.state.interestAreaHeight}
                />
            </div>
        )
    }
}