// this file contain the api arround pouchdb, so that the details of getting the info are hidden

import * as PouchDB from './PouchDB';

export const getFile = fileId => {
    return new Promise(function(resolve, reject) {
        PouchDB.get("file_" + fileId,
            (fileDoc) => {
                resolve(fileDoc.serverURL);
            },
            (err) => {
                console.log("error retrieving pouch doc for file " + fileId);
                console.log(err);

                resolve(null);
            }
        );
    });
}
