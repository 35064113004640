import React, { Component } from 'react';
import "./popup-download.scss";
import * as Lang from "./PopupDownloadLang";

import Popup from "_modules/popup/Popup";
import Button from "_modules/button/Button";
import Button3 from "_modules/button3/Button3";

import ProgressBar from "@ramonak/react-progress-bar";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companyDataId: this.props.companyDataId,
            progress: 0
        }

    }

    cancel = (param) => {
        this.props.onCancel(this);
    }

    accept = (param) => {
        this.props.onAccept(this.props.download_status.id);
    }

    render() {
        console.log("Popup download - download status: ", this.props.download_status);
        const adClass = this.props.addClass ? this.props.addClass : "";

        return (
            <Popup {...this.props} title={this.props.title} className={"popup popup-download" + (this.props.top ? " top" : "") + adClass} >
                <div className="pedit-top">
                    {this.props.download_status
                        ? <div className="progress-bar-container">
                            <ProgressBar isLabelVisible={true} className="progress-bar" bgColor="#5d5d5d" completed={Math.round((this.props.download_status.current_step*100)/this.props.download_status.total_steps)} />
                            <label></label>
                          </div>
                        : null
                    }
                </div>
                <div className="popup-edit-buttons">
                    <div className="right">
                        <Button3 onClick={this.cancel} label={Lang.BTN_CANCEL} />
                        { this.props.download_status && this.props.download_status.is_done ?
                            <Button onClick={this.accept} label={Lang.BTN_ACCEPT} /> : null
                        }
                    </div>
                </div>
            </Popup>
        );
    }
}