import React from "react";

import "./popup.scss";

class View extends React.Component {

    close = params => {
        this.props.onClose(this);
    }

    render() {

        const cls = this.props.className ? this.props.className : "popup";

        return (
            <div className={cls}>
                <div className="popup-modal">
                    {this.props.hideClose === true ? null :
                        <div onClick={this.close} className="popup-close-btn">X</div>
                    }
                    <div className="popup-title">
                        {this.props.title}
                    </div>
                    <div className="popup-cnt">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default View;
