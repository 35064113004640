import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Lang from "./ExportsLang";
import "./exports.scss";
import * as actions from "states/appState";

import Input from "_modules/input/Input";
import ButtonSave from "components/button-save/ButtonSave";

class Export extends Component {

    render() {
        return (
            <div className="export">
                <Input nameID="name" className="input name" value={this.props.name} {...this.props} />
                <Input type="number" nameID="width" className="input width" label2={Lang.UNIT} value={this.props.width} {...this.props}>
                    {this.props.title ?
                        <div className="subtitle">{Lang.SUB1_TITLE1_WIDTH}</div>
                        : null}
                </Input>
                <Input type="number" nameID="height" className="input height" label2={Lang.UNIT} value={this.props.height} {...this.props} >
                    {this.props.title ?
                        <div className="subtitle">{Lang.SUB1_TITLE1_HEIGHT}</div>
                        : null}
                </Input>
            </div>
        )
    }
}

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            exports: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.populateExports(nextProps);
    }

    componentWillMount() {
        this.populateExports(this.props);
    }

    populateExports = (props) => {
        if (props.appState.exports) {
            const items = props.appState.exports;

            if(items.length === 0) {
                items.push(this.addEmptyExport());
            } else {
                const lastItem = items[items.length - 1];
                if (lastItem.name !== "" && lastItem.width !== "" && lastItem.height !== "") {
                    items.push(this.addEmptyExport());
                }
            }

            this.setState({
                exports: items,
            })
        }
    }

    addEmptyExport = (params) => {
        return {
            name: "",
            width: "",
            height: "",
            id: -1,
        }
    }

    onExportChange = (params, ev) => {
        const items = this.state.exports;
        items[params.props.index][params.props.nameID] = ev.target.value;

        if (params.props.index === this.state.exports.length - 1) {
            if (ev.target.value !== "") {
                items.push(this.addEmptyExport());
            }
        }

        if (params.props.index === this.state.exports.length - 2) {
            if (ev.target.value === "") {
                for (let i = items.length - 2; i >= 0; i--) {
                    if (items[i].name === "" && items[i].width === "" && items[i].height === "") {
                        items.splice(i, 1);
                    } else {
                        break;
                    }
                }
            }
        }

        this.setState({
            exports: items,
        })
        this.props.appState.exportsDoc.exports = items;
        this.props.actions.populateExports(this.props.appState.exportsDoc);
        this.props.actions.toggleSave(true);
    }

    buildExports = (param, key) => {
        return (
            <Export index={key} onChange={this.onExportChange} key={key} title={key === 0 ? true : false} {...param} />
        )
    }

    save = (param)=>{
        this.props.actions.saveExports();
    }

    render() {
        return (
            <div className="exports">
                <div className="title">{Lang.TITLE}</div>
                <div className="cnt">
                    <div className="subtitle">{Lang.SUB1_TITLE1}</div>
                    <div className="subcnt">
                        {this.state.exports.map(this.buildExports)}
                    </div>
                </div>
                {this.props.appState.save ?
                    <div className="save">
                        <ButtonSave onClick={this.save} />
                    </div>
                    : null}
            </div>
        )
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
