import React, { Component } from 'react';
import "./test.scss";

import Check from "_modules/check/Check";
import Button2 from "_modules/button2/Button2"

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'showTT': false,
        }

        this.ht = null;
        this.ttClicked = false;
    }

    toggleTT = (params) => {
        let dl = 10;
        if (params) {
            dl = 0;
            clearTimeout(this.ht);
        } else {

        }

        this.ht = setTimeout(() => {
            this.setState({
                'showTT': params,
            });
            clearTimeout(this.ht);
        }, dl);
    }

    mouseOver = (params) => {
        if (this.props.onClickTT) {
            this.toggleTT(true);
        }
    }

    mouseOut = (params) => {
        this.toggleTT(false);
    }

    clickTT = (params) => {
        this.ttClicked = true;
        params.preventDefault();
        this.props.onClickTT(this);

        setTimeout(() => {
            this.ttClicked = false;
        }, 10);
    }

    render() {
        const tests = this.props.backend_for_damage_type.tests;
        const test = tests[this.props.id];
        if (!test){
            return null;
        }
        const details = test.details;
        const checked = details.active === 1 ? true : false;

        return (
            <div onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} className="test">
                <Check {...this.props} checked={checked} label={this.props.name} />
                {this.state.showTT ?
                    <div onClick={this.clickTT} className="tt">
                        <Button2 svg={require("assets/images/Symbol 25 – 210.svg")} />
                    </div>
                    : null}
                {this.props.active && (this.props.active.index === this.props.index) ?
                    <div className="arrow-right" />
                    : null}
            </div>
        );
    }
}