import React, { Component } from 'react';
import "./popup-edit.scss";
import * as Lang from "./PopupEditLang";

import Popup from "_modules/popup/Popup";
import Input from "_modules/input/Input";
import InputTop from "_modules/input-top/InputTop";
import Button from "_modules/button/Button";
import Button3 from "_modules/button3/Button3";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            value2: this.props.value2,
            error: false,
            error2: false,
        }

    }

    change = (params, ev) => {
        this.setState({
            'value': ev.target.value,
            'error': false,
        })
    }


    change2 = (params, ev) => {
        this.setState({
            'value2': ev.target.value,
            'error2': false,
        })
    }


    cancel = (param) => {
        this.props.onCancel(this);
    }

    accept = (param) => {
        if (this.state.value === undefined) {
            this.setState({
                'error': true,
            })

        } else {
            if (this.props.secInputTop) {
                if (this.state.value2 === undefined) {
                    this.setState({
                        'error2': true,
                    })
                } else {
                    this.props.onAccept(this);
                }
            } else
                this.props.onAccept(this);
        }
    }

    delete = (param) => {
        this.props.onDelete(this);
    }

    render() {
        const adClass = this.props.addClass ? this.props.addClass : "";

        return (
            <Popup {...this.props} title={this.props.title} className={"popup popup-edit" + (this.props.top ? " top" : "") + adClass} >
                <div className="pedit-top">
                    {this.props.top
                        ? <InputTop error={this.state.error} autoFocus={true} {...this.props} value={this.state.value} onChange={this.change} />
                        : <Input error={this.state.error} autoFocus={true} {...this.props} value={this.state.value} onChange={this.change} />
                    }
                    {this.props.secInputTop
                        ? <div className="pe-it2">
                            <InputTop error={this.state.error2} {...this.props} top={this.props.secInputTop} value={this.state.value2} onChange={this.change2} />
                        </div>
                        : null
                    }
                </div>
                {this.props.contentBottom}
                <div className="popup-edit-buttons">
                    {this.props.showLeft ?
                        <div className="left">
                            <Button3 onClick={this.delete} label={Lang.BTN_DELETE} />
                        </div>
                        : null}
                    <div className="right">
                        <Button3 onClick={this.cancel} label={Lang.BTN_CANCEL} />
                        <Button onClick={this.accept} label={Lang.BTN_ACCEPT} />
                    </div>

                </div>
            </Popup>
        );
    }
}