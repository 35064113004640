import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "states/appState";
import "./tab4.scss";
import ReactSVG from 'react-svg';
import * as LocalApi from "_services/LocalApi";
import * as Api from "_services/Api";

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fullImageUrl: null
        }
    }

    componentWillMount() {
        this.getFileURL();
    }

    getFileURL = () => {
        if(this.props.probeImage && this.props.probeImage.file_id !== 0) {

            LocalApi.getFile(this.props.probeImage.file_id).then(
                (serverURL) => {

                    if(serverURL !== null) {
                        this.setState({
                            fullImageUrl: Api.getImageFullUrl(serverURL)
                        });
                    }
                }
            );
        }
    }
    
    render = () => {
        let mainImage = null;
        let arrowImage = null;

        if(this.state.fullImageUrl) {
            const arr = this.props.probeImage.arrow;
            const st = {
                'top': arr.y + "px",
                'left': arr.x + "px",
                'transform': "rotate(" + arr.rotation + "deg)",
                width: '50px',
                height: '50px'
            }
            
            mainImage = <img className="img-photo" alt="" src={this.state.fullImageUrl} />;

            if(arr.show) {
                arrowImage = <ReactSVG style={st} alt="" className="img-arr" src={require("assets/images/icon_arrow_pointer.svg")} />;
            }
        } else {
            mainImage = <img alt="" className="no-cam" src={require("assets/images/no_cam.png")} />;
        }

        return (
            <div className="img-proben-img" key={this.props.key} >
                {mainImage}
                {arrowImage}
            </div>
        )
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
