import React, { Component } from 'react';
import "./white-panel.scss";

export default class View extends Component {

    render() {
        return (
            <div className="white-panel">
                {this.props.title ? <div className="title">{this.props.title}</div> : null}
                <div className="content">{this.props.children}</div>
            </div>
        )
    }
}