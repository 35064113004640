import React, { Component } from 'react';
import "./test2.scss";

import Input from "_modules/input/Input";
import Plus from "components/plus/Plus";
import * as Lang from "./Test2Lang";
import Check from "_modules/check/Check";

import Select from "react-dropdown-select";

class Item extends Component {

    constructor(props) {
        super(props);

        // Provisional propertys
        this.showOldStyle = true;
        this.showUnits = false;

        // this.options = [{ color: 0, value: 0, }, { color: 1, value: 1, }, { color: 2, value: 2, }];
        this.options = [
            { color: 0, value: 0, }, 
            { color: 1, value: 1, }, 
            { color: 2, value: 2, }, 
            { color: 3, value: 3, }, 
            { color: 4, value: 4, }, 
            { color: 5, value: 5, }, 
            { color: 6, value: 6, }, 
            { color: 7, value: 7, }, 
            { color: 8, value: 8, }, 
            { color: 9, value: 9, }, 
            { color: 10, value: 10, }, 
            { color: 11, value: 11, }];

        // this.minColChange = 0;
        this.colChange = 0;
        // this.maxColChange = 0;

        // New values to track each color changes
        this.minColChange = 0;
        this.colChange1 = 0;
        this.colChange2 = 0;
        this.colChange3 = 0;
        this.colChange4 = 0;
        this.colChange5 = 0;
        this.colChange6 = 0;
        this.colChange7 = 0;
        this.colChange8 = 0;
        this.colChange9 = 0;
        this.colChange10 = 0;
        this.maxColChange = 0;

    }

    customContentRenderer = ({ props, state }) => {
        if (state.values.length === 0){
            return null;
        }
        return (
            <div className={"color small color" + state.values[0].color} />

        )
    }

    colorsRender = ({ item, itemIndex, props, state, methods }) => {
        return (
            <div className={"color color" + item.color} onClick={() => methods.addItem(item)} />
        )
    }

    minQuChanged = (prop, ev) => {
        this.props.onChange(this, "minQnt", ev);
    }

    maxQuChanged = (prop, ev) => {
        this.props.onChange(this, "maxQnt", ev);
    }

    minUnitChanged = (prop, ev) => {
        this.props.onChange(this, "minUnit", ev);
    }

    maxUnitChanged = (prop, ev) => {
        this.props.onChange(this, "maxUnit", ev);
    }

    nameChanged = (prop, ev) => {
        this.props.onChange(this, "name", ev);
    }

    // New functions for 10 input values
    quantityChanged = (quantityIdentifier, ev) => {
        this.props.onChange(this, quantityIdentifier, ev);
    }

    unitChanged = (unitIdentifier, ev) => {
        this.props.onChange(this, unitIdentifier, ev);
    }

    minColChanged = prop => {
        this.props.onChange(this, "minCol", { target: { value: prop[0].value }, change: this.minColChange });
        this.minColChange++;
    }

    colChanged1 = prop => {
        this.props.onChange(this, "col1", { target: { value: prop[0].value }, change: this.colChange1 });
        this.colChange1++;
    }

    minShowChanged = (prop, ev) => {
        this.props.onChange(this, "minShow", ev);
    }

    showChanged1 = (prop, ev) => {
        this.props.onChange(this, "show1", ev);
    }

    showChanged2 = (prop, ev) => {
        this.props.onChange(this, "show2", ev);
    }

    showChanged3 = (prop, ev) => {
        this.props.onChange(this, "show3", ev);
    }

    showChanged4 = (prop, ev) => {
        this.props.onChange(this, "show4", ev);
    }

    showChanged5 = (prop, ev) => {
        this.props.onChange(this, "show5", ev);
    }

    showChanged6 = (prop, ev) => {
        this.props.onChange(this, "show6", ev);
    }

    showChanged7 = (prop, ev) => {
        this.props.onChange(this, "show7", ev);
    }

    showChanged8 = (prop, ev) => {
        this.props.onChange(this, "show8", ev);
    }

    maxShowChanged = (prop, ev) => {
        this.props.onChange(this, "maxShow", ev);
    }

    colChanged2 = prop => {
        this.props.onChange(this, "col2", { target: { value: prop[0].value }, change: this.colChange2 });
        this.colChange2++;
    }

    colChanged3 = prop => {
        this.props.onChange(this, "col3", { target: { value: prop[0].value }, change: this.colChange3 });
        this.colChange3++;
    }

    colChanged4 = prop => {
        this.props.onChange(this, "col4", { target: { value: prop[0].value }, change: this.colChange4 });
        this.colChange4++;
    }

    colChanged5 = prop => {
        this.props.onChange(this, "col5", { target: { value: prop[0].value }, change: this.colChange5 });
        this.colChange5++;
    }

    colChanged6 = prop => {
        this.props.onChange(this, "col6", { target: { value: prop[0].value }, change: this.colChange6 });
        this.colChange6++;
    }

    colChanged7 = prop => {
        this.props.onChange(this, "col7", { target: { value: prop[0].value }, change: this.colChange7 });
        this.colChange7++;
    }

    colChanged8 = prop => {
        this.props.onChange(this, "col8", { target: { value: prop[0].value }, change: this.colChange8 });
        this.colChange8++;
    }

    colChanged9 = prop => {
        this.props.onChange(this, "col9", { target: { value: prop[0].value }, change: this.colChange9 });
        this.colChange9++;
    }

    normColChanged = prop => {
        this.props.onChange(this, "col", { target: { value: prop[0].value }, change: this.colChange });
        this.colChange++;
    }

    maxColChanged = prop => {
        this.props.onChange(this, "maxCol", { target: { value: prop[0].value }, change: this.maxColChange });
        this.maxColChange++;
    }

    render() {
        return (
            <div className="test2-item">
                { this.props.showOnly === undefined ? 
                    <div className="delete-button-container">
                        <img className="button-img" alt="" onClick={() => {this.props.showConfirmDeletePopup(this.props.id)}} src={require("assets/images/symbols/icon_delete.svg")} />
                    </div> : null 
                }
                <div className="test2-unit-line">
                    <div className="test2-unit-line-cnt">
                        <label className="unit-label">Einheit:</label>
                        <Input className="input unit unit1" {...this.props} onChange={this.minUnitChanged} value={this.props.minUnit} />
                    </div>
                </div>
                <div className="test2-select-min-color-container">
                    <Select
                        itemRenderer={this.colorsRender}
                        contentRenderer={this.customContentRenderer}
                        className="select colors"
                        options={this.options}
                        values={[this.options[this.props.minCol]]}
                        onChange={this.minColChanged}

                    />
                </div>
                { this.props.showOnly === undefined || (this.props.showOnly && this.props.minShow) ? 
                <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={this.minQuChanged} type="text" value={this.props.minQnt} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.minShow} onChange={this.minShowChanged} label="" /> : null }
                        <div className="test2-select-container">
                            <Select
                                itemRenderer={this.colorsRender}
                                contentRenderer={this.customContentRenderer}
                                className="select colors"
                                options={this.options}
                                values={[this.options[this.props.col1]]}
                                onChange={this.colChanged1}
                            />
                        </div>
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={this.minUnitChanged} value={this.props.minUnit} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show1)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt1", e)} type="text" value={this.props.qnt1} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show1} onChange={this.showChanged1} label="" /> : null }
                            <Select
                                itemRenderer={this.colorsRender}
                                contentRenderer={this.customContentRenderer}
                                className="select colors"
                                options={this.options}
                                values={[this.options[this.props.col2]]}
                                onChange={this.colChanged2}
                            />
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit1", e)} value={this.props.unit1} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show2)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt2", e)} type="text" value={this.props.qnt2} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show2} onChange={this.showChanged2} label="" /> : null }
                        <div className="test2-select-container">
                            <Select
                                itemRenderer={this.colorsRender}
                                contentRenderer={this.customContentRenderer}
                                className="select colors"
                                options={this.options}
                                values={[this.options[this.props.col3]]}
                                onChange={this.colChanged3}
                            />
                        </div>
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit2", e)} value={this.props.unit2} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show3)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt3", e)} type="text" value={this.props.qnt3} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show3} onChange={this.showChanged3} label="" /> : null }
                        <Select
                            itemRenderer={this.colorsRender}
                            contentRenderer={this.customContentRenderer}
                            className="select colors"
                            options={this.options}
                            values={[this.options[this.props.col4]]}
                            onChange={this.colChanged4}
                        />
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit3", e)} value={this.props.unit3} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show4)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt4", e)} type="text" value={this.props.qnt4} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show4} onChange={this.showChanged4} label="" /> : null }
                        <div className="test2-select-container">
                            <Select
                                itemRenderer={this.colorsRender}
                                contentRenderer={this.customContentRenderer}
                                className="select colors"
                                options={this.options}
                                values={[this.options[this.props.col5]]}
                                onChange={this.colChanged5}
                            />
                        </div>
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit4", e)} value={this.props.unit4} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show5)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt5", e)} type="text" value={this.props.qnt5} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show5} onChange={this.showChanged5} label="" /> : null }
                        <Select
                            itemRenderer={this.colorsRender}
                            contentRenderer={this.customContentRenderer}
                            className="select colors"
                            options={this.options}
                            values={[this.options[this.props.col6]]}
                            onChange={this.colChanged6}
                        />
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit5", e)} value={this.props.unit5} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show6)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt6", e)} type="text" value={this.props.qnt6} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show6} onChange={this.showChanged6} label="" /> : null }
                        <div className="test2-select-container">
                            <Select
                                itemRenderer={this.colorsRender}
                                contentRenderer={this.customContentRenderer}
                                className="select colors"
                                options={this.options}
                                values={[this.options[this.props.col7]]}
                                onChange={this.colChanged7}
                            />
                        </div>
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit6", e)} value={this.props.unit6} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show7)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt7", e)} type="text" value={this.props.qnt7} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show7} onChange={this.showChanged7} label="" /> : null }
                        <Select
                            itemRenderer={this.colorsRender}
                            contentRenderer={this.customContentRenderer}
                            className="select colors"
                            options={this.options}
                            values={[this.options[this.props.col8]]}
                            onChange={this.colChanged8}
                        />
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit7", e)} value={this.props.unit7} /> : null }
                </div> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly && this.props.show8)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={(param, e) => this.quantityChanged("qnt8", e)} type="text" value={this.props.qnt8} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.show8} onChange={this.showChanged8} label="" /> : null }
                        <div className="test2-select-container">
                            <Select
                                itemRenderer={this.colorsRender}
                                contentRenderer={this.customContentRenderer}
                                className="select colors"
                                options={this.options}
                                values={[this.options[this.props.col]]}
                                onChange={this.normColChanged}
                            />
                        </div>
                        { false ? <div className="amp1 amp">&lt;</div> : null }
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={(param, e) => this.unitChanged("unit8", e)} value={this.props.unit8} /> : null }
                </div> : null }
                { false ? <Select
                    itemRenderer={this.colorsRender}
                    contentRenderer={this.customContentRenderer}
                    className="select colors"
                    options={this.options}
                    values={[this.options[this.props.col]]}
                    onChange={this.normColChanged}

                /> : null }
                { this.showOldStyle && (this.props.showOnly === undefined || (this.props.showOnly !== undefined && this.props.maxShow)) ? <div className="test2-rub1">
                    <div className="test2-rub-cnt">
                        <Input className="input quantity q-min" {...this.props} onChange={this.maxQuChanged} type="text" value={this.props.maxQnt} />
                        { this.props.showOnly === undefined ? <Check checked={this.props.maxShow} onChange={this.maxShowChanged} label="" /> : null }
                        { false ? <div className="amp amp2">&gt;</div> : null }
                        <Select
                            itemRenderer={this.colorsRender}
                            contentRenderer={this.customContentRenderer}
                            className="select colors"
                            options={this.options}
                            values={[this.options[this.props.maxCol]]}
                            onChange={this.maxColChanged}
                        />
                    </div>
                    { this.showUnits ? <Input className="input unit unit1" {...this.props} onChange={this.maxUnitChanged} value={this.props.maxUnit} /> : null }
                </div> : null }

                { this.props.showOnly === undefined ? <Input className="input industry" {...this.props} onChange={this.nameChanged} value={this.props.name} /> : null }

            </div>
        )
    }
}

export default class View extends Component {

    buildItems = (params, key) => {
        if (this.props.showOnly !== undefined) {
            if (this.props.showOnly !== params.id) {
                return null;
            }
        }

        return (
            <Item index={params.id} key={params.id} {...params} onChange={this.onChange} showOnly={this.props.showOnly} showConfirmDeletePopup={this.props.showConfirmDeletePopup}/>
        )
    }

    addItem = (params) => {
        this.props.addItem();
    }

    onChange = (param, type, ev) => {
        this.props.onChange(this, param, ev, type);
    }

    render() {

        return (
            <div className="test2">
                <div className="header-titles">
                    {this.props.showOnly === undefined ? <div className="title title1">{Lang.TITLE1}</div> : null}
                    {this.props.showOnly === undefined ? <div className="title title2">{Lang.TITLE2}</div> : null}
                </div>
                {this.props.details.values.map(this.buildItems)}
                {this.props.showOnly === undefined ? <Plus onClick={this.addItem} label={Lang.PLUS} /> : null}
            </div>
        );
    }
}