import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "states/appState";
import "./tab2.scss";
import * as Lang from "./Tab2Lang";
import Select from "_modules/select/Select";
import TextArea from "_modules/text-area/TextArea";
import * as probe from "_utils/probe";
import ImgTab2 from './ImgTab2';

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'ref': new Date().getTime(),
        }

        this.firstTime = true;

        setTimeout(() => {
            this.firstTime = false;
        }, 10);

    }

    componentWillMount() {
        this.props.actions.toggleRightBgGray(true);
    }

    componentWillUnmount() {
        this.props.actions.toggleRightBgGray(false);
    }

    componentWillReceiveProps(nextProps) {

    }

    aChanged = (param, param2) => {
        if(param2.length === 0 || !param2[0]) {
            return;
        }

        const probe = this.props.appState.probes[param.props.index];
        if(param2[0].value !== probe["material_option_id_" + param.props.name]) {
            if (param.clicked)
                this.props.actions.toggleSave(true);
        }

        probe["material_option_id_" + param.props.name] = param2[0].value;
        probe.is_changed = true;

        if (param.props.name === "1") {
            probe["material_option_id_2"] = -1;
        }

        this.setState({
            'ref': new Date().getTime(),
        })
    }

    bChanged = (param, param2) => {
        if(typeof(param2[0]) === 'undefined') return;

        const probe = this.props.appState.probes[param.props.index];

        if (probe.result[param.props.offfset]["app_result" + (param.props.name) + "_id"] !== param2[0].value) {
            if (!this.firstTime) {
                if (param.clicked)
                    this.props.actions.toggleSave(true);
            }
        }

        probe.result[param.props.offfset]["app_result" + (param.props.name) + "_id"] = param2[0].value;
        probe.is_changed = true;

        if (param.props.name === "1") {
            // probe.result[param.props.offfset]["app_result2_id"] = -1;
        }

        if (probe.result.length === param.props.offfset + 1) {
            if (probe.result[param.props.offfset].app_result1_id >= 0 && probe.result[param.props.offfset].app_result2_id >= 0) {
                probe.result.push({
                    'app_result1_id': -1,
                    'app_result2_id': -1,
                })
            }
        }

        if (probe.result.length === param.props.offfset + 2) {
            if (probe.result[param.props.offfset].app_result1_id < 0 && probe.result[param.props.offfset].app_result2_id < 0) {
                for (let i = probe.result.length - 1; i >= 0; i--) {
                    if (probe.result[i].app_result1_id < 0 && probe.result[i].app_result2_id < 0) {
                        probe.result.splice(-1, 1);
                    } else {
                        break;
                    }
                }
                probe.result.push({
                    'app_result1_id': -1,
                    'app_result2_id': -1,
                })
            }
        }


        this.setState({
            'ref': new Date().getTime(),
        })
    }

    textChanged = (param) => {
        const probe = this.props.appState.probes[param.props.index];
        probe.text = param.state.value;
        probe.is_changed = true;

        this.props.actions.toggleSave(true);
    }


    buildBefund(param, key, parentProps) {

        const inst = this.parent;
        const parentKey = this.parentKey;
        const probe = this.probe;
        const dp = inst.props.appState.damage_data.app_settings;
        const result = probe.result[key];

        const b1 = [
            {
                'label': Lang.SELECT,
                'value': -1,
            }
        ]
        const b2 = [
            {
                'label': Lang.SELECT,
                'value': -1,
            }
        ]

        const s1 = result.app_result1_id + 1;
        const s2 = result.app_result2_id + 1;

        for (let i = 0; i < dp.app_results_1.length; i++) {
            const item = dp.app_results_1[i];
            if (item.name !== undefined) {
                b1.push({
                    'label': item.name,
                    'value': i,
                })
            }
        }

        for (let i = 0; i < dp.app_results_2.length; i++) {
            const item = dp.app_results_2[i];
            if (item.name !== undefined) {
                b2.push({
                    'label': item.name,
                    'value': i,
                })
            }
        }

        if((s1 > b1.length - 1) || (s2 > b2.length - 1)) return null;

        return (
            <div key={key} className="row" >
                <div className="name">{Lang.BEFUND}</div>
                <div className="selectors">
                    <div className="selector">
                        <Select index={parentKey} offfset={key} manualValues={true} name="1" onSelect={inst.bChanged} options={b1} values={[b1[s1]]} />
                    </div>
                    <div className="selector">
                        <Select index={parentKey} offfset={key} manualValues={true} name="2" onSelect={inst.bChanged} options={b2} values={[b2[s2]]} />
                    </div>
                </div>
            </div>
        )
    }

    probeTypeChanged = (selectComponent, selectedValue) => {
        // get the probe that was changed
        const probe = this.props.appState.probes[selectComponent.props.index];
        
        if(selectedValue[0] && selectedValue[0].value && probe.sample_type !== selectedValue[0].value) {
            console.log(probe.sample_type + " = " + selectedValue[0].value);

            probe.sample_type = selectedValue[0].value;
            probe.is_changed = true;

            this.setState({
                'ref': new Date().getTime(),
            });

            this.props.actions.toggleSave(true);
        }
    }

    sChanged = (param, param2, param3) => {
        if(param2.length === 0 || !param2[0]) {
            return;
        }

        const probe = this.props.appState.probes[param.props.index];
        const nm = param.props.name;

        if (probe["sample_location_" + nm] !== param2[0].value) {
            if (param3 > 2) {
                if (param.clicked)
                    this.props.actions.toggleSave(true);
            }
        }

        probe["sample_location_" + nm] = param2[0].value;
        probe.is_changed = true;

        if (nm === "2") {
            probe["sample_location_3"] = "";
        }

        this.setState({
            'ref': new Date().getTime(),
        });
    }

    // currently not used - check ImgTab2
    changePhoto = (param) => {
        this.props.actions.togglePhotoProbe(param);
        this.props.actions.toggleSave(true);
    }

    // currently not used - check ImgTab2
    deletePhoto = (param) => {
        const photo = param.props.photo;
        const photoIndex = param.props.photoIndex;
        this.props.actions.deletePhotoProbe(photo, photoIndex);
        this.props.actions.toggleSave(true);
    }

    // param is an probe image, check the app, in appState function togglePhotoProbe
    buildImage = (param, key) => {
        return (
            <ImgTab2 probeImage={param} key={key} changePhoto={this.changePhoto} />
        );
    }

    // param is a sample
    buildProbesContent = (param, key) => {
        if (param.sourceType === 2) {
            return;
        }

        if (this.props.probe) {
            if (param.id !== this.props.probe.id) {
                return;
            }
        } else {
            if (param.sample_layer_id !== this.props.appState.currentSampleLayer) {
                return;
            }
        }

        const dp = this.props.appState.damage_data.app_settings;
        const nm = probe.getProbeName(this.props.appState, param, false);
        const tx = param.text;
        const material_options = dp.material_options;

        const a1 = [
            {
                'label': Lang.SELECT,
                'value': -1,
            }
        ]
        const a2 = [
            {
                'label': Lang.SELECT,
                'value': -1,
            }
        ]

        
        for (let i = 0; i < material_options.length; i++) {
            if(material_options[i]) {
                const item = material_options[i];
                a1.push({
                    'label': item.name,
                    'value': i,
                })
            }
        }

        const materialOption1 = a1.find(el => el.value == param.material_option_id_1);
        const selectedMaterialOption1 = materialOption1 ? materialOption1 : a1[0];
        
        const s1 = param.material_option_id_1;

        if (s1 >= 0 && material_options[s1]) {
            for (let i = 0; i < material_options[s1].items.length; i++) {
                if(material_options[s1].items[i]) {
                    const item = material_options[s1].items[i];

                    a2.push({
                        'label': item.name,
                        'value': i,
                    });
                }
            }
        }

        const materialOption2 = a2.find(el => el.value == param.material_option_id_2);
        const selectedMaterialOption2 = materialOption2 ? materialOption2 : a2[0];

        const showType4 = (param.sourceType === 0 && param.numbering_type === 2);

        const sample_types_values = [
            {
                'label': Lang.SELECT,
                'value': -1,
            }
        ];
        let sample_type_for_probe = false;

        const sl2 = [
            {
                'label': Lang.SELECT,
                'value': "",
            }
        ];
        const sl3 = [
            {
                'label': Lang.SELECT,
                'value': "",
            }
        ];

        const showMaterial = (param.sourceType === 0) ? true : false;
        const showPics = (param.sourceType === 0) ? true : false;
        
        let selectedSampleLocation2 = null;
        let selectedSampleLocation3 = null;

        if (showType4) {
            const sample_location = dp.sample_location;
            const sample_types = dp.sample_type;

            const sSel1 = probe.getSampleTypeLocation(this.props.appState, param);
            
            for (let i = 0; i < sample_types.length; i++) {
                const item = sample_types[i];

                if(item.name && item.id) {
                    sample_types_values.push({
                        'label': item.name,
                        'value': item.id
                    });
                }
            }
            sample_type_for_probe = sample_types_values[sSel1.sample_type_index];
            if(!sample_type_for_probe) {
                sample_type_for_probe = sample_types_values[0];
            }

            if(sample_location[sSel1.sample_location_index]) {
                const it2 = sample_location[sSel1.sample_location_index].items;
                for (let i = 0; i < it2.length; i++) {
                    if(it2[i]) {
                        const item = it2[i];
                        sl2.push({
                            'label': item.name,
                            'value': item.id,
                        })
                    }
                }

                const ssl2 = probe.getSampleTypeLocation2(this.props.appState, param, sSel1.sample_location_index);
                if (ssl2 >= 0 && it2[ssl2]) {
                    const it3 = it2[ssl2].items;

                    for (let i = 0; i < it3.length; i++) {
                        if(it3[i]) {
                            const item = it3[i];
                            sl3.push({
                                'label': item.name,
                                'value': item.id,
                            });
                        }
                    }
                }
            }

            selectedSampleLocation2 = sl2.find(el => el.value == param.sample_location_2);
            if(!selectedSampleLocation2) {
                selectedSampleLocation2 = sl2[0];
            }

            selectedSampleLocation3 = sl3.find(el => el.value == param.sample_location_3);
            if(!selectedSampleLocation3) {
                selectedSampleLocation3 = sl3[0];
            }
        }

        return (
            <div key={key} className="probe-cnt">
                <div className="name">{nm}</div>
                <div className="right">
                    {showType4 ?
                        <div className="selects">
                            <div className="selector">
                                <Select name="t" index={key} onSelect={this.probeTypeChanged} manualValues={true} options={sample_types_values} values={[sample_type_for_probe]} />
                            </div>
                            <div className="selector">
                                <Select name="2" index={key} onSelect={this.sChanged} manualValues={true} options={sl2} values={[selectedSampleLocation2]} />
                            </div>
                            <div className="selector">
                                <Select name="3" index={key} onSelect={this.sChanged} manualValues={true} options={sl3} values={[selectedSampleLocation3]} />
                            </div>
                        </div>
                        : null}
                    <TextArea placeholder={Lang.FREE_TEXT} index={key} onChange={this.textChanged} value={tx} />
                    {showMaterial ?
                        <div className="material">
                            <div className="row">
                                <div className="name">{Lang.MATERIAL}</div>
                                <div className="selectors">
                                    <div className="selector">
                                        <Select index={key} name="1" manualValues={true} onSelect={this.aChanged} options={a1} values={[selectedMaterialOption1]} />
                                    </div>
                                    <div className="selector">
                                        <Select index={key} name="2" manualValues={true} onSelect={this.aChanged} options={a2} values={[selectedMaterialOption2]} />
                                    </div>
                                </div>
                            </div>
                            {param.result.map(this.buildBefund, { parent: this, parentKey: key, probe: param })}
                        </div>
                        : null}
                    {showPics ?
                        <div className="pics">
                            {param.images ? param.images.map((img, key) => { return this.buildImage(img, key, param) }) : null}
                        </div>
                        : null}

                </div>
            </div>
        )
    }



    render() {

        if (this.props.appState.probes.length === 0) {
            return (null);
        }

        let title = "";

        const probes = this.props.appState.probes;

        return (
            <div className="tab2">
                <div className="title">
                    {title}
                </div>
                <div className="probes-cnt">
                    {probes.map(this.buildProbesContent)}
                </div>

            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
