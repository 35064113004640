import axios from 'axios';
import * as loginActions from "_modules/login/loginState";

const BASE_URL = (process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_PROD_BASE_URL);
export const DOC_GEN_URL = process.env.REACT_APP_DOC_GEN_URL;

const downloadKey = '50f9a014-40e6-43e5-85dc-b58d24195555';
const DOWNLOAD_API_URI = BASE_URL + "/api/images/download";
const UPLOAD_SCREENSHOT_BASE64 =  "/api/images/upload_base64";

export const getUid = () => {
    const min = 1111;
    const max = 9999;

    return Date.now() + "_" + Math.floor(Math.random() * (max - min + 1));
}

export const get =(url, data, config, onResult, onError) => {
    const fUrl = BASE_URL + url;
    var dataToSend = {
        params:data,
        ...config
    }
    //console.log(fUrl);
    //console.log(data);
    //console.log(config);
    //console.log(dataToSend);
    axios.get(fUrl, dataToSend)
        .then(result => {
            onResult(result);
            //console.log(result);
        })
        .catch(error => {
            //console.log(error.response);
            onError(error);
        });
}

export const getDownloadStatus = (downloadId) => {
    const fUrl = DOC_GEN_URL + "/getDownloadStatus/" + downloadId;
    return axios.get(fUrl)
}

export const downloadFile =(url, downloadId, reportType) => {
    // Report Type used for the second word export("/2")
    const fUrl = url+"/"+downloadId+reportType;
    
    axios({
        url: fUrl,
        method: 'GET',
        timeout: 60 * 3 * 1000, // 3 min
        responseType: 'blob', // important
    }).then((response) => {
        console.log("Successfully created report: ", response);
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'file.pdf');
        // document.body.appendChild(link);
        // link.click();

        // const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        // const link = document.createElement('a');
        // link.href = url;
        // link.target = '_blank'
        // // link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1])
        // link.click();
        // window.URL.revokeObjectURL(url);
    });
}

export const post = (url, data, config, onResult, onError) => {
    const fUrl = BASE_URL + url;
     //console.log(fUrl);
     //console.log(data);
     //console.log(config);
    return axios.post(fUrl, data, config)
        .then(result => {
            onResult(result);
            //console.log(result);
        })
        .catch(error => {
            //console.log(error.response);
            if(onError) {
                onError(error);
            }
        });
}

export const deleteData =(url, data, config, onResult, onError) => {
    const fUrl = BASE_URL + url;
     //console.log(fUrl);
     //console.log(data);
    // console.log(config);
    axios.delete(fUrl, data, config)
        .then(result => {
            onResult(result);
            //console.log(result);
        })
        .catch(error => {
            //console.log(error.response);
            onError(error);
        });
}

export const uploadScreenShot = ( screenshotId, base64ScreenShot, base64Legend, w, h, output) => {

    return post(UPLOAD_SCREENSHOT_BASE64, 
        {
            screenshotId,
            screenshot: base64ScreenShot,
            legend: base64Legend,
            w, h,
            output
        },
        { 'headers': loginActions.getHeaders() });
}

export const getImageFullUrl = (serverURL) => {
    return DOWNLOAD_API_URI + "/" + serverURL + "/" + downloadKey;
}