import PouchDB from 'pouchdb';

// in seconds
const SYNC_INTERVAL = 10;

const serverDBUrl = process.env.REACT_APP_DB_URL;
const remoteDBName = process.env.REACT_APP_REMOTE_DB_NAME;
const remoteDBUsername = process.env.REACT_APP_REMOTE_DB_USERNAME;
const remoteDBPassword = process.env.REACT_APP_REMOTE_DB_PASSWORD;
const localDBName = process.env.REACT_APP_LOCAL_DB_NAME;

var dbURL = serverDBUrl + "/" + remoteDBName;

var remoteDB = new PouchDB(dbURL, {auth: {username: remoteDBUsername, password: remoteDBPassword}});
var db = new PouchDB(localDBName);

// if you want to access it from console, uncomment these 2 lines
// window.remote_debug_db = remoteDB;
// window.debug_db = db;

var inSync = false;

export const sync = (callback) => {

    if(inSync) {
        console.log('already in sync');
    }

    console.log('sync start');

    inSync = true;
    db.sync(remoteDB).on('complete', async () => {
        console.log('sync done');

        if(callback) {
            callback();
        }

        inSync = false;

    }).on('error', err => {
        console.log('sync error');
        console.log(err);

        if(callback) {
            callback(err);
        }

        inSync = false;
    });
}

export const startSync = async (callback) => {
    sync(callback);

    // now it seems that the app in BG, does not trigger the intervall callback
    setInterval(
        sync,
        SYNC_INTERVAL * 1000
    );
}

export const getP = (id) => {
    return new Promise(function(resolve, reject) {
        db.get(id)
            .then(doc => {
                resolve(doc);
            })
            .catch(err => {
                console.log(err);
            });
        }
    );
}

export const get = (id, callback, errCallback) => {
    //console.log("get data from id, " + id);
    db.get(id)
        .then(doc => {
            // console.log(doc);
            callback(doc);
        })
        .catch(err => {
            if (errCallback) {
                errCallback(err);
            }
        });
}

export const put = (id, data, callback) => {
    //console.log("put data to id, " + id);
    const dataToSend = {
        ...data,
        _id: id,
    }

    //console.log(dataToSend);
    return db.get(id)
        .catch(function (err) {
            if (err.name === 'not_found') {
                return dataToSend;
            } else {
                console.log("error updating document " + id);
                console.log(err);
            }
        })
        .then(function(doc) {
            if(doc._rev) {
                dataToSend._rev = doc._rev;
            }

            Object.assign(doc, dataToSend);

            return db.put(doc);
        })
        .then(function(resultDoc /* this is a doc from pouch countaining some metadata that the actuall doc has been saved */) {
            
            return db.get(id).then((doc) => {
                if (callback) {
                    callback(doc);
                }
            });
        })
        .catch(function (err) {
            console.log("error updating document");
            console.log(err);
        });
}

export const remove = (id) => {
    //console.log("delete id, " + id);

    // to do: return promise, to allow callback after deletion
    db.get(id).then(function(doc) {
        return db.remove(doc);
    }).then(function (result) {
        console.log("document deleted");
    }).catch(function (err) {
        console.log("error deleting document");
        console.log(err);
    });
}

export const getAllWithPrefix = (prefix) => {
    return new Promise(function(resolve, reject) {
        _getAll({
            include_docs: true,
            startkey: prefix,
            endkey:  prefix + "\uffff" // this causes all the docs starting with that prefix to be returned
        }, false, (docs) => {

            const elems = [];

            for(let i = 0; i < docs.rows.length; i++) {
                const doc = docs.rows[i].doc;
            
                elems.push(doc);
            };

            resolve(elems);
        });
    });
}

const _getAll = (src, debug = true, callback = null) => {
    if(debug) {
        console.log("pouch db get all params");
        console.log(src);
    }

    db.allDocs(src).then(function (res) {
        if(debug) {
            console.log("pouch db get all -> results");
            console.log(res);
        }

        if(callback) {
            callback(res);
        }
    }).catch(function (err) {
        console.log("pouch db get all -> error");
        console.log(err);
    });
}
