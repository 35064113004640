import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./tab4.scss";
import * as Lang from "./Tab4Lang";
import * as actions from "states/appState";

import { DOC_GEN_URL } from "_services/Api";

import Button from "_modules/button/Button";
import WhitePanel from "components/white-panel/WhitePanel";
import Check from "_modules/check/Check";
import Tree3 from "components/tree3/Tree3";
import PopupProbe from "components/popup-probe/PopupProbe";
import PopupDownload from "components/popup-download/PopupDownload";
import * as probe from "_utils/probe";
import ImgTab4 from './ImgTab4';

class ImgsProben extends Component {

    click = (param) => {
        this.props.onClick(param);
    }

    getFloor = (param) => {
        const floors = this.props.appState.company_data.items;
        for (let i = 0; i < floors.length; i++) {
            const visits = floors[i].items;
            for (let j = 0; j < visits.length; j++) {
                const layers = visits[j].items;
                for (let k = 0; k < layers.length; k++) {
                    const layer = layers[k];
                    if (layer.id === param.sample_layer_id) {
                        return visits[j];
                    }
                }
            }
        }

        return null;
    }

    getContent = (param) => {


        let ret = "";

        const dp = this.props.appState.damage_data.app_settings;
        const material_options = dp.material_options;
        const ar1 = dp.app_results_1;
        const ar2 = dp.app_results_2;
        const sample_location = dp.sample_location;
        //const sample_type = dp.sample_type;

        if (param.sample_location_1 !== "") {
            let slRet = null;
            let slRet2 = null;

            for (let i = 0; i < sample_location.length; i++) {
                if(sample_location[i]) {
                    const sl = sample_location[i];
                    if (sl.id === param.sample_location_1) {
                        slRet = sl;
                        ret = ret + (ret.length === 0 ? "" : ", ") + sl.name;
                        break;
                    }
                }
            }

            if (param.sample_location_2 !== "" && slRet) {
                for (let i = 0; i < slRet.items.length; i++) {
                    if(slRet.items[i]) {
                        const sl2 = slRet.items[i];
                        if (sl2.id === param.sample_location_2) {
                            slRet2 = sl2;
                            ret = ret + (ret.length === 0 ? "" : ", ") + sl2.name;
                            break;
                        }
                    }
                }
            }

            if (param.sample_location_3 !== "" && slRet2) {
                for (let i = 0; i < slRet2.items.length; i++) {
                    if(slRet2.items[i]) {
                        const sl3 = slRet2.items[i];
                        if (sl3.id === param.sample_location_3) {
                            ret = ret + (ret.length === 0 ? "" : ", ") + sl3.name;
                            break;
                        }
                    }
                }
            }
        }

        if (param.text !== "") {
            ret = ret + (ret.length === 0 ? "" : ", ") + param.text;
        }

        if (param.material_option_id_1 > -1 && param.material_option_id_1 < material_options.length - 1) {
            let moRet = null;

            if(material_options[param.material_option_id_1]) {
                moRet = material_options[param.material_option_id_1];

                ret = ret + (ret.length === 0 ? "" : ", ") + moRet.name;
            }

            if (param.material_option_id_2 > -1 && param.material_option_id_2 < moRet.items.length - 1 && moRet) {
                if(moRet.items[param.material_option_id_2]) {
                    const mo2 = moRet.items[param.material_option_id_2];

                    ret = ret + (ret.length === 0 ? "" : ", ") + mo2.name;
                }
            }
        }

        if (param.result.length > 0) {
            for (let i = 0; i < param.result.length; i++) {
                const result = param.result[i];
                const r1 = result.app_result1_id;
                if (r1 > -1 && (r1 <= ar1.length -1 )) {
                    ret = ret + (ret.length === 0 ? "" : ", ") + ar1[r1].name;
                }

                const r2 = result.app_result2_id;
                if (r2 > -1 && (r2 <= ar2.length -1 )) {
                    ret = ret + (ret.length === 0 ? "" : ", ") + ar2[r2].name;
                }
            }

        }


        // console.log(param);
        //console.log(ret);

        return ret;
    }

    checkChanged = (param, param2) => {
        const item = param.props.item;
        const newValue = param2.target.value;

        const floor = this.getFloor(item);
        if(!floor.exportProbes) floor.exportProbes = {};
        const probesExportSettings = floor.exportProbes;
        probesExportSettings[item.id] = newValue;

        this.props.onCheck(param, param2);
    }

    // param is a sample
    buildItems = (param, key) => {

        if (param.sourceType === 2) {
            return null;
        }

        const floor = this.getFloor(param);
        if(!floor) {
            return null;
        }

        const visit = floor.exportCheck2;
        if (!visit) {
            return null;
        }

        let name = 'Siehe Abbildung';
        const content = this.getContent(param);
        const id = probe.getProbeName(this.props.appState, param, false);

        let tab4Images = null;
        if(param.images) {

            tab4Images = param.images.map((img, imgKey) => {
                return (
                    <ImgTab4 probeImage={img} key={imgKey} />
                );
            });
        } else {
            
            name = "";
            tab4Images = <ImgTab4 probeImage={null} key={0} />;
        }

        let isChecked = true;
        const exportsettingDataForItemExists = floor.exportProbes && floor.exportProbes.hasOwnProperty(param.id);
        if(exportsettingDataForItemExists) {
            isChecked = floor.exportProbes[param.id];
        }
        
        return (
            <div key={key} className="img-proben" >
                <div className="img-proben-title">
                    {name ? name : ""}
                    <span onClick={() => this.click(param)} className="img-proben-id">{id}</span>
                    <div className="img-proben-check">
                        <Check checked={isChecked} onChange={this.checkChanged} item={param} key={key+"_check"} label={name ? name : ""} />
                    </div>
                </div>
                
                <div className="img-proben-content">{content}</div>

                {tab4Images}
            </div>
        )
    }

    render() {
        return (
            <div className="imgs-proben">
                {this.props.items.map(this.buildItems)}
            </div>
        )
    }

}

class VisitsChecks extends Component {

    constructor(props) {

        super(props);

        const items = [];
        for (let i = 0; i < this.props.items.length; i++) {
            items.push({
                'open': true,
            })
        }

        this.state = {
            'items': items,
        }
    }

    checkChanged = (param, param2) => {
        const item = param.props.item;
        item["exportCheck" + this.props.index] = param2.target.value;
        this.props.onCheck(param, param2);
    }

    buildVisits = (param, key) => {
        if (!this.props.Lang) {
            return null;
        }
        const chk = param["exportCheck" + this.props.index] === true ? true : false;
        const options = {
            'day': '2-digit',
            'month': '2-digit',
            'year': 'numeric',
        };
        const date = new Date(param.date).toLocaleString("en-GB", options).replace("/", ".").replace("/", ".");

        return (
            <Check checked={chk} onChange={this.checkChanged} item={param} key={key} label={this.props.Lang.INVESTIGATION_NAME + " " + param.number + ", " + date} />
        )
    }

    click = (param) => {
        const itemOpen = this.state.items[param];
        itemOpen.open = !itemOpen.open;
        this.setState(this.state.items);
    }

    buildItems = (param, key) => {
        const itemOpen = this.state.items[key];

        return (
            <div key={key} className="floor">
                <div className="check-name">
                    <div index={key} onClick={() => { this.click(key) }} className="chk"></div>
                    {param.name}
                </div>
                {itemOpen.open ?
                    param.items.map(this.buildVisits)
                    : null}
            </div>
        )
    }

    render() {
        return (
            <div className="visits-checks">
                {this.props.items.map(this.buildItems)}
            </div>
        )
    }

}

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'tree_items': [],
            'show_probe': null,
            'show_download': false,
        }
    }

    componentWillMount() {
        this.props.actions.getAllSamplesForCurrentInvestigation();
        // Not sure if we need this room name / room line information here, but just uncomment if you need it
        // this.props.actions.getAllRoomNamesForCurrentInvestigation();
        // this.props.actions.getAllRoomLinesForCurrentInvestigation();
        this.props.actions.toggleRightBgGray(true);
    }

    componentWillUnmount() {
        this.props.actions.toggleRightBgGray(false);
    }

    populateTree(props) {
        const floors = props.items;
        const items = [];
        const options = {
            'day': '2-digit',
            'month': '2-digit',
            'year': 'numeric',
        };


        if (floors) {
            for (let i = 0; i < floors.length; i++) {
                const floor = floors[i];
                const floorObj = { 'name': floor.name, 'items': [], 'open': true };
                items.push(floorObj);

                const investiongations = floor.items;
                for (let j = 0; j < investiongations.length; j++) {
                    const investiongation = investiongations[j];
                    const date = new Date(investiongation.date).toLocaleString("en-GB", options).replace("/", ".").replace("/", ".");
                    const investiongationObj = {
                        'name': Lang.INVESTIGATION_NAME + " " + (investiongation.number) + ", " + date,
                        'items': [],
                        'object': investiongation,
                        'open': true
                    };
                    floorObj.items.push(investiongationObj);

                    const sampleLayers = investiongation.items;
                    for (let k = 0; k < sampleLayers.length; k++) {
                        const sampleLayer = sampleLayers[k];
                        const sampleLayerObj = {
                            'name': sampleLayer.name,
                            'object': investiongation,
                        };
                        const screenshots = sampleLayer.screenshots;
                        investiongationObj.items.push(sampleLayerObj);
                        if (screenshots) {
                            if (screenshots.length > 0) {
                                sampleLayerObj.items = [];
                                sampleLayerObj.open = true;
                            }
                            for (let i = 0; i < screenshots.length; i++) {
                                const scr = screenshots[i];
                                const scrObj = {
                                    'name': scr.value + "  " + scr.value2,
                                    screenshot: scr,
                                    'object': investiongation,
                                };
                                sampleLayerObj.items.push(scrObj);
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            tree_items: items,
        })

        //  console.log(items);
    }

    componentWillReceiveProps = (nextProps) => {
        this.populateTree(nextProps.appState.company_data);
    }

    checkTreeExport = (param) => {
        this.props.actions.toggleSave(true);
    }

    executeExport = (param) => {
        // 1 - is Export für Gutachten
        // 2_1 - is Export für Analysendatenblatt pdf
        // 2_2 - is Export für Analysendatenblatt docx

        this.showDownloadPopup();
        switch(param.props.index) {
            default:
            case "1":
                this.props.actions.doExport(DOC_GEN_URL + "/word/" + this.props.appState.company_data.id);
                // window.open(DOC_GEN_URL + "/word/" + this.props.appState.company_data.id , "_blank")
                break;

            case "2_1":
                this.props.actions.doExport(DOC_GEN_URL + "/pdf/" + this.props.appState.company_data.id);
                // window.open(DOC_GEN_URL + "/pdf/" + this.props.appState.company_data.id, "_blank")
                break;

            case "2_2":
                this.props.actions.doExport(DOC_GEN_URL + "/word/" + this.props.appState.company_data.id, "/2");
                // window.open(DOC_GEN_URL + "/word/" + this.props.appState.company_data.id + "/2" , "_blank")
                break;
        }
    }

    resetExports = (param) => {
        const items = this.props.appState.company_data.items;
        for (let i = 0; i < items.length; i++) {
            const floor = items[i];
            
            const investigations = floor.items;
            for (let j = 0; j < investigations.length; j++) {
                const investigation = investigations[j];
                investigation["exportCheck" + param.props.index] = false;

                const layers = investigation.items;
                for (let k = 0; k < layers.length; k++) {
                    const layer = layers[k];
                    const scrs = layer.screenshots
                    if (scrs) {
                        for (let l = 0; l < scrs.length; l++) {
                            const scr = scrs[l];
                            scr["exportCheck" + param.props.index] = false;
                        }
                    }
                }
            }
        }
        this.props.actions.toggleSave(true);
    }

    showProbePopup = (param) => {
        this.setState({
            'show_probe': param,
        })
    }

    closePopupProbe = () => {
        this.setState({
            'show_probe': null,
        })
    }

    showDownloadPopup = (param) => {
        this.setState({
            'show_download': true,
        })
    }

    closePopupDownload = () => {
        this.setState({
            'show_download': false,
        })
        this.props.actions.resetDownloadStatus();
    }

    downloadDocument = (downloadId) => {
        window.open(DOC_GEN_URL + "/download/" + downloadId , "_blank");
        this.closePopupDownload();
    }

    render() {
        return (
            <div className="tab4">
                <WhitePanel title={Lang.SECTION1_TITLE}>
                    <div className="buttons">
                        <Button index={"1"} onClick={this.executeExport} className="button btn" label={Lang.BTN_EXPORT} disabled={this.props.appState.saveInProgress} />
                        <Button index={1} onClick={this.resetExports} className="button btn" label={Lang.BTN_EINGABE} />
                    </div>
                    <div className="section-content">
                        <div className="section-content-left">
                            <div className="section-content-title">
                                {Lang.SECTION_SUB_TITLE_1}
                            </div>
                            <VisitsChecks Lang={Lang} index={1} onCheck={this.checkTreeExport} items={this.props.appState.company_data.items} />
                        </div>
                        <div className="section-content-right">
                            <div className="section-content-title">
                                {Lang.SECTION_SUB_TITLE_2}
                            </div>
                            <Tree3 treeIndex={1} onCheck={this.checkTreeExport} items={this.state.tree_items} />
                        </div>
                    </div>
                </WhitePanel>
                <WhitePanel title={Lang.SECTION2_TITLE}>
                    <div className="buttons">
                        <Button index={"2_1"} onClick={this.executeExport} className="button btn" label={Lang.BTN_GESAMTEXPORT} disabled={this.props.appState.saveInProgress} />
                        <Button index={"2_2"} onClick={this.executeExport} className="button btn" label={Lang.BTN_TABELLE} disabled={this.props.appState.saveInProgress} />
                        <Button index={2} onClick={this.resetExports} className="button btn" label={Lang.BTN_EINGABE} />
                    </div>
                    <div className="section-content">
                        <div className="section-content-left">
                            <div className="section-content-title">
                                {Lang.SECTION_SUB_TITLE_1}
                            </div>
                            <VisitsChecks Lang={Lang} index={2} onCheck={this.checkTreeExport} items={this.props.appState.company_data.items} />
                        </div>
                        <div className="section-content-right">
                            <div className="section-content-title">
                                {Lang.SECTION_SUB_TITLE_2}
                            </div>
                            <Tree3 treeIndex={2} onCheck={this.checkTreeExport} items={this.state.tree_items} />
                        </div>
                    </div>
                    <div className="section-content-title">
                        {Lang.SECTION_SUB_TITLE_3}
                    </div>
                    <ImgsProben onClick={this.showProbePopup} checkTreeExport={this.checkTreeExport} onCheck={this.checkTreeExport} appState={this.props.appState} items={this.props.appState.probesForCurrentInvestigation} />
                </WhitePanel>
                {this.state.show_probe ? <PopupProbe probe={this.state.show_probe} onClose={this.closePopupProbe} /> : null}
                {this.state.show_download ? <PopupDownload title={Lang.PREPARING_EXPORT} download_status={this.props.appState.download_status} companyDataId={this.props.appState.company_data.id} hideClose={true} onCancel={this.closePopupDownload} onAccept={this.downloadDocument}  /> : null}

            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
