import React, { Component } from 'react';
import "./company.scss";

import Floor from "components/floor/Floor";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFloor: "",
        }
    }

    componentWillReceiveProps(nextProps) {

        const data = nextProps.data;

        if (!data.selected) {
            this.setState({
                'selectedFloor': "",
            })
        }
    }



    click = (param) => {
        if (!this.props.appState.save) {
            if (this.props.data.company_data) {
                if (this.props.data.company_data.items.length > 0)
                    this.props.onClick(this);
            } else {
                this.props.onClick(this);
            }
        }
    }

    selectFloor = (param) => {
        if (!this.props.appState.save) {
            this.setState({
                'selectedFloor': param.props.id,
            })
            this.props.onFloorSelected(param);
        }
    }

    buildFloors = (param, key) => {
        return (
            <Floor appState={this.props.appState} key={key} selected={this.state.selectedFloor} onClick={this.selectFloor} {...param} />
        )
    }

    render() {
        // const dis = (this.props.company_data && this.props.company_data.items.length === 0) ? " disabled" : "";
        const dis = "";
        const props = this.props.props;
        const data = this.props.data;

        return (
            <div onClick={this.click} className={"company" + (data.selected ? " selected" : "") + dis}>
                <div className="company-id">
                    {props.number}
                </div>
                <div className="company-name">
                    {props.company}
                    <div className="company-floors">
                        {data.company_data ? data.company_data.items.map(this.buildFloors) : null}
                    </div>
                </div>
            </div>
        );
    }
}