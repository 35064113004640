import React, { Component } from 'react';
import "./plus.scss";

import Button2 from "_modules/button2/Button2";

export default class View extends Component {

    click = (params) => {
        this.props.onClick(this);
    }

    render() {
        return (
            <div className={this.props.cls ? this.props.cls : "plus"}>
                <Button2 left="+" label={this.props.label} {...this.props} onClick={this.click} onLbClick={this.props.onClick} />
            </div>
        )
    }
}