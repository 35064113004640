import React from 'react';
import './tree.scss';

export default class Tree extends React.Component {

    buildSubNode = (params, key) => {
        return this.buildNode(params, key);
    }

    buildNode = (params, key) => {
        return (
            <div key={key} className="tree-node">
                {params.component ? <params.component {...params} /> : null}
                {(params.open === true && params.items !== undefined) ?
                    <div className={"tree-sub-node level" + params.level } >
                        {params.items.map(this.buildNode)}
                    </div>
                    : null}
                {params.lastComponent ? <params.lastComponent {...params} /> : null}

            </ div>
        )
    }

    render() {
        return (
            <div className={this.props.className ? this.props.className : "tree"}>
                {this.props.items.map(this.buildNode)}
            </div>
        )
    }
}
