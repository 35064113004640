import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "states/appState";
import "./tab2.scss";
import * as Lang from "./Tab2Lang";
import Button from "_modules/button/Button";
import Button1 from "_modules/button1/Button1";
import ReactSVG from 'react-svg';
import * as LocalApi from "_services/LocalApi";
import * as Api from "_services/Api";

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fullImageUrl: null
        }
    }

    componentWillMount() {
        this.getFileURL();
    }

    getFileURL = () => {
        if(this.props.probeImage.file_id !== 0) {

            LocalApi.getFile(this.props.probeImage.file_id).then(
                (serverURL) => {

                    if(serverURL !== null) {
                        this.setState({
                            fullImageUrl: Api.getImageFullUrl(serverURL)
                        });
                    }
                }
            );
        }
    }
    
    render = () => {
        const arr = this.props.probeImage.arrow;
        const st = {
            'top': arr.y + "px",
            'left': arr.x + "px",
            'transform': "rotate(" + arr.rotation + "deg)",
            width: '50px',
            height: '50px'
        }

        return (
            <div key={this.props.key} className="pic">
                <img alt="" src={this.state.fullImageUrl} />

                {arr.show &&
                    <ReactSVG style={st} alt="" className="img-arr" src={require("assets/images/icon_arrow_pointer.svg")} />
                }

                {false &&
                <div className="btm">
                    <div className="btn-upl">
                        <Button photoIndex={this.props.key} className="button btn-photo" label={Lang.BTN_PHOTO} />
                        <input onChange={(ev) => {
                            const files = ev.target.files || ev.dataTransfer.files;
                            if (files.length)
                                this.props.changePhoto(this.props.probeImage);
                        }} className="fl" type="file"></input>
                    </div>

                    <Button1 photoIndex={this.props.key} onClick={this.deletePhoto} className="button button1 btn-delete" img={require("assets/images/g2234.svg")} />
                </div>
                }
            </div>
        )
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
