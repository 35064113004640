export const TITLE1 = "App Einstellungen";
export const TITLE2 = "Backend Einstellungen";
export const SUB1_TITLE1 = "Befund Menüoptionen";
export const SUB1_TITLE2 = "Material Menüoptionen";
export const SUB1_TITLE3 = "Probenorte";
export const SUB1_TITLE4 = "Probenarten";
export const SUB2_TITLE1 = "Befund Menüoptionen";
export const BTN_YES = "SPEICHERN";
export const BTN_NO = "Abbrechen";
export const BTN_OPTIONS = "Option";
export const DEL = "Möchten Sie die Kategorie 'a' einschließlich aller Unterkategoriepunkte löschen?";
export const NEW_CTG = "NEUE KATEGORIE";
export const EDIT_CTG = "EDIT KATEGORIE";
export const DEL_CTG = "KATEGORIE LÖSCHEN";
export const BTN_ADD_TEST = "Menüoption hinzufügen";
export const POPUP_TEST_TITLE = "Neue Menüoption anlegen";
export const POPUP_TEST_INPUT_TOP = "Bezeichnung";
export const POPUP_EDIT_TEST_TITLE = "Schadentyp bearbeiten";
export const POPUP_MATERIAL_OPTION_TITLE = "Neue Option anlegen";
export const POPUP_SAMPLE_LOCATION_TITLE = "Neue Option anlegen";
export const POPUP_SAMPLE_LOCATION_INPUT2 = "Kürzel";
export const POPUP_EDIT_MATERIAL_OPTION_TITLE = "Option bearbeiten";
export const POPUP_EDIT_SAMPLE_LOCATION_TITLE = "Option bearbeiten";
export const TEST_TYPE1 = "Merkmalsliste";
export const TEST_TYPE2 = "Ampelsystem";
export const POPUP_CONFIRM_DELETE_TEST_ITEM_TITLE = "Delete test item";
export const POPUP_CONFIRM_DELETE_TEST_ITEM_MESSAGE = "Möchten Sie diese Vorlage wirklich löschen?";
export const POPUP_CONFIRM_DELETE_TEST_MESSAGE = "Möchten Sie diese Menüoption wirklich löschen?";

