import React from "react";

import "./input-top.scss";
import Input from "_modules/input/Input"

class View extends React.Component {

    render() {

        const clsName = this.props.className ? this.props.className : "input-top";

        return (
            <div className={clsName}>
                <div className="input-top top">
                    {this.props.top}
                    {this.props.right !== undefined ? <span className="input-top-right">{this.props.right}</span> : null}
                </div>
                <Input {...this.props} className={this.props.inputClass !== undefined ? this.props.inputClass : this.props.className} />
            </div>
        );
    }
}

export default View;
