import React from "react";

import "./button.scss";

class View extends React.Component {

    click = data => {
        data.preventDefault();
        if (this.props.onClick)
            this.props.onClick(this);
    }

    render() {

        const icon = this.props.icon ? <i className={this.props.icon} /> : null;
        const img = this.props.img ? <img alt="" src={this.props.img} /> : null;
        const disClass = this.props.disabled === true ? " disabled" : "";
        const clsName = this.props.className ? this.props.className : "button";

        return (
            <div className={clsName + disClass} onClick={this.click}>
                <div className="button-content">{this.props.label}</div>
                {icon}
                {img}
                <div className="button-children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default View;
