import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./damagedata.scss";
import * as Lang from "./DamageDataLang";
import * as actions from "states/appState";

import Input from "_modules/input/Input";
import Test from "components/test/Test";
//import Button2 from "_modules/button2/Button2";
import Plus from "components/plus/Plus";
import Test1 from "components/tests/test1/Test1";
import Test2 from "components/tests/test2/Test2";
import PopupEdit from "components/popup-edit/PopupEdit";
import PopupConfirm from "components/popup-confirm/PopupConfirm";

import ButtonSave from "components/button-save/ButtonSave";
//import Tree2 from "components/tree2/Tree2";

import ItemsList from "_modules/items-list/ItemsList";
import Button2 from "_modules/button2/Button2";
import Radio from "_modules/radio/Radio";

class PlusList extends Component {

    click = param => {
        this.props.plusClick(this);
    }

    render() {
        return (
            <Plus className={this.props.className} label={this.props.plusLabel} onClick={this.click} cls={this.props.cls} />
        )
    }
}

class SettingsItem extends Component {

    edit = param => {
        this.props.parentProps.onEdit(this);
    }

    delete = param => {
        this.props.parentProps.onDelete(this);
    }

    click = param => {
        if (this.props.parentProps.onClick) {
            this.props.parentProps.onClick(this);
        }
    }

    render() {
        const dis = (this.props.parentProps.index < this.props.parentProps.length - 1) ? "" : " disabled";
        const nm = this.props.short !== undefined ? this.props.name + " " + this.props.short : this.props.name;

        return (
            <div className="settings-item">
                <div onClick={this.click} className={"item-name" + dis} > {nm}</div>
                <Button2 onClick={this.edit} className="button2 btn-edit" svg={require("assets/images/Symbol 25 – 210.svg")} />
                {false ? <Button2 onClick={this.delete} className="button2 btn-delete" svg={require("assets/images/delete.svg")} /> : null}
            </div>
        )
    }
}

class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTest: null,

            deleteTestItemId: "",

            showPopupTest: false,
            editPopupTest: false,
            showConfirmDeleteTestItemPopup: false,
            showConfirmDeleteTestPopup: false,
            showPopupAddMaterialOption: false,
            showPopupAddSampleLocation: false,
            showPopupEditMaterialOption: false,
            showPopupEditSampleLocation: false,

            showMaterialOption: [
                {
                    'visibility': true,
                    'selected': 0,
                    'last': 0,
                }, {
                    'visibility': false,
                    'selected': 0,
                    'last': 0,
                }
            ],

            showSampleLocation: [
                {
                    'visibility': true,
                    'selected': 0,
                    'last': 0,
                }, {
                    'visibility': false,
                    'selected': 0,
                    'last': 0,
                }, {
                    'visibility': false,
                    'selected': 0,
                    'last': 0,
                }
            ]
        }

        this.currentTest = null;
        this.currentMaterialOption = null;
        this.currentSampleLocation = null;
        this.currentTestType = 1;

        this.testTypes = [
            Test1, Test2
        ];

        this.lastDataID = -1;

    }

    showTest = param => {
        const index = param.props ? param.props.index : param;
        const prp = this.props.appState.backend_results.tests[index];
        const bItem = this.props.appState.backend_for_damage_type.tests[prp.id];

        const at = { 
            ...prp,
            ...bItem, 
            component: this.testTypes[prp.type], 
            index: param.props.index, 
            backend_for_damage_type: this.props.appState.backend_for_damage_type,
        };
        this.setState({
            activeTest: at,
        })
    }

    testChanged = (param, param2, ev, type) => {
        this.props.actions.testChanged(param, param2, ev, type);
    }

    testActiveChanged = (param, ev) => {
        this.props.actions.testActiveChanged(param, ev);
        this.props.actions.toggleSave(true);
    }

    addTestItem = param => {
        this.props.actions.addTestItem(this.state.activeTest);
    }

    deleteTestItem = param => {
        this.props.actions.deleteTestItem(this.state.activeTest, this.state.deleteTestItemId);
        this.closeConfirmDeleteTestItemPopup();
    }

    closeConfirmDeleteTestItemPopup = () => {
        this.setState({
            deleteTestItemId: "",
            showConfirmDeleteTestItemPopup: false
        });
    }

    showConfirmDeleteTestItemPopup = testItemId => {
        this.setState({
            deleteTestItemId: testItemId,
            showConfirmDeleteTestItemPopup: true
        });
    }

    buildTest = (param, key) => {
        return (
            <Test
                backend_for_damage_type={this.props.appState.backend_for_damage_type}
                onClickTT={this.showEditPopupTests}
                active={this.state.activeTest}
                index={key} key={key}
                {...param}
                onChange={this.testActiveChanged}
                onLabelClick={this.showTest}
            />
        )
    }

    infoChanged = (param, ev) => {
        this.props.actions.updateAppSettings(param, ev);
        this.props.actions.toggleSave(true);
    }

    buildAppResult1 = (param, key) => {
        return (
            <Input ctg="app_results_1" nameKey="name" index={key} onChange={this.infoChanged} key={key} value={param.name} {...param} />
        )
    }

    buildAppResult2 = (param, key) => {
        return (
            <Input ctg="app_results_2" nameKey="name" index={key} onChange={this.infoChanged} key={key} value={param.name} {...param} />
        )
    }

    buildSampleType = (param, key) => {
        return (
            <div className="subcnt" key={key}>
                <div className="tr1">
                    <Input ctg="sample_type" nameKey="name" linkTo="short" index={key} onChange={this.infoChanged} key={key} value={param.name} {...param} />
                </div>
                <div className="tr2">
                    <Input ctg="sample_type" nameKey="short" linkTo="name" index={key} onChange={this.infoChanged} key={key} value={param.short} {...param} />
                </div>
            </div>
        )
    }

    showPopupTests = params => {
        this.setState({
            showPopupTest: true
        });

    }

    closePopupTest = param => {
        this.setState({
            showPopupTest: false
        });
    }

    addTest = param => {
        this.props.actions.addTest({ name: param.state.value, type: this.testType });
        this.closePopupTest();
        this.setState({
            'activeTest': null,
        })
    }

    testTypeChanged = (param, param2) => {
        this.testType = param2.props.value;
    }

    closeConfirmDeleteTestPopup = () => {
        this.setState({
            showConfirmDeleteTestPopup: false
        });
    }

    showConfirmDeleteTestPopup = testItemId => {
        this.closeEditPopupTest();
        this.setState({
            showConfirmDeleteTestPopup: true
        });
    }

    deleteTest = param => {
        this.props.actions.deleteTest(this.currentTest);
        this.closeConfirmDeleteTestPopup();
        this.setState({
            'activeTest': null,
        })
    }

    updateTest = param => {
        this.props.actions.updateTest({ ...this.currentTest, 'newValue': param.state.value });
        this.closeEditPopupTest();
    }

    showEditPopupTests = param => {
        this.currentTest = param.props;
        this.setState({
            editPopupTest: true
        });
    }

    closeEditPopupTest = param => {
        this.setState({
            editPopupTest: false
        });
    }

    addMaterialOption = param => {
        if (param.state.value) {
            this.props.actions.addMaterialOption({ ...param.state, current: this.currentMaterialOption });
        }
        this.closePopupAddMaterialOption();
    }

    showPopupAddMaterialOption = param => {
        this.currentMaterialOption = param.props;
        this.setState({
            showPopupAddMaterialOption: true,
        })
    }

    closePopupAddMaterialOption = params => {
        this.setState({
            showPopupAddMaterialOption: false,
        })
    }

    addSampleLocation = param => {
        if (param.state.value) {
            let canAdd = true;
            if (this.currentSampleLocation.index === 0 && !param.state.value2)
                canAdd = false;
            if (canAdd)
                this.props.actions.addSampleLocation({ ...param.state, current: this.currentSampleLocation });
        }
        this.closePopupAddSampleLocation();
    }

    showPopupAddSampleLocation = param => {
        this.currentSampleLocation = param.props;
        this.setState({
            showPopupAddSampleLocation: true,
        })
    }

    closePopupAddSampleLocation = param => {
        this.setState({
            showPopupAddSampleLocation: false,
        })
    }

    showPopupEditMaterialOption = param => {
        this.currentMaterialOption = {
            ...param.props.parentProps, index: param.props.index, name: param.props.name,
        };
        console.log(param.props.index)
        console.log(param.props.parentProps.items[param.props.index].id)
        this.setState({
            showPopupEditMaterialOption: true,
        })
    }

    closePopupEditMaterialOption = params => {
        this.setState({
            showPopupEditMaterialOption: false,
        })
    }

    deleteMaterialOption = param => {
        if(window.confirm("Möchten Sie die Option wirklich löschen?")) {
            this.props.actions.deleteMaterialOption(this.currentMaterialOption);
            this.closePopupEditMaterialOption();
        }
    }



    updateMaterialOption = param => {
        if (param.state.value) {
            this.props.actions.updateMaterialOption({ ...this.currentMaterialOption, 'newValue': param.state.value });
        }
        this.closePopupEditMaterialOption();
    }

    showPopupEdiSampleLocation = param => {
        this.currentSampleLocation = {
            ...param.props.parentProps, index: param.props.index, name: param.props.name, short: param.props.short
        };
        this.setState({
            showPopupEdiSampleLocation: true,
        })
    }

    closePopupEditSampleLocation = params => {
        this.setState({
            showPopupEdiSampleLocation: false,
        })
    }

    deleteSampleLocation = param => {
        if(window.confirm("Möchten Sie den Probenort wirklich löschen?")) {
            this.props.actions.deleteSampleLocation(this.currentSampleLocation);
            this.closePopupEditSampleLocation();
        }
    }

    updateSampleLocation = param => {
        if (param.state.value) {
            this.props.actions.updateSampleLocation({ ...this.currentSampleLocation, 'newValue': param.state });
        }
        this.closePopupEditSampleLocation();
    }

    save = params => {
        this.props.actions.save();
    }

    buildSettingsItems = (props, key) => {
        return (
            <ItemsList key={key} {...props} />
        )
    }

    openMaterialOptionsNode = param => {
        const index = param.props.parentProps.index;
        const mo = this.state.showMaterialOption;
        const ni = index + 1;

        const iVis = mo[ni].visibility;
        for (let i = ni; i < this.state.showMaterialOption.length; i++) {
            mo[i].visibility = false;
        }

        mo[ni].selected = param.props.index;
        mo[ni].visibility = true;

        if (iVis && mo[ni - 1].last === param.props.index) {
            mo[ni].visibility = false;
        }

        mo[ni - 1].last = param.props.index;

        this.setState({
            showMaterialOption: mo,
        })
    }

    buildMaterialOptions = (param, key) => {
        if (!param.visibility)
            return null;

        let items = this.props.appState.damage_data.app_settings.material_options;
        if (!items) {
            return;
        }
        let i = 0;
        while (i < key) {
            i++;
            const ind = this.state.showMaterialOption[i].selected;
            items = items[ind].items;
        }

        return (
            <ItemsList
                key={key}
                length={this.state.showMaterialOption.length}
                lastComponent={PlusList}
                lastComponentProps={{ noItemsClass: "plus no-items" }}
                index={key}
                onClick={this.openMaterialOptionsNode}
                onEdit={this.showPopupEditMaterialOption}
                onDelete={this.deleteItem}
                plusClick={this.showPopupAddMaterialOption}
                plusLabel={Lang.BTN_OPTIONS}
                component={SettingsItem}
                items={items} />
        )
    }

    openSampleLocationsNode = param => {
        const index = param.props.parentProps.index;
        const mo = this.state.showSampleLocation;
        const ni = index + 1;

        const iVis = mo[ni].visibility;
        for (let i = ni; i < this.state.showSampleLocation.length; i++) {
            mo[i].visibility = false;
        }

        mo[ni].selected = param.props.index;
        mo[ni].visibility = true;

        if (iVis && mo[ni - 1].last === param.props.index) {
            mo[ni].visibility = false;
        }

        mo[ni - 1].last = param.props.index;

        this.setState({
            showSampleLocation: mo,
        })
    }

    buildSampleLocations = (param, key) => {
        if (!param.visibility)
            return null;

        let items = this.props.appState.damage_data.app_settings.sample_location;
        let i = 0;
        while (i < key) {
            i++;
            const ind = this.state.showSampleLocation[i].selected;
            if (!items[ind]) {
                return;
            }
            items = items[ind].items;
        }

        return (

            <ItemsList key={key} length={this.state.showSampleLocation.length} lastComponent={PlusList} lastComponentProps={{ noItemsClass: "plus no-items" }} index={key} onClick={this.openSampleLocationsNode} onEdit={this.showPopupEdiSampleLocation} plusClick={this.showPopupAddSampleLocation} plusLabel={Lang.BTN_OPTIONS} component={SettingsItem} items={items} />
        )
    }

    render() {
        const app_settings = this.props.appState.damage_data.app_settings;

        return (
            <div className="damagedata">
                <div className="title">{Lang.TITLE1}</div>
                <div className="cnt">
                    <div className="tl">
                        <div className="subtitle">{Lang.SUB1_TITLE1}</div>
                        <div className="subcnt">
                            <div className="tl1">
                                {app_settings.app_results_1.map(this.buildAppResult1)}
                            </div>
                            <div className="tl2">
                                {app_settings.app_results_2.map(this.buildAppResult2)}
                            </div>
                        </div>
                    </div>
                    <div className="tc1">
                        <div className="subtitle">{Lang.SUB1_TITLE2}</div>
                        <div className="settings-lists">
                            {this.state.showMaterialOption.map(this.buildMaterialOptions)}
                        </div>
                    </div>
                    <div className="tc2">
                        <div className="subtitle">{Lang.SUB1_TITLE3}</div>
                        <div className="settings-lists">
                            {this.state.showSampleLocation.map(this.buildSampleLocations)}
                        </div>
                    </div>
                    <div className="tr">
                        <div className="subtitle">{Lang.SUB1_TITLE4}</div>
                        {app_settings.sample_type.map(this.buildSampleType)}
                    </div>
                </div>
                <div className="bottom">
                    <div className="title">{Lang.TITLE2}</div>
                    <div className="cnt">
                        <div className="subtitle">{Lang.SUB2_TITLE1}</div>
                        <div className="tests-cnt">
                            <div className="tests">
                                {this.props.appState.backend_results.tests.map(this.buildTest)}
                            </div>
                            {this.state.activeTest ?
                                <div className="test-cnt">
                                    <this.state.activeTest.component {...this.state.activeTest} onChange={this.testChanged} addItem={this.addTestItem} showConfirmDeletePopup={this.showConfirmDeleteTestItemPopup}/>
                                </div>
                                : null}
                        </div>
                        <Plus onClick={this.showPopupTests} label={Lang.BTN_ADD_TEST} />
                    </div>
                </div>
                {this.state.showPopupAddMaterialOption ?
                    <PopupEdit onCancel={this.closePopupAddMaterialOption} onAccept={this.addMaterialOption} addClass=" pat" hideClose={true} top={Lang.POPUP_TEST_INPUT_TOP} title={Lang.POPUP_MATERIAL_OPTION_TITLE} />
                    : null}
                {this.state.showPopupAddSampleLocation ?
                    <PopupEdit secInputTop={this.currentSampleLocation.index === 0 ? Lang.POPUP_SAMPLE_LOCATION_INPUT2 : null} onCancel={this.closePopupAddSampleLocation} onAccept={this.addSampleLocation} addClass=" pat" hideClose={true} top={Lang.POPUP_TEST_INPUT_TOP} title={Lang.POPUP_SAMPLE_LOCATION_TITLE} ></PopupEdit>
                    : null}
                {this.state.showPopupTest ?
                    <PopupEdit contentBottom={
                        <div className="test-radios">
                            <Radio name="test_type" onChange={this.testTypeChanged} selected={0} values={[{ 'label': Lang.TEST_TYPE1, 'value': "0" }, { 'label': Lang.TEST_TYPE2, 'value': "1" }]} />
                        </div>
                    } onCancel={this.closePopupTest} onAccept={this.addTest} addClass=" pat adt" hideClose={true} top={Lang.POPUP_TEST_INPUT_TOP} title={Lang.POPUP_TEST_TITLE} >
                    </PopupEdit>
                    : null}
                {this.state.editPopupTest ?
                    <PopupEdit value={this.currentTest.name} showLeft={true} onDelete={this.showConfirmDeleteTestPopup} onCancel={this.closeEditPopupTest} onAccept={this.updateTest} addClass=" pat" hideClose={true} top={Lang.POPUP_TEST_INPUT_TOP} title={Lang.POPUP_EDIT_TEST_TITLE} />
                    : null}
                {this.state.showPopupEditMaterialOption ?
                    <PopupEdit value={this.currentMaterialOption.name} showLeft={true} onDelete={this.deleteMaterialOption} onCancel={this.closePopupEditMaterialOption} onAccept={this.updateMaterialOption} addClass=" pat" hideClose={true} top={Lang.POPUP_TEST_INPUT_TOP} title={Lang.POPUP_EDIT_MATERIAL_OPTION_TITLE} />
                    : null}
                {this.state.showPopupEdiSampleLocation ?
                    <PopupEdit secInputTop={this.currentSampleLocation.short !== undefined ? Lang.POPUP_SAMPLE_LOCATION_INPUT2 : null} value2={this.currentSampleLocation.short} value={this.currentSampleLocation.name} showLeft={true} onDelete={this.deleteSampleLocation} onCancel={this.closePopupEditSampleLocation} onAccept={this.updateSampleLocation} addClass=" pat" hideClose={true} top={Lang.POPUP_TEST_INPUT_TOP} title={Lang.POPUP_EDIT_SAMPLE_LOCATION_TITLE} />
                    : null}
                {this.state.showConfirmDeleteTestItemPopup ?
                    <PopupConfirm showLeft={false} onDelete={this.deleteTestItem} onCancel={this.closeConfirmDeleteTestItemPopup} onAccept={this.deleteTestItem} addClass=" pat" hideClose={true} message={Lang.POPUP_CONFIRM_DELETE_TEST_ITEM_MESSAGE} title="" ></PopupConfirm>
                    : null}
                {this.state.showConfirmDeleteTestPopup ?
                    <PopupConfirm showLeft={false} onDelete={this.deleteTest} onCancel={this.closeConfirmDeleteTestPopup} onAccept={this.deleteTest} addClass=" pat" hideClose={true} message={Lang.POPUP_CONFIRM_DELETE_TEST_ITEM_MESSAGE} title="" ></PopupConfirm>
                    : null}
                {this.props.appState.save ? <ButtonSave onClick={this.save} /> : null}
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
