import React, { Component } from 'react';
import "./plnright.scss";

import * as Api from "_services/Api";

import Button from "_modules/button/Button";
import * as Lang from "./PlnRightLang";

import Check from "_modules/check/Check";
import Slider from "_modules/slider/Slider";
import PopupEdit from "components/popup-edit/PopupEdit";

class ShItem extends Component {

    click = (param) => {
        this.props.onDelete(this);
    }

    render() {
        return (
            <div
                className="shitem"
                onClick={this.props.loadScreenShot}
            >
                <Button className="button btn1" label={this.props.label.value + " " + this.props.label.value2} />
                <div onClick={this.click} className="x">X</div>
            </div>
        )
    }
}

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showScreenshotPopup: false,
        }

    }

    componentDidMount() {
    }

    showScreenshotPopup = (param) => {
        this.setState({
            'showScreenshotPopup': true,
        })
    }

    closeScreenshotPopup = (param) => {
        this.setState({
            'showScreenshotPopup': false,
        })
    }

    deleteScreenshotItem = (param) => {
        this.props.actions.removeScreenshot(param.id);
    }

    takeScreenshot = param => {
        const trimmedValue = param.state.value.trim();
        const trimmedValue2 = param.state.value2.trim();

        const newScreenshot = {
            id: Api.getUid(),
            x: this.props.blueprintOffsetX,
            y: this.props.blueprintOffsetY,
            zoom: this.props.blueprintOffsetZoom, // is 100 - zoomLevel
            zoomLevel: this.props.zoomLevel,
            legendScale: this.props.legendScale,
            interestPointRadius: this.props.interestPointRadius,
            interestAreaWidth: this.props.interestAreaWidth,
            interestAreaHeight: this.props.interestAreaHeight,
            value: trimmedValue,
            value2: trimmedValue2,
            exportCheck1: true,
            exportCheck2: true,
            created_at: Date.now()
        }

        // send the screensot to server
        Api.uploadScreenShot(
            newScreenshot.id,
            document.getElementById("floorCanvas").toDataURL('image/jpeg'),
            document.getElementById("legend-btm").toDataURL('image/jpeg'),
            this.props.interestAreaWidth, this.props.interestAreaHeight,
            trimmedValue + ' ' + trimmedValue2
        );

        this.props.actions.addScreenshot(newScreenshot);

        this.closeScreenshotPopup();
    }

    changeLegend = (param, ev) => {
        this.props.actions.toggleLegend({
            'index': param.props.index,
            'visible': ev.target.value,
        })
    }

    componentWillMount() {
        
        if (this.props.appState.probes.legend > 0) {
            this.changeLegend({
                'props': {
                    'index': 0,
                }
            }, {
                'target': {
                    'value': false,
                }
            });

            setTimeout(() => {
                this.changeLegend({
                    'props': {
                        'index': 0,
                    }
                }, {
                    'target': {
                        'value': true,
                    }
                });
            }, 10);
        }
    }

    buildLegend = (param, key) => {
        if (param.sample_layer_id !== this.props.appState.currentSampleLayer) {
            return;
        }

        return (
            <Check key={key} label={param.text} index={key} onChange={this.changeLegend} checked={param.visible} />
        )
    }

    sliderValue = () => {
        // the reverse of the function onChangeRadiusLevel in Planner.js
        return this.props.interestPointRadius / 2 + 150
    }

    sliderLabelPercentage = () => {
        let percent100 = 150 / 2;
        let currentPercent = (( this.props.interestPointRadius === 1 ? 0 : this.props.interestPointRadius ) + 150) / 2;
        return Math.round((currentPercent/percent100 * 100))
    }

    render() {
      
        return (
            <>

            <div className="plnright">
                <div>
                    {Lang.LEGENDE_TITLE}
                </div>
                <div className="checks">
                    {this.props.appState.legend.map(this.buildLegend)}
                </div>

                <div className="sliders">
                    <div className="sliders-lb">
                        {Lang.SLD_TITLE}
                    </div>
                    <Slider label={Lang.SLD_LEGEND + " (" + this.props.legendScale + "%)"} step={5} min="20" max="180" value={this.props.legendScale} onChange={this.props.onChangeLegendScale} />
                    <Slider label={Lang.SLD_PLANNER_ZOOM + " (" + this.props.zoomLevel + "%)"} step={5} min="20" max="180" value={this.props.zoomLevel} onChange={this.props.onChangeScaleLevel} />
                    <Slider label={Lang.SLD_PROBES_ZOOM + " (" + this.sliderLabelPercentage() + "%)"} step={3} min="138" max="201" value={this.sliderValue()} onChange={this.props.onChangeRadiusLevel} />
                </div>

                <div className="btm">
                    <Button onClick={this.showScreenshotPopup} className="button btn1" label={Lang.BTN_SAVE} />
                    <div className="btm-scr">
                        {this.props.appState.screenshots.map((s, index) => <ShItem
                            onDelete={() => this.deleteScreenshotItem(s)} index={index}
                            key={"ScreenShot " + parseInt(index + 1)} label={s} loadScreenShot={() => {
                                this.props.loadScreenShot(s.x, s.y, s.zoom, s.zoomLevel, s.interestAreaWidth, s.interestAreaHeight, 
                                    s.legendScale, s.interestPointRadius);
                            }} />)}
                    </div>
                </div>
            </div>


            {this.state.showScreenshotPopup ?
                <PopupEdit
                    showLeft={false} hideClose={true} title={Lang.POPUP_SCREENSHOT_TITLE} addClass=" pat scr"
                    onCancel={this.closeScreenshotPopup} onAccept={this.takeScreenshot}
                    top={Lang.POPUP_SCREENSHOT_TOP_LEFT}
                    secInputTop={Lang.POPUP_SCREENSHOT_TOP_RIGHT}
                />
                : null}
            </>
        )
    }
}