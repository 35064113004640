import React, { Component } from 'react';
import "./button-save.scss";
import * as Lang from "./ButtonSaveLang";

import Button from "_modules/button/Button";

export default class View extends Component {

    render() {
        return (
            <Button {...this.props} className="button button-save" label={Lang.LABEL} />
        )
    }
}