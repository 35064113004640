import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Lang from "./LoginLang";
import "./login.scss";
import * as actions from "./loginState";

import Button from "_modules/button/Button";
import Input from "_modules/input/Input";

window.LoginURL = "/login";
class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            err: "",
            btnDisabled: true,
        }

        this.props.actions.doLoginState(this);
    }

    checkAllCompleted = (params) => {
        if (this.state.username === "" || this.state.password === "") {
            return false;
        }

        return true;
    }

    login = data => {
        if (!this.checkAllCompleted()) {
            this.setState({
                err: Lang.ERR_COMPLETE_FIELDS,
            });
            return;
        }
        this.setState({ err: "", btnDisabled: true });
        this.props.actions.login(this.state, this);
    }

    keyPressed = (data, ev) => {
        if (ev.key === "Enter") {
            this.login();
        }
    }

    dataChanged = (data, event) => {

        const st = this.state;
        st[data.props.name] = event.target.value;
        st.err = "";
        st.btnDisabled = false;

        if (!this.checkAllCompleted()) {
            st.btnDisabled = true;
        }
        this.setState(st);
    }


    componentWillReceiveProps(nextProps) {

        const isError = nextProps.loginState.error;
        if (isError) {
            let err = Lang[isError.error];
            if (!err) {
                err = isError.error;
            }
            this.setState({
                err: err,
                btnDisabled: false,
            });
        } else {
            if (!this.props.loginState.data) {
                this.setState({
                    'username': "",
                    'password': "",
                    'err': "",
                    'btnDisabled': true,
                })
            }
        }

    }

    render() {
        if (this.props.loginState.data) {
            return null;
        }


        return (
            <div className="login">
                <div className="login-title">
                    {Lang.TITLE}
                </div>
                <div className="login-cnt">
                    <Input autoFocus={true} img={require("assets/images/username.svg")} name="username" onKeyPress={this.keyPressed} disabled={this.props.id} placeholder={Lang.INP_INSERT_USER} onChange={this.dataChanged} value={this.state.username} />
                    <Input img={require("assets/images/password.svg")} name="password" onKeyPress={this.keyPressed} type="password" placeholder={Lang.INP_INSERT_PASSWORD} onChange={this.dataChanged} value={this.state.password} />
                    <div className="login-err">
                        {this.state.err}
                    </div>
                </div>
                <div className="login-btm">
                    <Button disabled={this.state.btnDisabled} label={Lang.BTN_LOGIN} onClick={this.login} />
                </div>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
