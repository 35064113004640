import React, { Component } from 'react';
import "./popup-confirm.scss";
import * as Lang from "./PopupConfirmLang";

import Popup from "_modules/popup/Popup";
import Button from "_modules/button/Button";
import Button3 from "_modules/button3/Button3";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            value2: this.props.value2,
            error: false,
            error2: false,
        }

    }

    change = (params, ev) => {
        this.setState({
            'value': ev.target.value,
            'error': false,
        })
    }


    change2 = (params, ev) => {
        this.setState({
            'value2': ev.target.value,
            'error2': false,
        })
    }


    cancel = (param) => {
        this.props.onCancel(this);
    }

    accept = (param) => {
        this.props.onAccept(this);
    }

    delete = (param) => {
        this.props.onDelete(this);
    }

    render() {
        const adClass = this.props.addClass ? this.props.addClass : "";

        return (
            <Popup {...this.props} title={this.props.title} className={"popup popup-confirm" + (this.props.top ? " top" : "") + adClass} >
                <div className="pedit-top"> 
                    {this.props.message
                        ? <label value={this.state.value2} onChange={this.change2}>{this.props.message}</label>
                        : null
                    }
                </div>
                <div className="popup-edit-buttons">
                    {this.props.showLeft ?
                        <div className="left">
                            <Button3 onClick={this.delete} label={Lang.BTN_DELETE} />
                        </div>
                        : null}
                    <div className="right">
                        <Button3 onClick={this.cancel} label={Lang.BTN_CANCEL} />
                        <Button onClick={this.accept} label={Lang.BTN_ACCEPT} />
                    </div>

                </div>
            </Popup>
        );
    }
}