import React, { Component } from 'react';
import "./tab.scss";

export default class View extends Component {

    click = (params) => {
        this.props.onClick(this);
    }

    render() {

        return (
            <div onClick={this.click} className={"tab" + (this.props.selected ? " selected" : "") + (this.props.disabled ? " disabled" : "")}>
                {this.props.label}
            </div>
        );
    }
}