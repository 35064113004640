import React from "react";

import "./button2.scss";
import ReactSVG from 'react-svg'

class View extends React.Component {

    click = params => {
        params.preventDefault();
        if (this.props.onClick)
            this.props.onClick(this);
    }

    lbClick = params => {
        params.preventDefault();
        if (this.props.onLbClick)
            this.props.onLbClick(this);
    }

    lbLeftClick = params => {
        params.preventDefault();
        if (this.props.onLbLeftClick)
            this.props.onLbLeftClick(this);
    }

    render() {

        const clsName = this.props.className ? this.props.className : "button2";

        return (
            <div className={clsName} >
                {this.props.lbLeft ? <div onClick={this.lbLeftClick} className="button2-lb-left">{this.props.lbLeft}</div> : null}
                {this.props.left ?
                    <div className="button2-left" onClick={this.click}>{this.props.left}</div>
                    :
                    this.props.img ? <img className="button2-left-img" alt="" onClick={this.click} src={this.props.img} /> :
                        this.props.svg ? <ReactSVG className="button2-left-img" onClick={this.click} src={this.props.svg} /> : null
                }
                <div className="button2-label" onClick={this.lbClick}>{this.props.label}</div>
            </div>
        );
    }
}

export default View;
