export const SECTION1_TITLE = "Export für Gutachten";
export const SECTION2_TITLE = "Export als Analysedatenblatt";
export const SECTION_SUB_TITLE_1 = "Auswahl Begehungen";
export const SECTION_SUB_TITLE_2 = "Auswahl Screenshots";
export const SECTION_SUB_TITLE_3 = "Proben";
export const BTN_EXPORT = "EXPORT";
export const BTN_EINGABE = "EINGABE LEEREN";
export const BTN_GESAMTEXPORT = "GESAMTEXPORT";
export const BTN_TABELLE = "EXPORT TABELLE";
export const INVESTIGATION_NAME = "Begehung";
export const PREPARING_EXPORT = "Export vorbereiten";