import React from "react";

import "./text-area.scss";

class View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value !== undefined ? this.props.value : "",
            focus: "",
        }

        this.tIn = 0;
    }

    _onChange = data => {
        if (this.props.onChange) {
            this.props.onChange(this, { target: { value: this.state.value } });
        }
    }

    onChange = data => {
        this.setState({
            value: data.target.value,
        })
        clearTimeout(this.tIn);
        this.tIn = setTimeout(() => {
            this._onChange(data);
        }, this.props.interval ? this.props.interval : 0);
    }

    blur = (params) => {
        if (this.props.onBlur) {
            this.props.onBlur(this);
        }
        this.setState({
            focus: "",
        })
    }

    focus = (params) => {
        if (this.props.onFocus) {
            this.props.onFocus(this);
        }
        this.setState({
            focus: " focus",
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== undefined) {
            this.setState({
                value: nextProps.value,
            })
        } else { }
    }

    keyPressed = data => {
        if (data.charCode === 13) {
           // data.target.blur();
        }
        if (this.props.onKeyPress) {
            this.props.onKeyPress(this, data);
        }
    }

    render() {

        const img = this.props.img ? <img alt="" src={this.props.img} /> : null;
        const placeholder = this.props.placeholder ? this.props.placeholder : "";
        const type = this.props.type ? this.props.type : "";
        const name = this.props.name ? this.props.name : "";
        const val = this.state.value;
        const id = this.props.id ? this.props.id : undefined;
        const clsName = this.props.className ? this.props.className : "text-area" + this.state.focus;
        return (
            <div className={clsName}>
                {this.props.img ?
                    <div className="img">
                        {img}
                    </div>
                    : null
                }
                {this.props.label ?
                    <div className="label">
                        {this.props.label}
                    </div>
                    : null
                }
                <textarea onFocus={this.focus} onBlur={this.blur} autoFocus={this.props.autoFocus !== undefined ? this.props.autoFocus : false} min={this.props.min} max={this.props.max} maxLength={this.props.maxLength} onKeyPress={this.keyPressed} id={id} name={name} onChange={this.onChange} type={type} placeholder={placeholder} value={val} />
                {this.props.label2 ?
                    <div className="label2">
                        {this.props.label2}
                    </div>
                    : null
                }
                {this.props.children}
            </div>
        );
    }
}

export default View;
