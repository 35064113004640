import React, { Component } from 'react';
import "./panel.scss";

export default class View extends Component {

    render() {

        return (
            <div className="panel">
                {this.props.label}
            </div>
        );
    }
}