export const getSampleTypeLocation = (appState, param) => {
    const sample_type = appState.damage_data.app_settings.sample_type;
    const sample_location = appState.damage_data.app_settings.sample_location;

    const st = param.sample_type;
    const sl1 = param.sample_location_1;

    let cst = null;
    let csl = null;
    let cstIndex = -1;
    let cslIndex = -1;

    for (let i = 0; i < sample_type.length; i++) {
        if (sample_type[i].id === st) {
            cst = sample_type[i];
            // Added a +1 to take into account the addition of the please select option added in the dropdown from tab2
            cstIndex = i+1;
        }
    }


    for (let i = 0; i < sample_location.length; i++) {
        if (sample_location[i] && sample_location[i].id === sl1) {
            csl = sample_location[i];
            cslIndex = i;
        }
    }

    return ({
        'sample_type': cst,
        'sample_location': csl,
        'sample_type_index': cstIndex,
        'sample_location_index': cslIndex,
    })
}

 export const getSampleTypeLocation2_1 = (appState, param) => {
    const gstl = getSampleTypeLocation(appState, param);
    const sl = gstl.sample_location ? gstl.sample_location.items : [];

    for (let i = 0; i < sl.length; i++) {
        if (sl[i] && sl[i].id === param.sample_location_2) {
            return sl[i];
        }
    }

    return null;
}

export const getSampleTypeLocation2 = (appState, param, index) => {
    if(index < 0 || !appState.damage_data.app_settings.sample_location[index]) {
        return -1;
    }

    const items = appState.damage_data.app_settings.sample_location[index].items;

    for (let i = 0; i < items.length; i++) {
        if (items[i] && items[i].id && items[i].id === param.sample_location_2) {
            return i;
        }
    }

    return -1;
}

export const getSampleTypeLocation3 = (appState, param, index, index2) => {
    if(index < 0 || index2 < 0 || !appState.damage_data.app_settings.sample_location[index]) {
        return -1;
    }

    const sl = appState.damage_data.app_settings.sample_location[index];
    if (sl) {
        if (sl.items[index2]) {
            const items = sl.items[index2].items;
            if (items) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i] && items[i].id && items[i].id === param.sample_location_3) {
                        return i;
                    }
                }
            }
        }
    }

    return -1;
}

export const getProbeName = (appState, data, forGroup = true) => {
    //const selSymb = data.form;
    const sourceType = data.sourceType;
    const num = data.number;
    const nt = data.numbering_type;
    //const symImg = this.probesSymbols[sourceType].items[selSymb].img;
    let lb = "";

    //console.log(data);
    if (sourceType === 0) {
        if (nt === 0) {
            lb = num;

            if (forGroup && data.group > 1) {
                lb = "(" + data.group + ")";
            }
        }
        if (nt === 1) {
            lb = data.prefix + "" + num;

            if (forGroup && data.group > 1) {
                lb = data.prefix + "" + data.number + "(" + data.group + ")";
            }
        }
        if (nt === 2) {
            const gstl = getSampleTypeLocation(appState, data);
            lb = (gstl.sample_location ? gstl.sample_location.short : "_") + "" + data.iGroup /* + "-" + gstl.sample_type.short + "1" */;

            // We no longer differentiate between single probe and multiple probes at the same location in the naming of the 4 digits probes
            // Always show as B1, B2
            // GUTD-373
            // https://netzinkubator.atlassian.net/browse/GUTD-373
            // if (!forGroup || data.group === 1) {
            //     let suffixSampleType = data.index_for_sample_type ? data.index_for_sample_type : data.index;
            //     lb += ("-" + gstl.sample_type.short + "" + suffixSampleType);
            // }
        }
    }
    if (sourceType === 1) {
        lb = data.text;
    }

    return lb;
}