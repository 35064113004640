import React, { Component } from 'react';
import "./shblueprint.scss";

import Screenshot from "../screenshot/Screenshot";
//import * as Lang from "./ShBluePrintLang";
import * as probe from "_utils/probe";

import * as LocalApi from "_services/LocalApi";
import * as Api from "_services/Api";

const fire_source = new Image();

// should match the one from app Probes.js
const defaultDimForMarkings = 100;
const defaultDimForProbes = 80;


export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastX: 0,
            lastY: 0,
            interestPointRadius: this.props.interestPointRadius,

            currentBackgroundFileUrl: null,
            currentFloorFileId: 0
        }

        this.colors = [
            {
                //'img': require("assets/images/colors/Pfad 258.svg"),
                'color': '#FF6085',
                'id': 0,
            }, {
                // 'img': require("assets/images/colors/Pfad 259.svg"),
                'color': '#51DBB6',
                'id': 1,
            }, {
                //'img': require("assets/images/colors/Pfad 261.svg"),
                'color': '#F4E785',
                'id': 2,
            }, {
                // 'img': require("assets/images/colors/Pfad 260.svg"),
                'color': '#51B6DB',
            }
        ];

        this.radius = defaultDimForProbes / 2;
        this.offsetX = 0.4;

        this.irt = null;

        if (fire_source.src === "") {
            fire_source.src = require("assets/images/fire_source.svg");
        }

        this.planCanvas = null;
        this.canvasCtx = null;
        this.planImage = null;

        this.redrawFrame = false;
    }

    componentWillUnmount() {
        if(this.redrawFrame !== false) {
            window.cancelAnimationFrame(this.redrawFrame);
        }

        // window.removeEventListener("resize", this.onResize);
        // this.canvasCtx = null;
    }

    getProbeColor(probe) {
        return this.colors[probe.color].color;
    }

    resetFloorPlanFile = () => {
        this.setState({
            currentBackgroundFileUrl: null,
            currentFloorFileId: 0
        });
    }

    getFloorPlanFile = () => {
        if(this.state.currentFloorFileId !== this.props.appState.currentFloor.file_id) {

            if(this.props.appState.currentFloor.file_id !== 0) {

                LocalApi.getFile(this.props.appState.currentFloor.file_id).then(
                    (serverURL) => {

                        if(serverURL !== null) {
                            this.setState({
                                currentBackgroundFileUrl: Api.getImageFullUrl(serverURL),
                                currentFloorFileId: this.props.appState.currentFloor.file_id
                            });
                        } else {
                            this.setState({
                                currentBackgroundFileUrl: null,
                                currentFloorFileId: this.props.appState.currentFloor.file_id
                            });
                        }
                    }
                );
            } else {
                this.resetFloorPlanFile();
            }
        }
    }
    
    // Get the maximum font size that fits the context width
    getFontSizeToFit = (ctx, text, fontFace, maxWidth) => {
        ctx.font = `1px ${fontFace}`;
        return maxWidth / ctx.measureText(text).width;
    }

    drawSymbol = (ctx, p) => {
        const st = p.sourceType;
        //const nt = p.numbering_type;
        const form = p.form;
        const off = {
            x: 0, y: 0,
        }

        // console.log(`radius level: ${this.props.interestPointRadius}`);

        const sc = this.props.interestPointRadius;

        // Initial probe dimensions
        var probeWidth = defaultDimForProbes + sc;
        var probeHeight = defaultDimForProbes + sc;

        if (st === 0) {
            ctx.beginPath();

            if (form === 0) {
                ctx.arc(p.x + off.x, p.y + off.y, this.radius + this.props.interestPointRadius, 0, 2 * Math.PI);

                // Width and height for circular probes
                probeWidth = 2 * (this.radius + this.props.interestPointRadius);
                probeHeight = 2 * (this.radius + this.props.interestPointRadius);
            }
            if (form === 1) {
                const len = defaultDimForProbes / 2.15 + sc;
                const oy = -10;

                ctx.moveTo(p.x + off.x, p.y + off.y - len + oy);
                ctx.lineTo(p.x + off.x - len, p.y + off.y + len + oy);
                ctx.lineTo(p.x + off.x + len, p.y + off.y + len + oy);

                // Width and height for triangle probes
                probeWidth = len;
                probeHeight = len;
            }
            if (form === 2) {
                const len2 = defaultDimForProbes + sc;
                ctx.rect(p.x + off.x - len2 * 0.5, p.y + off.y - len2 * 0.5, len2, len2);

                // Width and height for square probes
                probeWidth = len2;
                probeHeight = len2;
            }
            if (form === 3) {
                const len3 = defaultDimForProbes / 2 + sc;
                ctx.moveTo(p.x + off.x + len3 * Math.cos(0), p.y + off.y + len3 * Math.sin(0));

                for (let side = 0; side < 7; side++) {
                    ctx.lineTo(p.x + off.x + len3 * Math.cos(side * 2 * Math.PI / 6), p.y + off.y + len3 * Math.sin(side * 2 * Math.PI / 6));
                }

                // Width and height for hexagon probes
                probeWidth = len3 * 2;
                probeHeight = len3 * 2;
            }
            ctx.closePath();
            ctx.fillStyle = this.getProbeColor(p);
            ctx.fill();

        }
        if (st === 1) {
            ctx.drawImage(fire_source, 
                p.x - (defaultDimForProbes + sc) / 2,
                p.y - (defaultDimForProbes + sc) / 2,
                defaultDimForProbes + sc, 
                defaultDimForProbes + sc);
        }

        if (st === 2) {
            ctx.beginPath();
            if (form === 0) {
                const rad = p.width !== undefined ? p.width / 2 : this.radius;
                ctx.arc(p.x + off.x, p.y + off.y, rad + this.props.interestPointRadius, 0, 2 * Math.PI);

                // Width and height for circular probes
                probeWidth = 2 * (rad + this.props.interestPointRadius);
                probeHeight = 2 * (rad + this.props.interestPointRadius);
            }
            if (form === 1) {
                const w = (p.width !== undefined ? p.width : defaultDimForMarkings + sc * 1.5) + this.props.interestPointRadius;
                const h = (p.height !== undefined ? p.height : defaultDimForMarkings + sc * 1.5) + this.props.interestPointRadius;
                ctx.rect(p.x + off.x - w * 0.5, p.y + off.y - h * 0.5, w, h);

                // Width and height for square probes
                probeWidth = w;
                probeHeight = h;
            }
            ctx.closePath();
            let op = 255;
            if (p.opacity !== undefined) {
                op = Math.round(255 * p.opacity / 100);
            }

            ctx.fillStyle = this.getProbeColor(p) + op.toString(16);
            ctx.fill();
        }

        if (st !== 1) {
            ctx.fillStyle = "black";

            let pName = probe.getProbeName(this.props.appState, p);
            ctx.textAlign = "center";

            var fontSize = this.getFontSizeToFit(ctx, pName, "Arial", probeWidth);

            // Reduce font size to 80% from the calculate size to fit inside the circle, triangle and the hexagon probes
            fontSize = Math.min(fontSize, probeHeight) * 0.8;
            ctx.font = fontSize + "px Arial";
            
            let metrics = ctx.measureText(pName);
            let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
            let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            
            if (form === 1) {
                // Move the text a bit lower(1.1) when the interestPointRadius raises to keep the text inside the triangle probes
                ctx.fillText(pName, p.x + off.x, p.y + actualHeight/2 + 0.2 * this.props.interestPointRadius );
            } else {
                ctx.fillText(pName, p.x + off.x, p.y + actualHeight/2);
            }
        }
    }

    drawRoomNameSymbol = (ctx, p) => {
        const off = {
            x: 0, y: 0,
        }
        
        // const w = p.width;
        // const h = p.height;

        // ctx.beginPath();

        // ctx.rect(p.x + off.x - w * 0.5, p.y + off.y - h * 0.5, w, h);

        // ctx.closePath();

        // let op = 255;
        // op = Math.round(255 * 0.7);
        // ctx.fillStyle = this.getProbeColor(p) + op.toString(16);

        // Only this grey color for now
        // const color = "#c2bfbf" + op.toString(16);
        // ctx.fillStyle = color;
        // ctx.fill();

        const fontFactor = 2.3;

        ctx.fillStyle = "black";
        const font = parseInt(p.fontSize);
        ctx.font = Math.floor(fontFactor * font) + "px Montserrat-Regular";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(p.roomName, p.x, p.y, p.width);
    }

    drawRoomLineSymbol = (ctx, p) => {
        // const off = {
        //     x: 0, y: 0,
        // }

        let w = p.width;
        const h = p.height;

        // save the unrotated context of the canvas so we can restore it later
        // the alternative is to untranslate & unrotate after drawing
        ctx.save();

        // rotate the canvas to the specified degrees
        ctx.translate(p.x, p.y);
        ctx.rotate(p.rotateAngle * Math.PI / 180);

        ctx.beginPath();
        
        ctx.rect(- w * 0.5, - h * 0.5, w, h);
        // ctx.rect(0, 0, w, h);

        ctx.closePath();
        
        let op = 255;
        // op = Math.round(255 * 0.6);

        // ctx.fillStyle = this.getProbeColor(p) + op.toString(16);
        const color = this.getProbeColor(p) + op.toString(16);
        ctx.fillStyle = color;
        ctx.fill();

        // we’re done with the rotating so restore the unrotated context
        ctx.restore();
    }

    drawCorrectionLineSymbol = (ctx, p) => {
        // const off = {
        //     x: 0, y: 0,
        // }

        let w = p.width;
        const h = p.height;

        // save the unrotated context of the canvas so we can restore it later
        // the alternative is to untranslate & unrotate after drawing
        ctx.save();

        // rotate the canvas to the specified degrees
        ctx.translate(p.x, p.y);
        ctx.rotate(p.rotateAngle * Math.PI / 180);

        ctx.beginPath();
        
        ctx.rect(- w * 0.5, - h * 0.5, w, h);
        // ctx.rect(0, 0, w, h);

        ctx.closePath();
        
        let op = 255;
        if (p.opacity !== undefined) {
            op = Math.round(255 * p.opacity / 100);
        }

        // ctx.fillStyle = this.getProbeColor(p) + op.toString(16);
        ctx.fillStyle = p.colorHex + op.toString(16);

        // ctx.fillStyle = this.getProbeColor(p) + op.toString(16);
        const color = p.colorHex + op.toString(16);
        ctx.fillStyle = color;
        ctx.fill();

        // we’re done with the rotating so restore the unrotated context
        ctx.restore();
    }

    drawCorrectionAreaSymbol = (ctx, p) => {
        // const off = {
        //     x: 0, y: 0,
        // }

        let w = p.width;
        const h = p.height;

        // save the unrotated context of the canvas so we can restore it later
        // the alternative is to untranslate & unrotate after drawing
        ctx.save();

        // rotate the canvas to the specified degrees
        ctx.translate(p.x, p.y);
        ctx.rotate(p.rotateAngle * Math.PI / 180);

        ctx.beginPath();
        
        ctx.rect(- w * 0.5, - h * 0.5, w, h);
        // ctx.rect(0, 0, w, h);

        ctx.closePath();
        
        let op = 255;
        if (p.opacity !== undefined) {
            op = Math.round(255 * p.opacity / 100);
        }

        ctx.fillStyle = p.colorHex + op.toString(16);

        // ctx.fillStyle = this.getProbeColor(p) + op.toString(16);
        const color = p.colorHex + op.toString(16);
        ctx.fillStyle = color;
        ctx.fill();

        // we’re done with the rotating so restore the unrotated context
        ctx.restore();
    }

    componentDidMount() {

        this.getFloorPlanFile();

        const canvasContainer = this.refs.canvasContainer;

        this.planCanvas = this.refs.canvas;
        this.planImage = this.refs.blueprint;

        this.canvasCtx = this.planCanvas.getContext("2d");

        this.trackTransforms(this.canvasCtx);
        
        let lastX = this.planCanvas.width / 2, lastY = this.planCanvas.height / 2;
        let dragStart;
        const setLastXY = this.setState.bind(this);

        // window.addEventListener("resize", this.onResize);
        canvasContainer.addEventListener('mousedown', (evt) => {
            document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none';

            lastX = evt.pageX - this.planCanvas.offsetLeft;
            lastY = evt.pageY - this.planCanvas.offsetTop;

            dragStart = this.canvasCtx.transformedPoint(lastX, lastY);

            setLastXY({ lastX, lastY });

        }, false);

        const redraw = this.redraw.bind(this);

        canvasContainer.addEventListener('mousemove', (evt) => {
            lastX = evt.pageX - this.planCanvas.offsetLeft;
            lastY = evt.pageY - this.planCanvas.offsetTop;

            setLastXY({ lastX, lastY });

            if (dragStart) {
                var pt = this.canvasCtx.transformedPoint(lastX, lastY);
                var nx = pt.x - dragStart.x;
                var ny = pt.y - dragStart.y;
                this.canvasCtx.translate(nx, ny);
                setLastXY({ lastX, lastY });

                redraw();
            }
        }, false);

        canvasContainer.addEventListener ("mouseleave", () => {
            dragStart = null;
        }, false);


        canvasContainer.addEventListener('mouseup', (evt) => {
            dragStart = null;
        }, false);

        this.planImage.onload = () => {
            this.canvasCtx.drawImage(this.planImage, 0, 0);
            
            if(this.props.appState.corrections !== null)
            {
                // First draw the correction areas
                this.props.appState.corrections.forEach(param => {
                    if (param.correction_floor_id !== this.props.appState.currentFloor.id || param.type === 'correction_line') {
                        return;
                    }
                    this.drawCorrectionAreaSymbol(this.canvasCtx, param);
                });

                // Then the correction lines on top
                this.props.appState.corrections.forEach(param => {
                    if (param.correction_floor_id !== this.props.appState.currentFloor.id || param.type === 'correction_area') {
                        return;
                    }
                    this.drawCorrectionLineSymbol(this.canvasCtx, param);
                });
            }

            if(this.props.appState.probes !== null)
            {
                this.props.appState.probes.forEach(param => {
                    if (param.sample_layer_id !== this.props.appState.currentSampleLayer) {
                        return;
                    }
                    this.drawSymbol(this.canvasCtx, param);
                });
            }

            if(this.props.appState.roomNames !== null)
            {
                this.props.appState.roomNames.forEach(param => {
                    if (param.room_layer_id !== this.props.appState.currentSampleLayer) {
                        return;
                    }
                    this.drawRoomNameSymbol(this.canvasCtx, param);
                });
            }

            if(this.props.appState.roomLines !== null)
            {
                this.props.appState.roomLines.forEach(param => {
                    if (param.roomline_layer_id !== this.props.appState.currentSampleLayer) {
                        return;
                    }
                    this.drawRoomLineSymbol(this.canvasCtx, param);
                });
            }
        }
    }

    goToXY(x, y, zoom) {
        this.setLastXY(x, y, (() => {
            if (this.props.blueprintOffsetZoom !== zoom) {
                const zoomOffset = zoom - this.props.blueprintOffsetZoom;
                this.zoom(zoomOffset);
            } else {
            }
            var pt = this.canvasCtx.transformedPoint(x, y);
            this.canvasCtx.translate(pt.x, pt.y);
            this.redraw();
        }));
    }

    setLastXY(lastX, lastY, cb) {
        this.setState({ lastX, lastY }, cb);
    }

    componentDidUpdate(prevProps, prevState) {
        this.getFloorPlanFile();
        
        const scaleLevel = this.props.zoomLevel - prevProps.zoomLevel;

        if (this.props.mustJumpToScreenShotArea === true) {

            if (this.props.blueprintOffsetX !== this.props.blueprintScreenShotOffsetX ||
                this.props.blueprintOffsetY !== this.props.blueprintScreenShotOffsetY ||
                this.props.blueprintOffsetZoom !== this.props.blueprintScreenShotOffsetZoom
            ) {
                this.props.setBlueprintOffset(this.props.blueprintScreenShotOffsetX, this.props.blueprintScreenShotOffsetY);
                // if(this.props.blueprintOffsetZoom !== this.props.blueprintScreenShotOffsetZoom) {
                //     // console.log("Zoom has to be adjusted");
                //     this.props.setBlueprintOffset(this.props.blueprintScreenShotOffsetX, this.props.blueprintScreenShotOffsetY) //, this.props.blueprintScreenShotOffsetZoom);
                //     // this.props.setBlueprintOffsetZoom(this.props.blueprintScreenShotOffsetZoom);
                // } else {
                //     this.props.setBlueprintOffset(this.props.blueprintScreenShotOffsetX, this.props.blueprintScreenShotOffsetY);
                // }
                this.goToXY(this.props.blueprintScreenShotOffsetX, this.props.blueprintScreenShotOffsetY, this.props.blueprintScreenShotOffsetZoom);
            } else {

            }
        } else {

        }

        if (scaleLevel !== 0) {
            if (prevProps.zoomLevel !== this.props.zoomLevel) {
                this.zoom(scaleLevel);

            }
        }

        if (prevProps.interestPointRadius !== this.props.interestPointRadius) {
            this.setState({ interestPointRadius: this.props.interestPointRadius });
            this.redraw();
        }
    }

    zoom(scaleLevel) {
        this.props.setBlueprintOffsetZoom(this.props.blueprintOffsetZoom + scaleLevel);
        const scaleFactor = 1.025;
        var factor = Math.pow(scaleFactor, scaleLevel);
        this.canvasCtx.scale(factor, factor);

        this.redraw();
    }

    trackTransforms(ctx) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        var xform = svg.createSVGMatrix();
        
        var savedTransforms = [];
        var save = ctx.save;
        ctx.save = function () {
            savedTransforms.push(xform.translate(0, 0));
            return save.call(ctx);
        };

        var restore = ctx.restore;
        ctx.restore = function () {
            xform = savedTransforms.pop();
            return restore.call(ctx);
        };

        var scale = ctx.scale;
        ctx.scale = function (sx, sy) {
            xform = xform.scaleNonUniform(sx, sy);
            return scale.call(ctx, sx, sy);
        };

        var translate = ctx.translate;
        ctx.translate = function (dx, dy) {
            xform = xform.translate(dx, dy);
            return translate.call(ctx, dx, dy);
        };

        var setTransform = ctx.setTransform;
        ctx.setTransform = function (a, b, c, d, e, f) {
            xform.a = a;
            xform.b = b;
            xform.c = c;
            xform.d = d;
            xform.e = e;
            xform.f = f;
            return setTransform.call(ctx, a, b, c, d, e, f);
        };

        const setBlueprintOffset = this.props.setBlueprintOffset.bind(this);
        var pt = svg.createSVGPoint();
        ctx.transformedPoint = function (x, y) {
            pt.x = x; pt.y = y;
            const inversedXform = xform.inverse();
            setBlueprintOffset(xform.e, xform.f);
            return pt.matrixTransform(inversedXform);
        }
    }

    refreshDisplay() {
        if(this.canvasCtx === null) {
            return;
        }

        var p1 = this.canvasCtx.transformedPoint(0, 0);
        var p2 = this.canvasCtx.transformedPoint(this.planCanvas.width, this.planCanvas.height);

        // this.canvasCtx.save();
        // this.canvasCtx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
        this.canvasCtx.fillStyle = "white";
        this.canvasCtx.fillRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
        // this.canvasCtx.setTransform(1, 0, 0, 1, 0, 0);
        // this.canvasCtx.clearRect(0, 0, this.planCanvas.width, this.planCanvas.height);

        // this.canvasCtx.restore();

        this.canvasCtx.drawImage(this.planImage, 0, 0);
        
        // First draw the correction areas
        this.props.appState.corrections.forEach(param => {
            if (param.correction_floor_id !== this.props.appState.currentFloor.id || param.type === 'correction_line') {
                return;
            }
            this.drawCorrectionAreaSymbol(this.canvasCtx, param);
        });

        // Then the correction lines on top
        this.props.appState.corrections.forEach(param => {
            if (param.correction_floor_id !== this.props.appState.currentFloor.id || param.type === 'correction_area') {
                return;
            }
            this.drawCorrectionLineSymbol(this.canvasCtx, param);
        });

        let ctx = this.canvasCtx;
        this.props.appState.probes.forEach(param => {
            if (param.sample_layer_id !== this.props.appState.currentSampleLayer) {
                return;
            }
            this.drawSymbol(ctx, param);
        });
        
        this.props.appState.roomNames.forEach(param => {
            if (param.room_layer_id !== this.props.appState.currentSampleLayer) {
                return;
            }
            this.drawRoomNameSymbol(ctx, param);
        });

        this.props.appState.roomLines.forEach(param => {
            if (param.roomline_layer_id !== this.props.appState.currentSampleLayer) {
                return;
            }
            this.drawRoomLineSymbol(ctx, param);
        });
    }

    redraw() {
        if(this.redrawFrame === false) {
            this.redrawFrame = window.requestAnimationFrame(() => {
                this.refreshDisplay();

                // const ctx = this.refreshDisplay();
                // this.setState({ ctx });

                this.redrawFrame = false;
            });
        }
    }

    render() {

        const bgSrc = this.state.currentBackgroundFileUrl;

        return (
            <div ref="canvasContainer" className="shblueprint">
                <canvas id='floorCanvas'
                    ref="canvas"
                    width={this.props.width}
                    height={this.props.height}
                />
                <img ref="blueprint"
                    crossOrigin="anonymous"
                    className="blueprint-img"
                    src={bgSrc}
                    onLoad={this.props.handleImageLoaded}
                    alt="blueprint" />
                <Screenshot
                    appState={this.props.appState}
                    width={this.props.interestAreaWidth}
                    height={this.props.interestAreaHeight}
                    lastX={this.state.lastX}
                    lastY={this.state.lastY}
                    setLastXY={this.setLastXY.bind(this)}
                    oneIsVisible={this.props.oneIsChecked}
                    twoIsVisible={this.props.twoIsChecked}
                    legendScale={this.props.legendScale}
                />
            </div>
        )
    }
}