import React, { Component } from 'react';
import "./legend.scss";
import ReactSVG from 'react-svg';

// class LegendItem extends Component {
//     render() {

//         if (!this.props.visible) {
//             return null;
//         }

//         return (
//             <div className="legend-item">
//                 <div className="legend-scale" style={{ transform: 'scale(' + this.props.sc + ')', WebkitTransform: 'scale(' + this.props.sc + ')' }}>
//                     <ReactSVG className={"legend-item-img col" + this.props.color} alt="legendIcon" src={this.props.img} />
//                     <span className="legend-item-lb">
//                         {this.props.label}
//                     </span>
//                 </div>
//             </div >
//         )
//     }
// }

// index based on colors, as defined in the app
const probeColors = [
    "ff6085",
    "51dbb6",
    "f4e785",
    "51b6db"
];

const probeImages = [
    // indx is based on type type and form
    // probes
    {
        img: require('assets/images/symbols/Ellipse 147.svg'),
        indx: "0_0",
        added: false
    },
    {
        img: require('assets/images/symbols/Pfad 254.svg'),
        indx: "0_1",
        added: false
    },
    {
        img: require('assets/images/symbols/Rechteck 777.svg'),
        indx: "0_2",
        added: false
    },
    {
        img: require('assets/images/symbols/Pfad 256.svg'),
        indx: "0_3",
        added: false
    },

    // fire source
    {
        img: require('assets/images/symbols/iconfinder_flash_322445.svg'),
        indx: "1_0",
        added: false
    },

    // area markings
    {
        img: require('assets/images/symbols/Ellipse 148.svg'),
        indx: "2_0",
        added: false

    },
    {
        img: require('assets/images/symbols/Rechteck 778.svg'),
        indx: "2_1",
        added: false
    }
];

// will contain the probe svgs
const probeSVGs = [];

// height of a row
const legendItemHeight = 30;
const legendItemFont = 14;
const legendItemImageSize = 16;
const legendItemMarginLeft = 5;

const legendImageTop = (legendItemHeight - legendItemImageSize) / 2;
const legendTextTop = (legendItemHeight - legendItemFont) / 2;

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            canvasWidth: 0,
            canvasHeight: 0,
            legendItemCount: 0 // legend items that are drawn
        };

        this.drawing = false;
        this.legendCanvas = null;
        
        // this.probesSymbols = [
        //     {
        //         'items': [
        //             {
        //                 'img': require('assets/images/symbols/Ellipse 147.svg'),
        //                 'id': 0,
        //                 'type': 0,
        //             }, {
        //                 'img': require('assets/images/symbols/Pfad 254.svg'),
        //                 'id': 1,
        //                 'type': 0,
        //             }, {
        //                 'img': require('assets/images/symbols/Rechteck 777.svg'),
        //                 'id': 2,
        //                 'type': 0,
        //             }, {
        //                 'img': require('assets/images/symbols/Pfad 256.svg'),
        //                 'id': 3,
        //                 'type': 0,
        //             }
        //         ]
        //     },
        //     {
        //         'items': [
        //             {
        //                 'img': require('assets/images/symbols/iconfinder_flash_322445.svg'),
        //                 'id': 0,
        //                 'type': 1,
        //             }
        //         ]
        //     },
        //     {
        //         'items': [
        //             {
        //                 'img': require('assets/images/symbols/Ellipse 148.svg'),
        //                 'id': 0,
        //                 'type': 2,
        //             }, {
        //                 'img': require('assets/images/symbols/Rechteck 778.svg'),
        //                 'id': 1,
        //                 'type': 2,
        //             }
        //         ]
        //     },
        // ];

    }

    componentDidMount = () => {

        this.legendCanvas = this.refs.legendCanvas;

        const canvasWidth = this.refs.legend.offsetWidth;
        
        this.setCanvasWidthHeight(canvasWidth + 10);

        this.drawLegend();
    }

    componentDidUpdate = (prevProps, prevState) => {

        const legendItemCount = this.getLegendCount();
        if(legendItemCount !== prevState.legendItemCount || this.props.scale !== prevProps.scale ||
            this.refs.legend.offsetWidth + 10 !== prevState.canvasWidth) {

            this.setCanvasWidthHeight(this.refs.legend.offsetWidth + 10);

            this.drawLegend();
        }
    }

    setCanvasWidthHeight = (canvasWidth = null) => {
        const legendItemCount = this.getLegendCount();

        let canvasHeight = legendItemCount * legendItemHeight;
        let scaleFactor = this.props.scale / 100; // percent

        // console.log('canvas scaleFactor ' + scaleFactor + "(" + this.props.scale + ")");

        canvasHeight *= scaleFactor;

        let state = {
            canvasHeight: canvasHeight,
            legendItemCount: legendItemCount
        };

        if(canvasWidth !== null) {
            state.canvasWidth = canvasWidth;
        }

        this.legendCanvas.height = canvasHeight;

        this.setState(state);
    }

    drawLegend = () => {

        if(this.legendCanvas && !this.drawing && this.getLegendCount() > 0)
        {
            this.drawing = true;
            requestAnimationFrame(() => {

                const ctx = this.legendCanvas.getContext('2d');
                if (ctx) {
                    
                    let scaleFactor = this.props.scale / 100; // percent
                    ctx.scale(scaleFactor, scaleFactor);

                    // console.log('canvas scaleFactor ' + scaleFactor + "(" + this.props.scale + ")");

                    // keep width and height constant
                    const canvasWidth = this.legendCanvas.width / scaleFactor;
                    const canvasHeight = this.legendCanvas.height / scaleFactor;

                    // console.log('canvas ' + canvasWidth + "x" + canvasHeight);

                    // draw background
                    ctx.fillStyle = 'rgb(192, 192, 192)';
                    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
                    
                    this.drawLegendList(ctx);

                }

                this.drawing = false;
            });
        }
    }

    drawLegendList = (ctx) => {
        ctx.font = legendItemFont + 'px Montserrat-Regular';
        ctx.textBaseline = 'top';
        ctx.fillStyle = 'black';

        this.props.appState.legend
            .filter(legendItem => legendItem.sample_layer_id === this.props.appState.currentSampleLayer && legendItem.visible)
            .forEach((legendItem, i) => {
                this.drawLegendItem(ctx, legendItem, i);
            });
    }

    drawLegendItem = (ctx, legendItem, i) => {
        const svgIndex = legendItem.sourceType + "_" + legendItem.form + "_" + legendItem.color;
        if(probeSVGs[svgIndex]) {
            ctx.drawImage(probeSVGs[svgIndex], legendItemMarginLeft, legendItemHeight * i + legendImageTop, 
                legendItemImageSize, legendItemImageSize);
        }

        ctx.fillText(legendItem.text, legendItemMarginLeft + legendItemImageSize + legendItemMarginLeft, 
            legendItemHeight * i + legendTextTop);
    }

    getLegendCount = () => {
        // all on this layer and selected to be shown
        return this.props.appState.legend.filter(legendItem => legendItem.sample_layer_id === this.props.appState.currentSampleLayer && legendItem.visible).length;
    }

    afterSVGInjection = (err, svg, indx /* for forms */, probeImageKey) => {
        // we have the image so build the one with the different colors        
        var svgURL = new XMLSerializer().serializeToString(svg);

        probeColors.forEach( (probeColor, colorKey) => {
            var svgURLColor = svgURL.replace('5d5d5d', probeColor);

            let imgWithColor = new Image();
            imgWithColor.onload = () => {
                const imgIndex = indx + '_' + colorKey;

                if(!probeSVGs[imgIndex]) {
                    probeSVGs[imgIndex] = imgWithColor;

                    probeSVGs[imgIndex].onload = null;
                    imgWithColor = null;
                }

                if(this.allSVGsLoaded()) {
                    this.drawLegend();
                }
            }
            imgWithColor.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURLColor);

            probeImages[probeImageKey].added = true;            
        });
    }

    preloadSVGs = () => {
        return probeImages.map( (probeImage, key) => {
            if(!probeImage.added)
            {
                return (
                    <ReactSVG afterInjection={(err, svg) => this.afterSVGInjection(err, svg, probeImage.indx, key)} src={probeImage.img} key={key} />
                );                
            }

            return null;
        });
    }

    allSVGsLoaded = () => {
        return (Object.keys(probeSVGs).length === (probeImages.length * probeColors.length));
    }

    render() {

        return (
            <div className="legend" ref="legend">
                <canvas className="legend-btm" id="legend-btm" ref="legendCanvas" width={this.state.canvasWidth} height={this.state.canvasHeight}>
                </canvas>


                <div className="legend-svgs" style={{display: "none"}}>
                    {this.preloadSVGs()}
                </div >
            </div>
        );
    }
}