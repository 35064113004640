import React from "react";

import "./slider.scss";

class View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: 0,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value) {
            this.setState({
                value: nextProps.value, 
            })
        }
    }

    onChange = data => {
        data.preventDefault();
        if (this.props.onChange)
            this.props.onChange(this, data);
    }

    render() {

        const clsName = this.props.className ? this.props.className : "slider";

        return (
            <div className={clsName} >
                {this.props.label ?
                    <div className="label">{this.props.label}</div>
                    : null}
                <input type="range" min={this.props.min} max={this.props.max} value={this.state.value} step={this.props.step} onChange={this.onChange} />
            </div>
        );
    }
}

export default View;
