import React, { Component } from 'react';
import "./popup-probe.scss";

import Popup from "_modules/popup/Popup";
import Tab2 from "containers/tab2/Tab2";

export default class View extends Component {


    render() {

        return (
            <Popup {...this.props} className={"popup popup-probe"} >
                <Tab2 {...this.props} />
            </Popup>
        );
    }
}