import React, { Component } from 'react';
import "./plmain.scss";

import ShBluePrint from "../shBluePrint/ShBluePrint";
import * as Lang from "./PlmainLang";

import Input from "_modules/input/Input";
import Select from "react-dropdown-select";

export default class MainContainerArea extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'selectedExport': 0,
            'exps': [],
        }
    }

    componentWillMount() {
        const exps = [];
        for (let i = 0; i < this.props.appState.exports.length; i++) {
            const exp = this.props.appState.exports[i];
            if (exp.name !== "" && exp.width !== "" && exp.height !== "") {
                exps.push({
                    'label': exp.name,
                    'value': i,
                })
            }
        }

        this.setState({
            'exps': exps,
            'selectedExport': 0,
        })
    }

    onSelChange = param => {
      
        const exp = this.props.appState.exports[param[0].value];

        this.props.setInterestAreaWidth(this, { target: { value: exp.width } });
        this.props.setInterestAreaHeight(this, { target: { value: exp.height } });

    }

    render() {
      
        return (
            <div className="plmain" >
                <div className="shsettings">
                    {this.state.exps.length > 0 ? <Select className="plmain-select" options={this.state.exps} onChange={this.onSelChange} values={[this.state.exps[this.state.selectedExport]]} /> : null}
                    <Input type="number" interval={350} value={this.props.interestAreaWidth} label={Lang.INP_WIDTH} label2={Lang.INP_UNIT} onChange={this.props.setInterestAreaWidth} />
                    <Input type="number" interval={350} value={this.props.interestAreaHeight} label={Lang.INP_HEIGHT} label2={Lang.INP_UNIT} onChange={this.props.setInterestAreaHeight} />
                </div>
                <ShBluePrint
                    appState={this.props.appState}
                    interestAreaWidth={this.props.interestAreaWidth}
                    interestAreaHeight={this.props.interestAreaHeight}
                    width={this.props.planAreaWidth}
                    height={this.props.planAreaHeight}
                    imageWidth={this.props.imageWidth}
                    imageHeight={this.props.imageHeight}
                    handleImageLoaded={this.props.handleImageLoaded}
                    zoomLevel={this.props.zoomLevel}
                    interestPointRadius={this.props.interestPointRadius}
                    oneIsChecked={this.props.oneIsChecked}
                    twoIsChecked={this.props.twoIsChecked}
                    legendScale={this.props.legendScale}

                    setBlueprintOffset={this.props.setBlueprintOffset}
                    setBlueprintOffsetZoom={this.props.setBlueprintOffsetZoom}

                    blueprintOffsetX={this.props.blueprintOffsetX}
                    blueprintOffsetY={this.props.blueprintOffsetY}
                    blueprintOffsetZoom={this.props.blueprintOffsetZoom}

                    blueprintScreenShotOffsetX={this.props.blueprintScreenShotOffsetX}
                    blueprintScreenShotOffsetY={this.props.blueprintScreenShotOffsetY}
                    blueprintScreenShotOffsetZoom={this.props.blueprintScreenShotOffsetZoom}

                    mustJumpToScreenShotArea={this.props.mustJumpToScreenShotArea}

                    screenShotButtonClicked={this.props.screenShotButtonClicked}
                    markScreenShotAsLoaded={this.props.markScreenShotAsLoaded}
                />
            </div >
        )
    }
}