import React from "react";

import "./select.scss";
import Select from "react-dropdown-select";

export default class View extends React.Component {

    constructor(props) {
        super(props);

        this.clicked = false;

        this.numChange = 0;
    }

    onChange = (param) => {
        if (this.props.onSelect) {
            this.props.onSelect(this, param, this.numChange);
            this.numChange++;
        }
    }

    click = () => {
        this.clicked = true;
    }

    contentRenderer = ({ props, state }) => {
        if (!this.props.values[0]) {
            return null;
        }

        const val = this.props.values[0].label;
        return (
            <div>
                {val}
            </div>
        );
    };

    render() {

        const clsName = this.props.className ? this.props.className : "select" + (this.props.disabled === true ? " disabled" : "")

        return (
            <div onClick={this.click} className={clsName}>
                <Select disabled={this.props.disabled} contentRenderer={this.props.manualValues === true ? this.contentRenderer : null} searchable={false} multi={false} {...this.props} onChange={this.onChange} className="" />
            </div>
        );
    }
}