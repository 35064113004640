import React, { Component } from 'react';
import "./user.scss";
import InputTop from "_modules/input-top/InputTop";
import Input from "_modules/input/Input";
import Button2 from "_modules/button2/Button2";

export default class View extends Component {

    del = () => this.props.onDelete(this);

    render() {
        const errors = this.props.errors;

        return (
            <div className={"user" + (this.props.topUserLabel ? " top-user" : "")}>
                {this.props.topUserLabel ?
                    <InputTop top={this.props.topUserLabel}  {...this.props} inputClass="input" error={(errors && errors.username === true)} className="input inp-cnt" value={this.props.username} onChange={this.props.onChange} name="username" />
                    :
                    <Input  {...this.props} error={(errors && errors.username === true)} className="input inp-cnt" value={this.props.username} onChange={this.props.onChange} name="username" />
                }
                {this.props.topPassLabel ?
                    <InputTop top={this.props.topPassLabel} {...this.props} inputClass="input" error={(errors && errors.password === true)} className="input inp-cnt" value={this.props.password} onChange={this.props.onChange} type="password" name="password" />
                    :
                    <Input {...this.props} error={(errors && errors.password === true)} className="input inp-cnt" value={this.props.password} onChange={this.props.onChange} type="password" name="password" />
                }
                {this.props.topEmailLabel ?
                    <InputTop top={this.props.topEmailLabel} {...this.props} inputClass="input" error={(errors && errors.email === true)} className="input inp-cnt" value={this.props.email} onChange={this.props.onChange} name="email" />
                    :
                    <Input {...this.props} error={(errors && errors.email === true)} className="input inp-cnt" value={this.props.email} onChange={this.props.onChange} name="email" />
                }
                <Button2 onClick={this.del} className="button2 btn-delete" svg={require("assets/images/delete.svg")} />
            </div>
        )
    }
}