import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import "./sample-layer.scss";
import * as actions from "states/appState";

class View extends Component {

    click = (params) => {
        if (!this.props.appState.save) {
            params.stopPropagation();
            this.props.onClick(this);
            this.props.actions.getFloorCorrectionsData(this.props.appState && this.props.appState.currentFloor ? this.props.appState.currentFloor : null);
            this.props.actions.getSampleLayerData(this.props);
            this.props.actions.getRoomNamesLayerData(this.props);
            this.props.actions.getRoomLinesLayerData(this.props);
        }
    }

    render() {
        const open = this.props.selected === this.props.id ? true : false;

        return (
            <div onClick={this.click} className={"sample-layer" + (open ? " selected" : "")} >
                {this.props.name}
            </div >
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: bindActionCreators(actions, dispatch),
    })
)(View);
