import React, { Component } from 'react';
import "./bar.scss";

import Button from "./button/Button";

export default class View extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buttons: [],
        }
    }

    onSelect = params => {
        if (this.props.enabled) {
            if (!params.props.disabled) {
                const btns = this.state.buttons;

                for (let i = 0; i < this.state.buttons.length; i++) {
                    const btn = this.state.buttons[i];
                    const props = btn.props;
                    const data = btn.data;
                    props.selected = false;
                    if (data.id === params.props.id) {
                        props.selected = true;
                    }
                }

                this.setState({
                    buttons: btns,
                })

                this.props.onSelect(params);
            }
        }
    }

    buildItems = (data, key) => {
        return (
            <Button onClick={this.onSelect} key={key} {...data.data} {...data.props} />
        )
    }

    componentWillMount() {
        const btns = [];

        for (let i = 0; i < this.props.buttons.length; i++) {
            const btnProp = this.props.buttons[i];
            const btn = {
                data: {
                    id: i,
                },
                props: btnProp,
            }
            btns.push(btn);
        }

        this.setState({
            buttons: btns,
        })

    }


    render() {
        return (
            <div className="bar">
                <div className="logo">
                    <img src={this.props.logo} alt="" />
                </div>
                <div className="btns">
                    {this.state.buttons.map(this.buildItems)}
                </div>
            </div>
        );
    }
}